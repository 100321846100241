import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import LeopardAttachmentPopup from '../components/LeopardAttachmentPopup';

import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing,
    Editing,
    Export,
    FilterRow,
    HeaderFilter,
    LoadPanel,
    Pager,
    Paging,
    RemoteOperations,
    Selection,
    Sorting,
    StateStoring
} from 'devextreme-react/data-grid';

import {Popover} from 'devextreme-react/popover';
import ExcelJS from 'exceljs';
import {exportDataGrid} from 'devextreme/excel_exporter';

import LeopardSecurity from '../security/LeopardSecurity';
import LDH from '../helpers/LeopardDataHelper';
import LeopardDataHelper from '../helpers/LeopardDataHelper';
import LRH from '../helpers/LeopardReactHelper';
import {InitCustomStore, UpdateCustomStore, UpdateGridViewDefinition} from './LeopardActionCreators';
import {LeopardGridViewColumnBuilder, LeopardGridViewToolbar} from '../datashaping/LeopardGridViewBuilders';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardFormEditor from "./LeopardFormEditor";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import LeopardDataImportPopup from "../components/LeopardDataImportPopup";
import LeopardReportSchedulerPopup from "../components/LeopardReportSchedulerPopup";
import LPH from "../helpers/LeopardPermissionHelper";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import {isFunction} from "devextreme/core/utils/type";
import LWH from "../helpers/LeopardWebsocketHelper";
import LeopardLayoutSelectionPopup from "../components/LeopardLayoutSelectionPopup";
import LeopardAPIGatewayConfig from "./LeopardAPIGatewayConfig";
import LeopardDocumentUploader from "../components/LeopardDocumentUploader";

let moment = require("moment");

class LeopardGridView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // ---- For "UseStateStore => True" Only ----
            customStore: null
            // ------------------------------------------
        };

        this.totalRecordCount = 0;
        this.optimizePagerForLargeDataset = true;
        this.defaultPageSize = 50;
        this.enableAutoRefresh = false;
        this.autoRefreshInterval = 30;
        this.customColumnOverallWidth = 200;
        this.showAutoRefreshSwitch = true;
        this.gridDefinition = {columnDefinition: []};
        this.isGridViewLayoutStateLoaded = false;
        this.customColumnConfiguration = null;
        this.retryConnectionCount = 0;
        this.hasCustomizeColumnsInitialized = false;
        this.combinedFilterHistory = [];
        this.relationships = [];
        this.relationshipsLinkedToDataView = [];
        this.isFirstRowSelectedOnInitLoad = false;
        this.disposingAllInstances = false;
        this.selectedParentViewData = null;
        this.selectedGridViewRowData = null;
        this.originalDataSourceUrl = "";
        this.pendingWebsocketCallbacks = [];
        this.dynamicGridViewHeaders = [];

        this.uiObjectInstance = {
            popupDataImportInstance: null,
            popupReportSchedulerInstance: null,
            gridViewInstance: null,
            popupEditFormInstance: null,
            popupPhotoGalleryInstance: null,
            exportOptionPopupInstance: null
        };
        this.disposablePopupInstances = [];
        this.exportOptionFromPopup = null;
        this.gridViewCustomLayout = null;
        this.globalVariableInitialized = false;
        this.gridIndexForImport = 0;
        this.highlightPendingList = [];
        this.highlightWordsFromParent = [];
    }

    setExportOptionPopupInstance = (ref) => {
        if (LDH.IsObjectNull(ref) || LDH.IsObjectNull(ref.instance)) return;
        this.uiObjectInstance.exportOptionPopupInstance = ref.instance;
    }

    setComponentInstance = (data) => {
        if (LDH.IsObjectNull(data.e) || LDH.IsObjectNull(data.e.instance)) return;
        this.uiObjectInstance[data.componentName] = data.e.instance;
    }

    setGridViewInstance = (ref) => {
        let that = this;
        if (LDH.IsObjectNull(ref) || LDH.IsObjectNull(ref.instance)) return;
        this.uiObjectInstance.gridViewInstance = ref.instance;

        this.props.setGridViewInstance({
            instance: ref.instance,
            id: this.props.GridViewId,
            type: "datagrid",
            isDataView: true,
            optimizePagerForLargeDataset: this.optimizePagerForLargeDataset
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.GridViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstance.gridViewInstance.option("relationships", linkedList);
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId)) {
            LeopardStaticUIConfig.Global_DashboardDataViewSiteAccessRegister.push({
                dashboardItemId: dashboardItemId,
                props: that.props,
                instance: ref.instance,
                dataViewPersistentId: that.props.gridDefinition.dataViewPersistentId,
                callback(data) {
                    let gridViewInstance = this.instance;
                    if (data.actionType === "clear-data-childview") {
                        that.setState({customStore: []}, function () {
                            setTimeout(function () {
                                gridViewInstance.refresh();
                            }, 100);
                        });
                    } else if (data.actionType === "send-data-to-childview") {
                        if (that.props.gridDefinition.gridViewEngine !== "csv-adhoc-engine") {
                            return;
                        }

                        let dataSource = this.instance.option("dataSource");
                        let gridViewData = [];

                        if (!LDH.IsObjectNull(dataSource) && dataSource.length > 0) {
                            gridViewData = dataSource.map(function (e) {
                                return e;
                            });

                            let newDataToAdd = new Object();
                            if (!LDH.IsObjectNull(data.parentGridDef.columnDefinition) &&
                                data.parentGridDef.columnDefinition.length > 0 &&
                                data.commandLinkData.columnHeaderMatchType === "column-header-text") {
                                let keys = Object.keys(gridViewData[0]);
                                for (let i = 0; i < keys.length; i++) {
                                    let keyName = keys[i];

                                    for (let j = 0; j < data.parentGridDef.columnDefinition.length; j++) {
                                        let customColumn = data.parentGridDef.columnDefinition[j];

                                        if (customColumn.columnCustomHeader === keyName &&
                                            !LDH.IsObjectNull(data.dataFromSource[customColumn.columnName])) {

                                            if (!LDH.IsObjectNull(customColumn.isImportEnabled) && !customColumn.isImportEnabled) {
                                                continue;
                                            }
                                            newDataToAdd[keyName] = data.dataFromSource[customColumn.columnName];
                                            break;
                                        }
                                    }
                                }
                                if (data.dataViewType === "gridview") {
                                    newDataToAdd["Processed"] = "";
                                    newDataToAdd["OrderIndex"] = gridViewData.length + 1;
                                }
                                gridViewData.push(newDataToAdd);
                            } else if (LDH.IsObjectNull(data.commandLinkData.columnHeaderMatchType) ||
                                data.commandLinkData.columnHeaderMatchType === "column-header-id") {
                                let keys2 = Object.keys(gridViewData[0]);
                                for (let i = 0; i < keys2.length; i++) {
                                    let keyName2 = keys2[i];
                                    let isImportEnabled = true;

                                    for (let j = 0; j < data.parentGridDef.columnDefinition.length; j++) {
                                        let customColumn = data.parentGridDef.columnDefinition[j];

                                        if (customColumn.columnName === keyName2 &&
                                            !LDH.IsObjectNull(customColumn.isImportEnabled) &&
                                            !customColumn.isImportEnabled) {
                                            isImportEnabled = false;
                                            break;
                                        }
                                    }
                                    if (!isImportEnabled) continue;

                                    if (!LDH.IsObjectNull(data.dataFromSource[keyName2])) {
                                        newDataToAdd[keyName2] = data.dataFromSource[keyName2];
                                    }
                                }

                                let keys5 = Object.keys(data.dataFromSource);
                                for (let i = 0; i < keys5.length; i++) {
                                    let keyName5 = keys5[i];
                                    if (keyName5.indexOf("_ValueColor") > -1 ||
                                        keyName5.indexOf("_BgColor") > -1) {
                                        delete data.dataFromSource[keyName5];
                                    }
                                }
                                if (data.dataViewType === "gridview") {
                                    newDataToAdd["Processed"] = "";
                                    newDataToAdd["OrderIndex"] = gridViewData.length + 1;
                                }
                                gridViewData.push(newDataToAdd);
                            }
                        } else {
                            let newDataToAdd2 = new Object();
                            if (!LDH.IsObjectNull(data.parentGridDef.columnDefinition) &&
                                data.parentGridDef.columnDefinition.length > 0 &&
                                data.commandLinkData.columnHeaderMatchType === "column-header-text") {
                                let keys3 = Object.keys(data.dataFromSource);
                                for (let i = 0; i < keys3.length; i++) {
                                    let keyName3 = keys3[i];

                                    for (let j = 0; j < data.parentGridDef.columnDefinition.length; j++) {
                                        let customColumn = data.parentGridDef.columnDefinition[j];

                                        if (customColumn.columnName === keyName3 &&
                                            !LDH.IsObjectNull(data.dataFromSource[customColumn.columnName])) {

                                            if (!LDH.IsObjectNull(customColumn.isImportEnabled) && !customColumn.isImportEnabled) {
                                                continue;
                                            }
                                            newDataToAdd2[customColumn.columnCustomHeader] = data.dataFromSource[keyName3];
                                            break;
                                        }
                                    }
                                }
                                if (data.dataViewType === "gridview") {
                                    newDataToAdd2["Processed"] = "";
                                    newDataToAdd2["OrderIndex"] = gridViewData.length + 1;
                                }
                                gridViewData.push(newDataToAdd2);
                            } else if (LDH.IsObjectNull(data.commandLinkData.columnHeaderMatchType) ||
                                data.commandLinkData.columnHeaderMatchType === "column-header-id") {

                                for (let j = 0; j < data.parentGridDef.columnDefinition.length; j++) {
                                    let customColumn = data.parentGridDef.columnDefinition[j];

                                    if (!LDH.IsObjectNull(customColumn.isImportEnabled) &&
                                        !customColumn.isImportEnabled) {
                                        delete data.dataFromSource[customColumn.columnName];
                                    }
                                }

                                let keys5 = Object.keys(data.dataFromSource);
                                for (let i = 0; i < keys5.length; i++) {
                                    let keyName5 = keys5[i];
                                    if (keyName5.indexOf("_ValueColor") > -1 ||
                                        keyName5.indexOf("_BgColor") > -1) {
                                        delete data.dataFromSource[keyName5];
                                    }
                                }
                                if (data.dataViewType === "gridview") {
                                    data.dataFromSource["Processed"] = "";
                                    data.dataFromSource["OrderIndex"] = gridViewData.length + 1;
                                }
                                gridViewData.push(data.dataFromSource);
                            }
                        }

                        if (!LDH.IsObjectNull(gridViewData) && gridViewData.length > 0) {
                            that.dynamicGridViewHeaders = Object.keys(gridViewData[0]);
                        }

                        if ((LDH.IsObjectNull(this.props.gridDefinition.allowImportDuplicates) ||
                                !this.props.gridDefinition.allowImportDuplicates) &&
                            !LDH.IsObjectNull(this.props.gridDefinition.columnIdForSearchDuplicates) &&
                            !LDH.IsValueEmpty(this.props.gridDefinition.columnIdForSearchDuplicates)) {
                            let columnId = this.props.gridDefinition.columnIdForSearchDuplicates;
                            let hasDuplicates = LDH.IsArrayContainDuplicates(gridViewData, columnId);
                            if (hasDuplicates) {
                                LRH.ShowToast("This item has already been added to the Import list.", "error", 5000);
                                return;
                            }
                        }

                        if ((!LDH.IsObjectNull(this.props.gridDefinition.maxNumberOfRowsForImport) &&
                            !LDH.IsValueEmpty(this.props.gridDefinition.maxNumberOfRowsForImport) &&
                            this.props.gridDefinition.maxNumberOfRowsForImport > 0)) {
                            if (gridViewData.length > this.props.gridDefinition.maxNumberOfRowsForImport) {
                                let maxRow = this.props.gridDefinition.maxNumberOfRowsForImport;
                                LRH.ShowToast("Your data has reached the maximum " + maxRow + " rows.", "error", 5000);
                                return;
                            }
                        }

                        if (LDH.IsObjectNull(that.props.useStateStore) || !that.props.useStateStore) {
                            that.props.UpdateCustomStore(gridViewData, that.props.state.gridViewId);
                            setTimeout(function () {
                                if (!LDH.IsObjectNull(data.commandLinkData.confirmationToastMessageVisibility) &&
                                    data.commandLinkData.confirmationToastMessageVisibility) {
                                    LRH.ShowToast(data.commandLinkData.confirmationToastMessageText, "success", 2000);
                                }
                                window["gridview_datasource_persistent_" + that.props.gridDefinition.dataViewPersistentId] = gridViewData;
                                that.gridViewDataSourceOnChanged(null, "inserted", that);
                                gridViewInstance.refresh();
                            }, 100);
                        } else {
                            that.setState({customStore: gridViewData}, function () {
                                setTimeout(function () {
                                    if (!LDH.IsObjectNull(data.commandLinkData.confirmationToastMessageVisibility) &&
                                        data.commandLinkData.confirmationToastMessageVisibility) {
                                        LRH.ShowToast(data.commandLinkData.confirmationToastMessageText, "success", 2000);
                                    }
                                    window["gridview_datasource_persistent_" + that.props.gridDefinition.dataViewPersistentId] = gridViewData;
                                    that.gridViewDataSourceOnChanged(null, "inserted", that);
                                    gridViewInstance.refresh();
                                }, 100);
                            });
                        }
                    }
                }
            });
        }

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: ref.instance,
                callback(data) {
                    if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data["sendFromTabId"]) &&
                        !LDH.IsObjectNull(data["sendToDataViewId"]) &&
                        data["sendToDataViewId"] !== that.props.GridViewId) {
                        return;
                    }
                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            let gridDefinition = this.props.gridDefinition;
                            let params = gridDefinition.oDataQueryForLinkedView;
                            let evaluatedData = LDH.DeepClone(data.evaluatedData);
                            let columDef = [];
                            that.selectedParentViewData = data.dataFromSource;

                            if (!LDH.IsObjectNull(this.props.gridDefinition.eventHandlerParentSelectionOnChanged) &&
                                !LDH.IsValueEmpty(this.props.gridDefinition.eventHandlerParentSelectionOnChanged)) {
                                let javascript = this.props.gridDefinition.eventHandlerParentSelectionOnChanged;
                                let dataName = "data";
                                let dataValue = data;
                                evaluatedData = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                                    dataValue, this.props.GridViewId, null);
                            }
                            if (!LDH.IsObjectNull(evaluatedData)) {
                                that.setState({customStore: evaluatedData});
                                return;
                            }

                            if (!LDH.IsObjectNull(data.dataFromSource) &&
                                !LDH.IsObjectNull(data.dataFromSource["CallChildViewOnNonSelection"]) &&
                                data.dataFromSource["CallChildViewOnNonSelection"] === true &&
                                !LDH.IsObjectNull(gridDefinition.oDataQueryNonSelectionForLinkedView) &&
                                !LDH.IsValueEmpty(gridDefinition.oDataQueryNonSelectionForLinkedView)) {
                                params = gridDefinition.oDataQueryNonSelectionForLinkedView;
                            }

                            if (!LDH.IsObjectNull(data.parentGridDef) &&
                                !LDH.IsObjectNull(data.parentGridDef.columnDefinition)) {
                                columDef = data.parentGridDef.columnDefinition;
                            }

                            if (!LDH.IsObjectNull(this.props.gridDefinition.realtimeMessages) &&
                                this.props.gridDefinition.realtimeMessages.length > 0) {
                                let realtimeMessage = null;
                                for (let v = 0; v < this.props.gridDefinition.realtimeMessages.length; v++) {
                                    let message = this.props.gridDefinition.realtimeMessages[v];
                                    if (!LDH.IsObjectNull(message.attachToEvent) &&
                                        message.attachToEvent === "received-data-from-parent" &&
                                        !LDH.IsObjectNull(message.messagingType) &&
                                        message.messagingType === "publisher") {
                                        realtimeMessage = message;
                                        break;
                                    }
                                }

                                if (!LDH.IsObjectNull(realtimeMessage)) {
                                    if (!LDH.IsObjectNull(realtimeMessage.websocketRequestLogic) &&
                                        !LDH.IsValueEmpty(realtimeMessage.websocketRequestLogic)) {
                                        let javascript = realtimeMessage.websocketRequestLogic;
                                        let dataName = "data";
                                        let dataValue = [];
                                        let requests = LDH.EvaluateJavaScriptForDataShaping(javascript,
                                            dataName, dataValue, that.props.GridViewId);

                                        if (!LDH.IsObjectNull(requests)) {
                                            if (!LDH.IsObjectNull(gridDefinition.clearDataWhenLoading) && gridDefinition.clearDataWhenLoading) {
                                                that.setState({customStore: []});
                                            }

                                            setTimeout(function () {
                                                for (let k = 0; k < requests.length; k++) {
                                                    let queryToSend = requests[k].query;
                                                    queryToSend = LDH.FilterMacro(queryToSend, null, null);

                                                    let eventKey = LDH.GenerateGuid();
                                                    LWH.AddPendingEvent(eventKey, "", that.props.GridViewId, "leopard-gridview-data-request", null, false);

                                                    let messageTemplate = LWH.GetMessageTemplate(
                                                        "leopardsystems.depot.search.item", window.websocketConnectionId, requests[k], eventKey, "connectionId"
                                                    );
                                                    LWH.SendMessage(JSON.stringify(messageTemplate));

                                                    let instance = that.uiObjectInstance.gridViewInstance;
                                                    LRH.EnableOrDisableGridViewToolbarButtons(that.props.GridViewId, false, instance);

                                                    let pendingCallback = LWH.WaitForWebsocketResponse(eventKey, function (response, responseData) {
                                                        LRH.EnableOrDisableGridViewToolbarButtons(that.props.GridViewId, true, instance);

                                                        if (response === "aborted") {
                                                            $("#gridViewToobar_" + that.props.GridViewId).hide();
                                                            $("#GridView_TopBar_Refresh_" + that.props.GridViewId).removeClass("leopard-ui-disabled");
                                                            LRH.ShowToast("Websocket connection was aborted.", "error", 5000);
                                                        } else if (response === "completed") {
                                                            let shapedData = responseData.data;

                                                            if (!LDH.IsObjectNull(realtimeMessage.websocketResponseLogic) &&
                                                                !LDH.IsValueEmpty(realtimeMessage.websocketResponseLogic)) {
                                                                let javascript = realtimeMessage.websocketResponseLogic;
                                                                let dataName = "data";
                                                                let dataValue = responseData;
                                                                shapedData = LDH.EvaluateJavaScriptForDataShaping(javascript,
                                                                    dataName, dataValue, that.props.GridViewId);
                                                            }

                                                            if (LDH.IsObjectNull(that.props.useStateStore) || !that.props.useStateStore) {
                                                                that.props.UpdateCustomStore(shapedData, that.props.GridViewId);
                                                            } else {
                                                                that.setState({customStore: shapedData});
                                                            }
                                                        }
                                                    });
                                                    that.pendingWebsocketCallbacks.push(pendingCallback);
                                                }
                                            }, 100);
                                        }
                                    }
                                    return;
                                }
                            }

                            if (!LDH.IsObjectNull(params) && !LDH.IsValueEmpty(params)) {
                                if (LDH.IsObjectNull(data.dataFromSource)) {
                                    this.instance.option("setBlankPostProcess", true);

                                    let gridViewState = that.props.state.gridViewState;
                                    let currentState = that.getCurrentState(gridViewState, false);
                                    let filterOption = gridDefinition.clearFilterOnParentSelectionChanged;
                                    if ((LDH.IsObjectNull(filterOption) || filterOption === true) &&
                                        $("#GridView_TopBar_ClearFilter_" + dashboardItemId).length > 0) {
                                        $("#GridView_TopBar_ClearFilter_" + dashboardItemId).trigger("click");
                                    }
                                    this.instance.option("dataSource", currentState.customStore);
                                } else {
                                    params = LDH.ConvertArrayMacroToString(params,
                                        data.dataFromSource, columDef);

                                    this.instance.option("dataFromSource", data.dataFromSource);
                                    this.instance.option("customQueryParams", params);
                                    this.instance.option("setBlankPostProcess", false);
                                    this.instance.option("selectFirstRow", true);

                                    let gridViewState = that.props.state.gridViewState;
                                    let currentState = that.getCurrentState(gridViewState, false);
                                    let filterOption = gridDefinition.clearFilterOnParentSelectionChanged;
                                    if ((LDH.IsObjectNull(filterOption) || filterOption === true) &&
                                        $("#GridView_TopBar_ClearFilter_" + dashboardItemId).length > 0) {
                                        $("#GridView_TopBar_ClearFilter_" + dashboardItemId).trigger("click");
                                    }
                                    this.instance.option("dataSource", currentState.customStore);
                                }
                            } else if ((LDH.IsObjectNull(params) || LDH.IsValueEmpty(params)) &&
                                !LDH.IsObjectNull(data.dataFromSource)) {
                                this.instance.option("dataFromSource", data.dataFromSource);
                                this.instance.option("setBlankPostProcess", false);
                                this.instance.option("selectFirstRow", true);

                                let gridViewState = that.props.state.gridViewState;
                                let currentState = that.getCurrentState(gridViewState, false);
                                let filterOption = gridDefinition.clearFilterOnParentSelectionChanged;
                                if ((LDH.IsObjectNull(filterOption) || filterOption === true) &&
                                    $("#GridView_TopBar_ClearFilter_" + dashboardItemId).length > 0) {
                                    $("#GridView_TopBar_ClearFilter_" + dashboardItemId).trigger("click");
                                }
                                this.instance.option("dataSource", currentState.customStore);
                            } else if ((LDH.IsObjectNull(params) || LDH.IsValueEmpty(params)) &&
                                LDH.IsObjectNull(data.dataFromSource)) {
                                this.instance.option("setBlankPostProcess", true);

                                let gridViewState = that.props.state.gridViewState;
                                let currentState = that.getCurrentState(gridViewState, false);
                                let filterOption = gridDefinition.clearFilterOnParentSelectionChanged;
                                if ((LDH.IsObjectNull(filterOption) || filterOption === true) &&
                                    $("#GridView_TopBar_ClearFilter_" + dashboardItemId).length > 0) {
                                    $("#GridView_TopBar_ClearFilter_" + dashboardItemId).trigger("click");
                                }
                                this.instance.option("dataSource", currentState.customStore);
                            }
                        }
                    }
                }
            });
        }
    };

    componentDidMount = () => {
        let that = this;
        let layouts = this.props.dataViewLayouts;
        let initializeStore = false;
        let isGridViewJSONEngine = this.props.isGridViewJSONEngine;
        let staticJSONData = this.props.staticJSONData;

        if (!LDH.IsObjectNull(this.props.gridDefinition.useLocalDataSource) &&
            this.props.gridDefinition.useLocalDataSource) {
            isGridViewJSONEngine = true;
            staticJSONData = [];
        }

        if (!LDH.IsObjectNull(layouts)) {
            this.gridViewCustomLayout = null;
            if (!LDH.IsObjectNull(layouts.selectedLayoutId) &&
                !LDH.IsObjectNull(layouts.layouts) && layouts.layouts.length > 0) {
                for (let i = 0; i < layouts.layouts.length; i++) {
                    if (layouts.layouts[i].layoutId === layouts.selectedLayoutId) {
                        this.gridViewCustomLayout = layouts.layouts[i].layoutData;
                    }
                }
            }
            initializeStore = true;
        } else if (!LDH.IsObjectNull(this.props.dashboardLayoutDataViewMapping)) {
            let foundLayout = false;
            for (let i = 0; i < this.props.dashboardLayoutDataViewMapping.length; i++) {
                let mapping = this.props.dashboardLayoutDataViewMapping[i];
                if (mapping.dataViewId === that.props.GridViewId) {
                    let userId = LDH.GetUserIdFromUserProfile(window.userProfile);
                    let persistentId = mapping.dataViewPersistentId;
                    foundLayout = true;

                    LeopardAjaxHelper.GetDataViewLayoutById(userId, persistentId, function (response) {
                        if (!LDH.IsObjectNull(response) && !LDH.IsObjectNull(response.layouts)) {
                            for (let j = 0; j < response.layouts.length; j++) {
                                if (response.layouts[j].layoutId === mapping.layoutId) {
                                    that.gridViewCustomLayout = response.layouts[j].layoutData;
                                    break;
                                }
                            }
                        }

                        if (LDH.IsObjectNull(that.props.useStateStore) ||
                            that.props.useStateStore === false) {
                            that.initializeCustomStore(true, isGridViewJSONEngine, staticJSONData);
                        } else {
                            that.initializeCustomStore(false, isGridViewJSONEngine, staticJSONData);
                        }
                    }, null);
                    break;
                }
            }
            if (this.props.dashboardLayoutDataViewMapping.length === 0 || !foundLayout) {
                initializeStore = true;
            }
        } else if (this.props.isLoadForTabbedView) {
            initializeStore = true;
        }

        if (initializeStore) {
            if (LDH.IsObjectNull(this.props.useStateStore) ||
                this.props.useStateStore === false) {
                this.initializeCustomStore(true, isGridViewJSONEngine, staticJSONData);
            } else {
                this.initializeCustomStore(false, isGridViewJSONEngine, staticJSONData);
            }
        }
    };

    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        let gridDefinition = this.props.gridDefinition;
        let persistentId = gridDefinition.dataViewPersistentId;
        if (!LDH.IsObjectNull(window["CacheData_GridView_PId_" + persistentId])) {
            delete window["CacheData_GridView_PId_" + persistentId];
        }

        LWH.RemovePendingEventByDataViewId(this.props.GridViewId);
        for (let i = 0; i < this.pendingWebsocketCallbacks.length; i++) {
            clearInterval(this.pendingWebsocketCallbacks[i]);
        }

        if (!LDH.IsObjectNull(this.uiObjectInstance.gridViewInstance)) {
            this.uiObjectInstance.gridViewInstance.dispose();
            let elem = document.getElementById(this.props.GridViewId);
            if (!LDH.IsObjectNull(elem)) {
                elem.parentNode && elem.parentNode.removeChild(elem);
            }
        }

        let popupInstances = this.disposablePopupInstances;
        LRH.DisposeUIInstancesFromList(popupInstances);

        let uiObjectInstance = this.uiObjectInstance;
        LRH.DisposeUIInstancesFromList(uiObjectInstance);

        let dataViewId = this.props.GridViewId;
        if (!LDH.IsObjectNull(gridDefinition.parameters) && gridDefinition.parameters.length > 0) {
            for (let k = 0; k < gridDefinition.parameters.length; k++) {
                let controlId = gridDefinition.parameters[k].parameterName;
                let controlName = "dataViewParam_" + dataViewId + "_control_" + controlId;
                if (!LDH.IsObjectNull(window[controlName])) {
                    delete window[controlName];
                }
            }
        }
        window["gridview_datasource_persistent_" + gridDefinition.dataViewPersistentId] = null;

        this.props.setGridViewInstance({
            instance: null,
            id: this.props.GridViewId,
            type: "datagrid",
            isDataView: true,
            optimizePagerForLargeDataset: this.optimizePagerForLargeDataset
        });
    };

    highlightCellText = (thisComp) => {
        let wordsOnly = false;
        if (!LDH.IsObjectNull(thisComp.props.gridDefinition.textHighlightWordsOnly) &&
            !LDH.IsValueEmpty(thisComp.props.gridDefinition.textHighlightWordsOnly)) {
            wordsOnly = thisComp.props.gridDefinition.textHighlightWordsOnly;
        }

        for (let i = 0; i < thisComp.highlightPendingList.length; i++) {
            if (LDH.IsObjectNull(thisComp.highlightPendingList[i])) {
                continue;
            }

            let $element = $("#" + thisComp.highlightPendingList[i].id);
            if ($element === null || $element.length === 0) {
                delete thisComp.highlightPendingList[i];
                continue;
            }
            if (!LDH.IsValueEmpty($element.attr("text-highlighted")) &&
                $element.attr("text-highlighted") === "true") {
                continue;
            }

            let words = thisComp.highlightPendingList[i].words;
            $element.highlight(words, {
                caseSensitive: false,
                wordsOnly: wordsOnly,
                className: 'leopard-text-highlighter',
                ignoreDiacritics: false
            }).attr("text-highlighted", true);
        }
    };

    initializeCustomStore = (isDataView, isGridViewJSONEngine, staticJSONData, isRefreshJSONData) => {
        let thisComp = this;
        let gridViewId = this.props.GridViewId;
        let gridDefinition = this.props.gridDefinition;
        let limitedColumns = [];
        let clientSideQuery = "";

        for (let u = 0; u < gridDefinition.columnDefinition.length; u++) {
            if (!LDH.IsValueEmpty(gridDefinition.columnDefinition[u].isEnabled) &&
                gridDefinition.columnDefinition[u].isEnabled === false) {
                limitedColumns.push(gridDefinition.columnDefinition[u].columnName);
            }
        }

        if (!LDH.IsValueEmpty(gridDefinition.clientSideQuery)) {
            clientSideQuery = gridDefinition.clientSideQuery;
        }

        let hasCustomQueryParams = false;
        if (!LDH.IsObjectNull(gridDefinition.oDataQueryForLinkedView) &&
            !LDH.IsValueEmpty(gridDefinition.oDataQueryForLinkedView)) {
            hasCustomQueryParams = true;
        }

        if (!LDH.IsObjectNull(gridDefinition.parameters) && gridDefinition.parameters.length > 0) {
            let parametersCloned = LDH.DeepClone(gridDefinition.parameters);
            for (let i = 0; i < parametersCloned.length; i++) {
                let item = parametersCloned[i];
                let defaultValue = (LDH.IsObjectNull(item.defaultValue) ||
                    LDH.IsValueEmpty(item.defaultValue)) ? null : item.defaultValue;

                defaultValue = LDH.FilterMacro(defaultValue);
                if (LDH.IsObjectNull(defaultValue) || LDH.IsValueEmpty(defaultValue)) {
                    defaultValue = null;
                }

                if (LDH.IsObjectNull(window["dataViewParam_" + gridViewId + "_control_" + item.parameterName])) {
                    window["dataViewParam_" + gridViewId + "_control_" + item.parameterName] = defaultValue;
                }
            }

            for (let i = 0; i < parametersCloned.length; i++) {
                let item = parametersCloned[i];
                if (!LDH.IsObjectNull(item.dataShapingForQuery) && !LDH.IsValueEmpty(item.dataShapingForQuery)) {
                    let javascript = item.dataShapingForQuery;
                    let dataName = "data";
                    let dataValue = [];
                    LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, gridViewId);
                }
            }
        }

        let url = this.props.GetDataFromUrl.replace("?tableName=", "");
        if (LDH.IsObjectNull(this.originalDataSourceUrl) || LDH.IsValueEmpty(this.originalDataSourceUrl)) {
            this.originalDataSourceUrl = LDH.APIEndpointAdapter() + LDH.GetODataAPIGatewayUrl(url + "&authentication=true");
        }
        if (!LDH.IsObjectNull(gridDefinition.dataSourceOverrideMeta) &&
            !LDH.IsValueEmpty(gridDefinition.dataSourceOverrideMeta)) {
            let gatewayUrl = gridDefinition.dataSourceOverrideMeta.replace("/$metadata", "");
            this.originalDataSourceUrl = LDH.APIEndpointAdapter() + gatewayUrl +
                "/" + url + "&authentication=true";
        }
        url = LDH.GetFilteredUrlByDataViewParameters(gridDefinition.parameters, url, gridViewId);

        let fullColumns = this.props.columnFieldList.fullColumns;
        let store = LRH.InitCustomStoreForGridView(gridDefinition, gridViewId, url, clientSideQuery,
            limitedColumns, function (gridData, ds) {
                thisComp.customStoreFinalizeProcess(gridData, ds, thisComp, gridDefinition, gridViewId, isDataView);
                if (!LDH.IsObjectNull(isRefreshJSONData) && isRefreshJSONData) {
                    if (isDataView === false) {
                        thisComp.setState({customStore: gridData});
                    } else {
                        thisComp.props.UpdateCustomStore(gridData, gridViewId);
                    }
                }

                if (!LDH.IsObjectNull(thisComp.props.gridDefinition.enableTextHighlight) &&
                    thisComp.props.gridDefinition.enableTextHighlight) {

                    for (let i = 0; i < thisComp.highlightPendingList.length; i++) {
                        if (typeof thisComp.highlightPendingList[i] === "undefined") {
                            thisComp.highlightPendingList.splice(i, 1);
                            i--;
                        }
                    }
                    setTimeout(function () {
                        thisComp.highlightCellText(thisComp);
                    }, 100);
                }
            },
            fullColumns, this, isDataView, hasCustomQueryParams, isGridViewJSONEngine, staticJSONData, function () {
                let highlightWords = thisComp.props.gridDefinition.textHighlightWords.split(",");
                thisComp.highlightWordsFromParent = [];

                for (let c = 0; c < highlightWords.length; c++) {
                    thisComp.highlightWordsFromParent.push(LDH.ConvertArrayMacroToString(highlightWords[c],
                        thisComp.selectedParentViewData, null));
                }

                for (let i = 0; i < thisComp.highlightPendingList.length; i++) {
                    if (LDH.IsObjectNull(thisComp.highlightPendingList[i])) {
                        continue;
                    }

                    let $element = $('#' + thisComp.highlightPendingList[i].id);
                    if ($element === null || $element.length === 0) {
                        continue;
                    }
                    if ($element.attr("text-highlighted") !== "true") {
                        continue;
                    }
                    $element.unhighlight({className: 'leopard-text-highlighter'});
                    $element.removeAttr("text-highlighted");
                    thisComp.highlightPendingList[i].words = thisComp.highlightWordsFromParent;
                }
            });

        if (LDH.IsObjectNull(isRefreshJSONData) || isRefreshJSONData === false) {
            if (thisComp.props.dataInitializedOnControls === false) {
                thisComp.props.dataInitializedOnControlsUpdateRequest();
            }
            if (isDataView === false) {
                this.gridDefinition = thisComp.props.gridDefinition;
                this.setState({customStore: store});
            } else {
                this.props.InitCustomStore(store, gridViewId);
                this.props.UpdateGridViewDefinition(gridDefinition, gridViewId);
                this.props.applyGridViewConfigurationOnDataView({
                    filterBuilderValue: gridDefinition.filterBuilderValue,
                    explicitFilterOption: gridDefinition.explicitFilterOption,
                    columnResizingMode: gridDefinition.columnResizingMode
                });
            }
        }
    };

    customStoreFinalizeProcess = (gridData, ds, thisComp, gridDefinition, gridViewId, isDataView) => {
        if (thisComp.disposingAllInstances === true) return;

        let persistentId = gridDefinition.dataViewPersistentId;
        window["CacheData_GridView_PId_" + persistentId] = gridData;

        let instance = thisComp.uiObjectInstance.gridViewInstance;
        let dynamicData = instance.option("dynamicDatasetFromMessaging");
        let isDynamicDatasetEmpty = LDH.IsObjectNull(dynamicData);
        instance.option("dynamicDatasetFromMessaging", null);
        instance.option("isExportData", false);

        if (typeof ds === "string" && ds === "json-data") {
            thisComp.totalRecordCount = gridData.length;
        } else {
            thisComp.totalRecordCount = ds.totalCount();
        }
        if (!LDH.IsObjectNull(gridData) && !LDH.IsObjectNull(gridData.length)) {
            thisComp.disableOrEnablePagerBasedOnRowCount(gridData.length, gridViewId);
        }
        $(".toolbar-warming-up-text", "#gridViewToobar_" + gridViewId).hide();
        thisComp.validateUserAuthenticationStatus();

        let selectByDefault = LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault;
        if (!LDH.IsObjectNull(instance) && gridData.length > 0 &&
            !LDH.IsObjectNull(instance.option("selectFirstRow")) &&
            instance.option("selectFirstRow") && !isDataView) {
            if (!LDH.IsObjectNull(thisComp.props.columnFieldList) &&
                !LDH.IsObjectNull(thisComp.props.columnFieldList.fullColumns) &&
                thisComp.props.columnFieldList.fullColumns.length > 0) {
                instance.selectRowsByIndexes(0);
                instance.option("selectFirstRow", false);
            }
        } else if (!isDataView && thisComp.props.dashboardLevel === 1 &&
            thisComp.isFirstRowSelectedOnInitLoad === false) {
            thisComp.isFirstRowSelectedOnInitLoad = true;
            if (!LDH.IsObjectNull(thisComp.props.columnFieldList) &&
                !LDH.IsObjectNull(thisComp.props.columnFieldList.fullColumns) &&
                thisComp.props.columnFieldList.fullColumns.length > 0) {
                instance.selectRowsByIndexes(0);
            }
        } else if (!isDataView && selectByDefault === true) {
            thisComp.isFirstRowSelectedOnInitLoad = true;
            if (!LDH.IsObjectNull(thisComp.props.columnFieldList) &&
                !LDH.IsObjectNull(thisComp.props.columnFieldList.fullColumns) &&
                thisComp.props.columnFieldList.fullColumns.length > 0) {
                instance.selectRowsByIndexes(0);
            }
        }

        if (isDynamicDatasetEmpty) {
            thisComp.updateGridViewDataSourceByWebsocket(gridDefinition, gridViewId, thisComp);
        }
        LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true, instance);
    };

    updateGridViewDataSourceByWebsocket = (gridDefinition, gridViewId, thisComp) => {
        if (!LDH.IsObjectNull(gridDefinition.realtimeMessages) && gridDefinition.realtimeMessages.length > 0) {
            for (let i = 0; i < gridDefinition.realtimeMessages.length; i++) {
                let realtimeMessage = gridDefinition.realtimeMessages[i];
                if (realtimeMessage.attachToEvent === "dataview-onload" &&
                    realtimeMessage.eventIdentifier === "leopardsystems.websocket.connections.stats") {
                    let eventKey = LDH.GenerateGuid();
                    let messageTemplate = LWH.GetMessageTemplate(
                        realtimeMessage.eventIdentifier,
                        window.websocketConnectionId, {}, eventKey, "userId"
                    );
                    let updateLocalDb = realtimeMessage.isUpdateLocalDataStore;
                    LWH.AddPendingEvent(eventKey, "", gridViewId, "leopard-gridview-userstatus-request", null, false);
                    LWH.SendMessage(JSON.stringify(messageTemplate));

                    let pendingCallback = LWH.WaitForWebsocketResponse(eventKey, function (response, responseData) {
                        if (response === "completed") {
                            thisComp.prepareDynamicDataSourceUpdate(gridDefinition, thisComp, responseData, gridViewId, updateLocalDb);
                        }
                    });
                    thisComp.pendingWebsocketCallbacks.push(pendingCallback);
                } else if (realtimeMessage.attachToEvent === "dataview-onload" &&
                    realtimeMessage.eventIdentifier === "leopardsystems.websocket.subscribe") {
                    let eventKey = LDH.GenerateGuid();
                    let messageTemplate = LWH.GetMessageTemplateForSubscription(
                        realtimeMessage.eventIdentifier,
                        window.websocketConnectionId, realtimeMessage.eventSubscription,
                        eventKey, "connectionId",
                    );
                    let updateLocalDb = realtimeMessage.isUpdateLocalDataStore;
                    LWH.AddPendingEvent(eventKey, "", gridViewId, realtimeMessage.eventSubscription, function (responseData) {
                        thisComp.prepareDynamicDataSourceUpdate(gridDefinition, thisComp, responseData, gridViewId, updateLocalDb);
                    }, true);
                    LWH.SendMessage(JSON.stringify(messageTemplate));
                }
            }
        }
    }

    prepareDynamicDataSourceUpdate = (gridDefinition, thisComp, responseData, gridViewId, isUpdateLocalDataStore) => {
        if (LDH.IsObjectNull(responseData)) return;
        let rootData = responseData;
        responseData = responseData.data;

        let instance = thisComp.uiObjectInstance.gridViewInstance;
        if (LDH.IsObjectNull(instance)) return;

        let dataSource = instance.option("dataSource");
        if (LDH.IsObjectNull(dataSource) || !isFunction(dataSource.items)) {
            return;
        }
        let items = dataSource.items();
        if (!LDH.IsObjectNull(gridDefinition.eventMessagingScript) &&
            !LDH.IsValueEmpty(gridDefinition.eventMessagingScript)) {
            let javascript = gridDefinition.eventMessagingScript;
            let dataName = "data";
            let dataValue = {
                dataItems: items, responseData: responseData,
                connectionId: window.websocketConnectionId,
                rootData: rootData,
                selectedParentViewData: this.selectedParentViewData
            };
            let dataArray = LDH.EvaluateJavaScriptForDataShaping(javascript,
                dataName, dataValue, gridViewId);
            thisComp.dynamicDataSourceUpdate(dataArray, isUpdateLocalDataStore);
        }
    };

    dynamicDataSourceUpdate = (dataArray, isUpdateLocalDataStore) => {
        let that = this;
        if (LDH.IsObjectNull(isUpdateLocalDataStore) || !isUpdateLocalDataStore) {
            return;
        }

        let instance = that.uiObjectInstance.gridViewInstance;
        instance.option("isDataUpdatedByEventMessaging", true);

        if (!LDH.IsObjectNull(dataArray) && dataArray.length > 0) {
            instance.option("dynamicDatasetFromMessaging", dataArray);
            instance.refresh();
        }
    };

    gridViewDataSourceOnChanged = (e, actionType, thisComp) => {
        let definition = thisComp.props.gridDefinition;
        let persistentId = definition.dataViewPersistentId;
        let datasource = window["gridview_datasource_persistent_" + persistentId];
        if (typeof datasource === "undefined") datasource = {};

        if (actionType === "inserted") {
            if (!LDH.IsObjectNull(definition.eventHandlerRowAdded) &&
                !LDH.IsValueEmpty(definition.eventHandlerRowAdded)) {
                let javascript = definition.eventHandlerRowAdded;
                let dataName = "data";
                let dataValue = {
                    dataSource: datasource,
                    dataFromParent: thisComp.selectedParentViewData,
                    dataViewType: "datagrid",
                    dataViewDefinition: definition,
                    eventType: actionType,
                    dataViewPersistentId: definition.dataViewPersistentId
                };
                LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                    dataValue, thisComp.props.GridViewId, null);
            }
        } else if (actionType === "removed") {
            if (!LDH.IsObjectNull(definition.eventHandlerRowDeleted) &&
                !LDH.IsValueEmpty(definition.eventHandlerRowDeleted)) {
                let javascript = definition.eventHandlerRowDeleted;
                let dataName = "data";
                let dataValue = {
                    dataSource: datasource,
                    dataToRemove: e.data,
                    dataFromParent: thisComp.selectedParentViewData,
                    dataViewType: "datagrid",
                    dataViewDefinition: definition,
                    eventType: actionType,
                    dataViewPersistentId: definition.dataViewPersistentId
                };
                LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                    dataValue, thisComp.props.GridViewId, null);
            }
        }

        if (!LDH.IsObjectNull(definition.sendDataOnDataSourceChangedPersistentId) &&
            !LDH.IsValueEmpty(definition.sendDataOnDataSourceChangedPersistentId)) {
            let instance = thisComp.uiObjectInstance.gridViewInstance;
            let dataSource = instance.option("dataSource");

            thisComp.actionItemSendDataToGenericDataView({
                dataSource: dataSource,
                rowData: e,
                definition: definition,
                actionType: actionType,
                persistentId: definition.sendDataOnDataSourceChangedPersistentId
            });
        }
    };

    validateUserAuthenticationStatus = () => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    getCurrentState = (gridViewState, setThisAsStore) => {
        let currentState = this.state;
        if (LDH.IsObjectNull(this.props.useStateStore) || this.props.useStateStore === false) {
            currentState = gridViewState.filter(gridView => {
                return gridView.gridViewId === this.props.GridViewId;
            });
            if (!LDH.IsObjectNull(currentState) && currentState.length > 0) {
                currentState = currentState[0];
            }
        } else {
            if (setThisAsStore) {
                currentState = this;
            } else if (LDH.IsObjectNull(this.state.customStore)) {
                currentState = null;
            }
        }
        if (LDH.IsObjectNull(currentState) || currentState.length === 0) {
            currentState = null;
        }
        return currentState;
    };

    onRowExpanding = (e) => {
        e.component.collapseAll(-1);
    };

    onToolbarPreparing = (e) => {
        let toolbarItems = e.toolbarOptions.items;
        for (let i = 0; i < toolbarItems.length; i++) {
            if (toolbarItems[i].name === "addRowButton" ||
                toolbarItems[i].name === "exportButton") {
                toolbarItems[i].visible = false;
            }
        }
    };

    onInitialized = (e) => {
        e.component.columnOption("command:edit", "width", 100);
    };

    onDataErrorOccurred = (e) => {
        let that = this;
        let gridViewId = this.props.GridViewId;
        let hasErrorOccurred = false;

        if (!LDH.IsObjectNull(e) && typeof e === "object" && !LDH.IsObjectNull(e.error) &&
            !LDH.IsObjectNull(e.error.httpStatus) && e.error.httpStatus === 403) {
            LRH.ShowToast("Failed to request data from the server. " +
                "Please refresh your browser and try again.",
                "error", 5000);
            hasErrorOccurred = true;
        }

        if (!LDH.IsTimeoutReceivedFromAPIGateway(e) && !hasErrorOccurred &&
            !LDH.IsObjectNull(e) && typeof e === "object" && !LDH.IsObjectNull(e.error) &&
            !LDH.IsObjectNull(e.error.httpStatus) && e.error.httpStatus === 400 &&
            !LDH.IsObjectNull(e.error.errorDetails) &&
            !LDH.IsValueEmpty(e.error.errorDetails.message)) {
            LRH.ShowToast("Your query is incomplete or invalid, " +
                "please correct the errors before proceeding. Error code: 400",
                "error", 5000);
            hasErrorOccurred = true;
            console.log("Error occurred on Grid View", e);
        }

        if (!LDH.IsObjectNull(that.uiObjectInstance.gridViewInstance) &&
            !LDH.IsObjectNull(that.uiObjectInstance.gridViewInstance.option("isExportData")) &&
            that.uiObjectInstance.gridViewInstance.option("isExportData") === true &&
            LDH.IsTimeoutReceivedFromAPIGateway(e)) {
            LRH.ShowToast("Failed to export data from the grid view. Please try again later.",
                "error", 5000);
            that.uiObjectInstance.gridViewInstance.option("isExportData", false);
            return;
        }

        if (LDH.IsTimeoutReceivedFromAPIGateway(e) && this.retryConnectionCount < 10 &&
            !hasErrorOccurred) {
            this.retryConnectionCount += 1;
            $(".toolbar-warming-up-text", $("#gridViewToobar_" + gridViewId)).show();

            setTimeout(function () {
                let instance = that.uiObjectInstance.gridViewInstance;
                let gridViewState = that.props.state.gridViewState;
                let currentState = that.getCurrentState(gridViewState, false);
                instance.option("dataSource", currentState.customStore);
            }, 100);
        } else {
            hasErrorOccurred = true;
        }

        if (hasErrorOccurred) {
            if (that.props.dataInitializedOnControls === false) {
                that.props.dataInitializedOnControlsUpdateRequest();
            }
            $(".toolbar-warming-up-text", $("#gridViewToobar_" + gridViewId)).hide();
            this.retryConnectionCount = 0;

            let instance = that.uiObjectInstance.gridViewInstance;
            LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true, instance);
        }
    };

    registerGridViewColumnHeaderEvents = () => {
        let that = this;
        $(".dx-texteditor-input-container", "#" + this.props.GridViewId).each(function () {
            $("input.dx-texteditor-input", $(this)).each(function () {
                $(this).off("keypress").on("keypress", function (e) {
                    if (e.which === 13) {
                        let id = that.props.GridViewId;
                        if ($("#GridView_TopBar_ApplyFilter_" + id).hasClass("leopard-ui-disabled")) {
                            return;
                        }
                        $(".dx-datagrid-toolbar-button.dx-apply-button", "#" + id).trigger("click");
                    }
                })
            });
        });

        $(".dx-overlay-wrapper.dx-datagrid-filter-range-overlay .dx-texteditor-input-container").each(function () {
            $("input.dx-texteditor-input", $(this)).each(function () {
                $(this).off("keypress").on("keypress", function (e) {
                    if (e.which === 13) {
                        let id = that.props.GridViewId;
                        if ($("#GridView_TopBar_ApplyFilter_" + id).hasClass("leopard-ui-disabled")) {
                            return;
                        }
                        $(".dx-datagrid-toolbar-button.dx-apply-button", "#" + id).trigger("click");
                    }
                })
            });
        });
    };

    resetPagingAndRefresh = (isRefresh, gridViewId) => {
        let $pager = $(".leopard-page-number.selected", "#GridViewPager_" + gridViewId);

        let pageSize = this.uiObjectInstance.gridViewInstance.pageSize();
        if (!LDH.IsObjectNull($pager) && $pager.length > 0) {
            pageSize = parseInt($pager.attr("pageSize").trim());
        }

        this.uiObjectInstance.gridViewInstance.option("customPagingOperation", "");
        this.uiObjectInstance.gridViewInstance.option("customPagingIndex", 0);
        this.uiObjectInstance.gridViewInstance.option("customPagingSize", pageSize);
        if (isRefresh) {
            let instance = this.uiObjectInstance.gridViewInstance;
            let gridViewState = this.props.state.gridViewState;
            let currentState = this.getCurrentState(gridViewState, false);
            instance.option("dataSource", currentState.customStore);
        }
    };

    setPagerLightMode = (e) => {
        try {
            let pagerView = e.component.getView("pagerView");
            if (!LDH.IsObjectNull(pagerView) && !this.optimizePagerForLargeDataset) {
                let pager = pagerView.element().dxPager("instance");
                if (e.element.clientWidth <= 495) {
                    pager.option("lightModeEnabled", true);
                } else {
                    pager.option("lightModeEnabled", false);
                }
            }
        } catch (error) {

        }
    };

    onContentReady = (e) => {
        let that = this;
        let $grid = $("#" + this.props.GridViewId);
        that.setPagerLightMode(e);
        that.highlightCellText(that);

        // ------ Temporary hide the group panel for now until we work on it. ----------
        let headerPanelId = "#" + this.props.GridViewId + " .dx-datagrid-header-panel";
        $(headerPanelId).hide();
        // -----------------------------------------------------------------------------

        let gridViewId = this.props.GridViewId;
        if (that.optimizePagerForLargeDataset === true) {
            $(".leopard-page-number", "#GridViewPager_" + gridViewId).each(function () {
                $(this).off("click").on("click", function () {
                    if ($("#GridViewPager_" + gridViewId).hasClass("leopard-ui-disabled")) {
                        return false;
                    }
                    let $id = $("#GridViewPager_" + gridViewId);
                    $(".leopard-page-number", $id).removeClass("selected");
                    $(this).addClass("selected");
                    that.resetPagingAndRefresh(true, gridViewId);
                    return false;
                });
            });
        }

        $(".leopard_gridview_column_template .dx-editor-with-menu", $grid).each(function () {
            $(this).off("mousedown").on("mousedown", function () {
                setTimeout(function () {
                    that.registerGridViewColumnHeaderEvents();
                }, 100);
            });
        });
        $(".leopard_gridview_column_template .dx-menu-item-wrapper", $grid).each(function () {
            $(this).off("mouseenter").on("mouseenter", function () {
                setTimeout(function () {
                    $(".dx-overlay-wrapper .dx-menu-item-content").each(function () {
                        $(this).off("click").on("click", function () {
                            setTimeout(function () {
                                that.registerGridViewColumnHeaderEvents();
                            }, 500);
                        })
                    });
                }, 500);
            });
        });
        that.registerGridViewColumnHeaderEvents();
    };

    groupingButtonOnClick = () => {
        this.uiObjectInstance.gridViewInstance.updateDimensions();
    };

    viewOptionsButtonOnClick = (data) => {
        if ($("#GridView_TopBar_ViewOptions_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        this.uiObjectInstance.gridViewInstance.showColumnChooser();
    };

    addRowButtonOnClick = (data) => {
        let that = this;
        data.getDataFromUrl = that.props.GetDataFromUrl;
        data.getDataFromUrl = data.getDataFromUrl.replace("?tableName=", "");
        window.Global_PopupCustomColumnData = data;

        let gridview = this.uiObjectInstance.gridViewInstance;
        window.Global_PopupTempParentData = gridview.option("dataFromSource");

        let instance = that.uiObjectInstance.popupEditFormInstance;
        if (instance === null) return;

        window.Global_PopupTempObjectInstance = instance;
        instance.option("visible", true);
    };

    exportButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Export_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        let rowLimit = LeopardStaticUIConfig.GridView_ExportMaximumRowLimit;

        if (LDH.IsObjectNull(data.gridDefinition)) {
            if (this.totalRecordCount > rowLimit) {
                LRH.ShowToast("We are unable to export your data. Make sure your data has less than " +
                    rowLimit + " rows.", "error", 5000);
                return;
            }
            this.uiObjectInstance.gridViewInstance.option("isExportData", true);
            LRH.exportToExcelLogic(this.uiObjectInstance.gridViewInstance, "DataGrid.xlsx", data.gridDefinition, data.gridViewId);
            return;
        }

        if (!LDH.IsObjectNull(data.gridDefinition.exportMaximumRowLimit) &&
            !LDH.IsValueEmpty(data.gridDefinition.exportMaximumRowLimit)) {
            rowLimit = data.gridDefinition.exportMaximumRowLimit;
        }
        if (this.totalRecordCount > rowLimit) {
            LRH.ShowToast("We are unable to export your data. Make sure your data has less than " +
                rowLimit + " rows.", "error", 5000);
            return;
        }
        if (LDH.IsObjectNull(data.gridDefinition.exportFormat) ||
            data.gridDefinition.exportFormat === "UserSelection") {
            this.uiObjectInstance.exportOptionPopupInstance.option("visible", true);
        } else {
            this.uiObjectInstance.gridViewInstance.option("isExportData", true);
            LRH.exportToExcelLogic(this.uiObjectInstance.gridViewInstance, "DataGrid.xlsx", data.gridDefinition, data.gridViewId);
        }
    };

    uploadButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Upload_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        let definition = data.gridDefinition;
        if (definition.uploadOption === "document-service") {
            this.uiObjectInstance["documentUploaderControl"].option("visible", true);
        }
    };

    downloadButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Download_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        if (LDH.IsObjectNull(this.selectedGridViewRowData) ||
            LDH.IsObjectNull(this.selectedGridViewRowData.selectedRowsData) ||
            this.selectedGridViewRowData.selectedRowsData.length === 0) {
            LRH.ShowToast("Please select a row on the Data Grid first.", "error", 5000);
            return;
        }

        let rowData = this.selectedGridViewRowData.selectedRowsData[0];
        let definition = data.gridDefinition;
        if (definition.downloadOption === "document-service") {
            let userId = rowData["owner"];
            let name = rowData["name"];
            let docType = rowData["type"];
            let instance = this.uiObjectInstance.gridViewInstance;
            LRH.EnableOrDisableGridViewToolbarButtons(data.gridViewId, false, instance);

            let documentRetrieveType = "leopardsystems.document.retrieve";
            if (!LDH.IsValueEmpty(definition.documentRetrieveType)) {
                documentRetrieveType = definition.documentRetrieveType;
            }

            LeopardAjaxHelper.RetrieveDocumentFromS3(userId, name, docType, function (documentData) {
                LRH.DownloadStringToFile(name, documentData);
                LRH.EnableOrDisableGridViewToolbarButtons(data.gridViewId, true, instance);
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve document.", "error", 5000);
                }
                LRH.EnableOrDisableGridViewToolbarButtons(data.gridViewId, true, instance);
            }, documentRetrieveType);
        }
    };

    layoutButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Layouts_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        this.uiObjectInstance["layoutPopoverControl"].option("visible", true);
    };

    exportDataOnPopupClicked = (data) => {
        let that = this;
        that.exportOptionFromPopup = data;
        that.uiObjectInstance.gridViewInstance.option("isExportData", true);
        if (data === "CSV") {
            LRH.exportToCsvLogic(this.uiObjectInstance.gridViewInstance, "DataGrid.csv", that.props.gridDefinition, that.props.GridViewId);
        } else {
            LRH.exportToExcelLogic(this.uiObjectInstance.gridViewInstance, "DataGrid.xlsx", that.props.gridDefinition, that.props.GridViewId);
        }
        that.exportOptionPopupOnHiding();
    };

    prepareDataPostList = (dataPostLogic, dataViewId, rowData) => {
        let dataSendToView = {};
        dataSendToView["rowData"] = rowData;

        let javascript = dataPostLogic;
        let dataName = "data";
        let dataValue = dataSendToView;
        return LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
            dataValue, dataViewId, null);
    };

    publishFormToSpecificFolder = (definition, rowData, gridViewId, thisComp, optionData) => {
        if (definition.downloadOption === "document-service") {
            let profile = window.userProfile;
            let userId = LeopardDataHelper.GetOrganizationIdFromUserProfile(profile);

            let documentRetrieveType = "leopardsystems.form.retrieve";
            let documentListType = "leopardsystems.form.list";
            let documentModifyType = "leopardsystems.form.modify";
            let documentCreateType = "leopardsystems.form.create";
            let name = rowData["name"];
            let docType = rowData["type"];
            let instance = thisComp.uiObjectInstance.gridViewInstance;
            let uploadDirectory = JSON.parse(optionData.eventSyncRequest).uploadDirectory;
            LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, false, instance);

            LeopardAjaxHelper.RetrieveDocumentFromS3(userId, name, docType, function (documentData) {
                LeopardAjaxHelper.InsertOrUpdateDocumentToS3(documentData,
                    name, uploadDirectory, function () {
                        LRH.ShowToast("Your file has been successfully published.", "success", 5000);

                        if (!LDH.IsObjectNull(optionData.autoRefreshTimeAfterSubmit) &&
                            !LDH.IsValueEmpty(optionData.autoRefreshTimeAfterSubmit)) {
                            setTimeout(function () {
                                thisComp.refreshButtonOnClick({
                                    gridViewId: gridViewId,
                                    definition: definition,
                                    refreshChildViews: false
                                });
                            }, optionData.autoRefreshTimeAfterSubmit * 1000);
                        }
                    }, function () {
                        LRH.ShowToast("Failed to upload the file.", "error", 5000);
                    }, "orgid", documentListType, documentModifyType, documentCreateType);

                LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true, instance);
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve document.", "error", 5000);
                }
                LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true, instance);
            }, documentRetrieveType);
        }
    };

    actionItemOnPopupClicked = (options) => {
        let that = this;
        let dataFromActionGroup = null;

        if (options.clickedFrom === "command-link") {
            let $link = $(options.e.target).closest(".export-option-popup-link");
            if ($link.hasClass("disabled")) return;

            let popover = that.uiObjectInstance[options.actionGroupInstanceId];
            dataFromActionGroup = popover.option("rowData");
        } else {
            dataFromActionGroup = options;
        }

        let actionBehaviour = options.data.actionItemBehaviour;
        let rowData = LDH.DeepClone(dataFromActionGroup.e.row.data);
        if (!LDH.IsObjectNull(rowData) && !LDH.IsObjectNull(options.data.apiGatewayPath) &&
            !LDH.IsValueEmpty(options.data.apiGatewayPath)) {
            let rowDataKeys = Object.keys(rowData);
            for (let v = 0; v < rowDataKeys.length; v++) {
                let dataToSearch = "{" + rowDataKeys[v] + "}"
                if (options.data.apiGatewayPath.indexOf(dataToSearch) > -1) {
                    let cellValue = rowData[rowDataKeys[v]];
                    let newPath = LDH.ReplaceAll(options.data.apiGatewayPath, dataToSearch, cellValue);
                    options.data.apiGatewayPath = newPath;
                }
            }
        } else if (!LDH.IsObjectNull(rowData) && !LDH.IsObjectNull(options.data.eventSyncRequest) &&
            !LDH.IsValueEmpty(options.data.eventSyncRequest)) {
            let rowDataKeys = Object.keys(rowData);
            for (let v = 0; v < rowDataKeys.length; v++) {
                let dataToSearch = "{" + rowDataKeys[v] + "}"
                if (options.data.eventSyncRequest.indexOf(dataToSearch) > -1) {
                    let cellValue = rowData[rowDataKeys[v]];
                    let newPath = LDH.ReplaceAll(options.data.eventSyncRequest, dataToSearch, cellValue);
                    options.data.eventSyncRequest = newPath;
                }
            }
        }

        if (!LDH.IsObjectNull(actionBehaviour) && actionBehaviour === "report-scheduler") {
            if (options.clickedFrom === "command-link") {
                let schedulerPopup = that.uiObjectInstance.popupReportSchedulerInstance;
                window.Global_PopupTempObjectInstance = schedulerPopup;
                window.Global_PopupCustomColumnData = {
                    rowData: rowData
                };
                schedulerPopup.option("visible", true);

                let popover = that.uiObjectInstance[options.actionGroupInstanceId];
                popover.option("visible", false);
            }
        } else if (!LDH.IsObjectNull(actionBehaviour) && actionBehaviour === "send-request") {
            let dialogTitle = options.data.confirmationDialogTitle;
            let dialogDesc = options.data.confirmationDialogDescription;
            let dropdownYes = LeopardDropdownHelper.DropdownSelectionYesNo[1].id;

            if (options.data.confirmationDialogVisibility === dropdownYes &&
                !LDH.IsObjectNull(dialogTitle) && !LDH.IsValueEmpty(dialogTitle) &&
                !LDH.IsObjectNull(dialogDesc) && !LDH.IsValueEmpty(dialogDesc)) {
                LRH.ShowDialog(dialogDesc, dialogTitle, function () {
                    if (options.data.apiGatewayPath === "/publishform") {
                        that.publishFormToSpecificFolder(options.definition, rowData, options.gridViewId, that, options.data);
                    } else {
                        that.actionItemApiGatewayInvoker(options, dataFromActionGroup);
                    }
                });
            } else {
                if (options.data.apiGatewayPath === "/publishform") {
                    that.publishFormToSpecificFolder(options.definition, rowData, options.gridViewId, that, options.data);
                } else {
                    that.actionItemApiGatewayInvoker(options, dataFromActionGroup);
                }
            }

            if (options.clickedFrom === "command-link") {
                let popover = that.uiObjectInstance[options.actionGroupInstanceId];
                popover.option("visible", false);
            }
        } else if (!LDH.IsObjectNull(actionBehaviour) && actionBehaviour === "send-data-to-childview") {
            let dialogTitle = options.data.confirmationDialogTitle;
            let dialogDesc = options.data.confirmationDialogDescription;
            let dropdownYes = LeopardDropdownHelper.DropdownSelectionYesNo[1].id;

            if (options.data.confirmationDialogVisibility === dropdownYes &&
                !LDH.IsObjectNull(dialogTitle) && !LDH.IsValueEmpty(dialogTitle) &&
                !LDH.IsObjectNull(dialogDesc) && !LDH.IsValueEmpty(dialogDesc)) {
                LRH.ShowDialog(dialogDesc, dialogTitle, function () {
                    that.actionItemSendDataToView({
                        commandLinkData: options.data,
                        rowData: rowData,
                        definition: options.definition
                    });
                });
            } else {
                that.actionItemSendDataToView({
                    commandLinkData: options.data,
                    rowData: rowData,
                    definition: options.definition
                });
            }
        } else if (!LDH.IsObjectNull(actionBehaviour) && actionBehaviour === "remove-local-data") {
            if (!LDH.IsObjectNull(options.data.customDataPostLogic) && !LDH.IsValueEmpty(options.data.customDataPostLogic)) {
                let docDataList = that.prepareDataPostList(options.data.customDataPostLogic, dataFromActionGroup.gridViewId, rowData);
                let docDataListStr = JSON.stringify(docDataList);
                docDataListStr = LDH.FilterMacro(docDataListStr, null, null);
                docDataList = JSON.parse(docDataListStr);

                let processedCount = 0;
                for (let i = 0; i < docDataList.length; i++) {
                    let urlPath = docDataList[i]["__ApiUrl"];
                    let jsonData = docDataList[i];

                    LeopardAjaxHelper.GridViewCRUD_InsertDataWithoutUserData(urlPath, jsonData, function () {
                        processedCount++;
                        if (processedCount >= docDataList.length) {
                            let rowIndex = options.e.row.rowIndex;
                            that.uiObjectInstance.gridViewInstance.deleteRow(rowIndex);

                            if (options.clickedFrom === "command-link") {
                                let popover = that.uiObjectInstance[options.actionGroupInstanceId];
                                popover.option("visible", false);
                            }
                        }
                    }, function (ex) {
                        processedCount++;
                        that.formSubmissionErrorCallback(that, ex);
                    });
                }
            } else {
                let rowIndex = options.e.row.rowIndex;
                that.uiObjectInstance.gridViewInstance.deleteRow(rowIndex);

                if (options.clickedFrom === "command-link") {
                    let popover = that.uiObjectInstance[options.actionGroupInstanceId];
                    popover.option("visible", false);
                }
            }
        } else {
            let dataToPass = {
                data: LDH.DeepClone(options.data),
                definition: LDH.DeepClone(dataFromActionGroup.definition),
                gridViewId: dataFromActionGroup.gridViewId,
                id: dataFromActionGroup.id,
                rowData: rowData,
                limitedColumns: LDH.DeepClone(dataFromActionGroup.limitedColumns)
            };
            dataToPass.data.getDataFromUrl = that.props.GetDataFromUrl;
            dataToPass.data.getDataFromUrl = dataToPass.data.getDataFromUrl.replace("?tableName=", "");
            window.Global_PopupCustomColumnData = dataToPass;

            let instance = that.uiObjectInstance.popupEditFormInstance;
            if (instance === null) return;

            window.Global_PopupTempObjectInstance = instance;
            if (options.clickedFrom === "command-link") {
                let popover = that.uiObjectInstance[options.actionGroupInstanceId];
                popover.option("visible", false);
            }
            instance.option("visible", true);
        }
    };

    actionItemSendDataToView = (data) => {
        let listeners = LeopardStaticUIConfig.Global_DashboardDataViewSiteAccessRegister;
        let persistentId = data.commandLinkData.sendDataToViewByPersistentId;
        if (LDH.IsObjectNull(persistentId) || LDH.IsValueEmpty(persistentId)) {
            return;
        }

        for (let v = 0; v < listeners.length; v++) {
            if (listeners[v].dataViewPersistentId === persistentId) {
                if (LDH.IsObjectNull(listeners[v]) || LDH.IsObjectNull(listeners[v].instance)) {
                    continue;
                }
                listeners[v].callback({
                    dataFromSource: data.rowData,
                    dataFromParent: this.selectedParentViewData,
                    dataViewType: "datagrid",
                    parentGridDef: data.definition,
                    actionType: "send-data-to-childview",
                    commandLinkData: data.commandLinkData
                });
            }
        }
    };

    actionItemSendDataToGenericDataView = (data) => {
        let listeners = LeopardStaticUIConfig.Global_DashboardDataViewSiteAccessRegister;
        let persistentId = data.persistentId;
        if (LDH.IsObjectNull(persistentId) || LDH.IsValueEmpty(persistentId)) {
            return;
        }

        for (let v = 0; v < listeners.length; v++) {
            if (listeners[v].dataViewPersistentId === persistentId) {
                if (LDH.IsObjectNull(listeners[v]) || LDH.IsObjectNull(listeners[v].instance)) {
                    continue;
                }
                listeners[v].callback({
                    dataSource: data.dataSource,
                    rowData: data.rowData,
                    dataFromParent: this.selectedParentViewData,
                    dataViewType: "datagrid",
                    definition: data.definition,
                    actionType: data.actionType,
                    persistentId: persistentId
                });
            }
        }
    };

    actionItemApiGatewayInvoker(options, dataFromActionGroup) {
        let that = this;

        let method = options.data.httpRequestType;
        if (LDH.IsObjectNull(method) || LDH.IsValueEmpty(method)) {
            method = "post";
        }

        let url = options.data.apiGatewayPath;
        let requestBody = null;

        let instance = that.uiObjectInstance.gridViewInstance;
        LRH.EnableOrDisableGridViewToolbarButtons(that.props.GridViewId, false, instance);

        if (!LDH.IsObjectNull(options.data.queryStringScript) && !LDH.IsValueEmpty(options.data.queryStringScript)) {
            let javascript = options.data.queryStringScript;
            let dataName = "parentRowData";
            let dataValue = dataFromActionGroup.e.row.data;
            javascript = LDH.FilterMacro(javascript);
            javascript = LDH.ConvertArrayMacroToString(javascript, dataFromActionGroup.e.row.data);
            let resultData = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, that.props.GridViewId);
            url = url + resultData;
        }

        if (!LDH.IsObjectNull(options.data.jsonDataForHttpRequest) && !LDH.IsValueEmpty(options.data.jsonDataForHttpRequest)) {
            let javascript = options.data.jsonDataForHttpRequest;
            let dataName = "parentRowData";
            let dataValue = dataFromActionGroup.e.row.data;
            javascript = LDH.FilterMacro(javascript);
            javascript = LDH.ConvertArrayMacroToString(javascript, dataFromActionGroup.e.row.data);
            requestBody = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, that.props.GridViewId);
        }

        if (!LDH.IsObjectNull(options.data.enableIteration) && options.data.enableIteration === true) {
            let hasError = false;
            let processedIndex = 0;

            for (let i = 0; i < requestBody.length; i++) {
                LeopardAjaxHelper.GenericHttpRequest(method, url, requestBody[i], function (response) {
                    processedIndex++;
                    if (!LDH.IsObjectNull(options.data.httpResponseCallbackScript) &&
                        !LDH.IsValueEmpty(options.data.httpResponseCallbackScript)) {
                        let javascript = options.data.httpResponseCallbackScript;
                        let dataName = "parentRowData";
                        let dataValue = dataFromActionGroup.e.row.data;
                        requestBody = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, that.props.GridViewId);
                    }

                    if (processedIndex >= requestBody.length) {
                        LRH.ShowToast("Your request has been successfully processed.", "success", 5000);
                        LRH.EnableOrDisableGridViewToolbarButtons(that.props.GridViewId, true, instance);

                        if (!LDH.IsObjectNull(options.data.autoRefreshTimeAfterSubmit) &&
                            !LDH.IsValueEmpty(options.data.autoRefreshTimeAfterSubmit)) {
                            setTimeout(function () {
                                that.refreshButtonOnClick({
                                    gridViewId: that.props.GridViewId,
                                    definition: that.props.gridDefinition,
                                    refreshChildViews: false
                                });
                            }, options.data.autoRefreshTimeAfterSubmit * 1000);
                        }
                    }
                }, function (error, sessionTimeout) {
                    processedIndex++;
                    if (hasError === true) return;
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to process your request. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    LRH.EnableOrDisableGridViewToolbarButtons(that.props.GridViewId, true, instance);
                    hasError = true;
                }, false, true);
            }
        } else {
            LeopardAjaxHelper.GenericHttpRequest(method, url, requestBody, function (response) {
                if (!LDH.IsObjectNull(options.data.httpResponseCallbackScript) &&
                    !LDH.IsValueEmpty(options.data.httpResponseCallbackScript)) {
                    let javascript = options.data.httpResponseCallbackScript;
                    let dataName = "parentRowData";
                    let dataValue = dataFromActionGroup.e.row.data;
                    requestBody = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, that.props.GridViewId);
                }
                LRH.ShowToast("Your request has been successfully processed.", "success", 5000);
                LRH.EnableOrDisableGridViewToolbarButtons(that.props.GridViewId, true, instance);

                if (!LDH.IsObjectNull(options.data.autoRefreshTimeAfterSubmit) &&
                    !LDH.IsValueEmpty(options.data.autoRefreshTimeAfterSubmit)) {
                    setTimeout(function () {
                        that.refreshButtonOnClick({
                            gridViewId: that.props.GridViewId,
                            definition: that.props.gridDefinition,
                            refreshChildViews: false
                        });
                    }, options.data.autoRefreshTimeAfterSubmit * 1000);
                }
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to process your request. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                LRH.EnableOrDisableGridViewToolbarButtons(that.props.GridViewId, true, instance);
            }, false, true);
        }
    }

    importButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Import_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        $("#GridView_TopBar_ImportWidget_" + data.gridViewId).val(null).trigger("click");
    };

    importDataCallback = (e) => {
        if ($("#GridView_TopBar_Import_" + e.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        window.Global_PopupTempData = {
            data: LDH.ConvertCsvToJSON(e.data),
            definition: this.props.gridDefinition
        };
        if (LDH.IsObjectNull(this.props.gridDefinition.dataImportOption) ||
            this.props.gridDefinition.dataImportOption === "popup") {
            let instance = this.uiObjectInstance.popupDataImportInstance;
            window.Global_PopupTempObjectInstance = instance;
            $("#GridView_TopBar_ImportWidget_" + e.gridViewId).val(null);
            instance.option("visible", true);
        } else if (this.props.gridDefinition.dataImportOption === "gridview") {
            let data = window.Global_PopupTempData.data;

            for (let i = 0; i < data.length; i++) {
                data[i]["Processed"] = "";
                data[i]["OrderIndex"] = i + 1;
            }
            $("#GridView_TopBar_Submit_" + e.gridViewId).removeClass("leopard-ui-disabled");

            if (!LDH.IsObjectNull(data) && data.length > 0) {
                this.dynamicGridViewHeaders = Object.keys(data[0]);
            }

            if (!LDH.IsObjectNull(this.props.gridDefinition.appendDataForImport) &&
                this.props.gridDefinition.appendDataForImport) {
                let instance = this.uiObjectInstance.gridViewInstance;
                let datasource = instance.option("dataSource");
                let datasourceCloned = datasource.map(function (e) {
                    return e;
                });
                for (let i = 0; i < data.length; i++) {
                    data[i]["OrderIndex"] = datasourceCloned.length + 1;
                    datasourceCloned.push(data[i]);
                }

                if ((LDH.IsObjectNull(this.props.gridDefinition.allowImportDuplicates) ||
                        !this.props.gridDefinition.allowImportDuplicates) &&
                    !LDH.IsObjectNull(this.props.gridDefinition.columnIdForSearchDuplicates) &&
                    !LDH.IsValueEmpty(this.props.gridDefinition.columnIdForSearchDuplicates)) {
                    datasourceCloned = LDH.RemoveDuplicatesFromArray(datasourceCloned,
                        this.props.gridDefinition.columnIdForSearchDuplicates);
                }

                if ((!LDH.IsObjectNull(this.props.gridDefinition.maxNumberOfRowsForImport) &&
                    !LDH.IsValueEmpty(this.props.gridDefinition.maxNumberOfRowsForImport) &&
                    this.props.gridDefinition.maxNumberOfRowsForImport > 0)) {
                    if (datasourceCloned.length > this.props.gridDefinition.maxNumberOfRowsForImport) {
                        let maxRow = this.props.gridDefinition.maxNumberOfRowsForImport;
                        LRH.ShowToast("Your data has reached the maximum " + maxRow + " rows.", "error", 5000);
                        return;
                    }
                }

                if (LDH.IsObjectNull(this.props.useStateStore) || !this.props.useStateStore) {
                    this.props.UpdateCustomStore(datasourceCloned, e.gridViewId);
                } else {
                    this.setState({customStore: datasourceCloned});
                }
            } else {
                if ((LDH.IsObjectNull(this.props.gridDefinition.allowImportDuplicates) ||
                        !this.props.gridDefinition.allowImportDuplicates) &&
                    !LDH.IsObjectNull(this.props.gridDefinition.columnIdForSearchDuplicates) &&
                    !LDH.IsValueEmpty(this.props.gridDefinition.columnIdForSearchDuplicates)) {
                    data = LDH.RemoveDuplicatesFromArray(data,
                        this.props.gridDefinition.columnIdForSearchDuplicates);
                }

                if ((!LDH.IsObjectNull(this.props.gridDefinition.maxNumberOfRowsForImport) &&
                    !LDH.IsValueEmpty(this.props.gridDefinition.maxNumberOfRowsForImport) &&
                    this.props.gridDefinition.maxNumberOfRowsForImport > 0)) {
                    if (data.length > this.props.gridDefinition.maxNumberOfRowsForImport) {
                        let maxRow = this.props.gridDefinition.maxNumberOfRowsForImport;
                        LRH.ShowToast("Your data has reached the maximum " + maxRow + " rows.", "error", 5000);
                        return;
                    }
                }

                if (LDH.IsObjectNull(this.props.useStateStore) || !this.props.useStateStore) {
                    this.props.UpdateCustomStore(data, e.gridViewId);
                } else {
                    this.setState({customStore: data});
                }
            }
        }
    };

    removeAllButtonOnClick = (e) => {
        let that = this;
        if (LDH.IsObjectNull(that.props.useStateStore) || !that.props.useStateStore) {
            that.props.UpdateCustomStore([], e.gridViewId);
        } else {
            that.setState({customStore: []});
        }
    }

    submitDataCallback = (e) => {
        if ($("#GridView_TopBar_Submit_" + e.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        let instance = this.uiObjectInstance.gridViewInstance;
        let datasource = instance.option("dataSource");

        if (LDH.IsObjectNull(datasource) || datasource.length === 0) {
            LRH.ShowToast("Data cannot be found. Please load a CSV file first.", "error", 5000);
            return;
        }

        let newDataSource = [];
        for (let i = 0; i < datasource.length; i++) {
            if (typeof datasource[i]["Processed"] === "undefined") {
                continue;
            }
            if (datasource[i]["Processed"] !== "DONE") {
                newDataSource.push(datasource[i]);
            }
        }
        datasource = newDataSource;

        if (LDH.IsObjectNull(datasource) || datasource.length === 0) {
            LRH.ShowToast("Data cannot be found. Please load a CSV file first.", "error", 5000);
            if (LDH.IsObjectNull(this.props.useStateStore) || !this.props.useStateStore) {
                this.props.UpdateCustomStore(datasource, e.gridViewId);
            } else {
                this.setState({customStore: datasource});
            }
            return;
        }

        if (!LDH.IsObjectNull(e.definition.columnIdForSearchDuplicates) &&
            !LDH.IsValueEmpty(e.definition.columnIdForSearchDuplicates)) {
            let hasDuplicates = LDH.IsArrayContainDuplicates(datasource, e.definition.columnIdForSearchDuplicates);
            if (hasDuplicates) {
                LRH.ShowToast("Validation failed. Please remove duplicate items from the Import view.", "error", 5000);
                return;
            }
        }

        let that = this;
        $("#gridViewToobar_" + e.gridViewId).show();
        $("#GridView_TopBar_Submit_" + e.gridViewId).addClass("leopard-ui-disabled");
        $("#GridView_TopBar_Import_" + e.gridViewId).addClass("leopard-ui-disabled");

        let urlForDataImport = "";
        if (!LDH.IsObjectNull(e.definition.urlForDataImportField) &&
            !LDH.IsValueEmpty(e.definition.urlForDataImportField)) {
            urlForDataImport = e.definition.urlForDataImportField;
        }

        let processedGridData = datasource.map(function (e) {
            return e;
        });

        if (!LDH.IsObjectNull(e.definition.referenceDataSubmitLogic) &&
            !LDH.IsValueEmpty(e.definition.referenceDataSubmitLogic)) {
            let javascript = e.definition.referenceDataSubmitLogic;
            let dataName = "dataCollection";
            let dataValue = {
                parentData: that.selectedParentViewData,
                gridData: processedGridData,
                url: urlForDataImport
            };
            processedGridData = LDH.EvaluateJavaScriptForDataShaping(javascript,
                dataName, dataValue, e.gridViewId);
        }

        let docDataList = [];
        for (let i = 0; i < processedGridData.length; i++) {
            let gridItem = processedGridData[i];
            let processedItem = LDH.FilterMacroForJsonValues(gridItem);
            docDataList.push(processedItem);
        }

        for (let i = 0; i < datasource.length; i++) {
            if (typeof datasource[i]["Processed"] === "undefined") {
                datasource[i]["Processed"] = "";
            }
            datasource[i]["OrderIndex"] = i + 1;
        }

        let uiUpdateFrequency = 5;
        if (!LDH.IsObjectNull(e.definition.uiUpdateFrequencyForSubmit) &&
            !LDH.IsValueEmpty(e.definition.uiUpdateFrequencyForSubmit)) {
            uiUpdateFrequency = e.definition.uiUpdateFrequencyForSubmit;
        }

        if (docDataList.length > 0) {
            that.gridIndexForImport = 0;
            that.submitDataLogicPerBatch(docDataList, datasource, e.gridViewId, uiUpdateFrequency, 0);
        }
    }

    submitDataLogicPerBatch = (docDataList, gridData, gridViewId, uiUpdateFrequency, docDataListIndex) => {
        let that = this;
        that.submitDataLogic(docDataList[docDataListIndex], gridData, 0, that.gridIndexForImport, gridViewId, 0, function () {
            if (docDataListIndex >= docDataList.length - 1) {
                if (LDH.IsObjectNull(that.props.useStateStore) || !that.props.useStateStore) {
                    that.props.UpdateCustomStore(gridData, gridViewId);
                } else {
                    that.setState({customStore: gridData});
                }
                $("#gridViewToobar_" + gridViewId).hide();
                $("#GridView_TopBar_Submit_" + gridViewId).removeClass("leopard-ui-disabled");
                $("#GridView_TopBar_Import_" + gridViewId).removeClass("leopard-ui-disabled");
                LRH.ShowToast("You have successfully submitted " + gridData.length + " transaction(s).", "success", 5000);
                return;
            }
            docDataListIndex++;
            that.submitDataLogicPerBatch(docDataList, gridData, gridViewId, uiUpdateFrequency, docDataListIndex);
        }, uiUpdateFrequency, docDataListIndex);
    }

    submitDataLogic = (docDataList, gridData, docDataIndex, gridIndex, gridViewId, updateIndex, finalCallback, uiUpdateFrequency) => {
        if (docDataIndex >= docDataList.length) {
            return;
        }
        let that = this;
        let urlPath = docDataList[docDataIndex]["__ApiUrl"];
        let jsonData = docDataList[docDataIndex];

        $("#gridViewToobar_" + gridViewId).show();
        $("#GridView_TopBar_Submit_" + gridViewId).addClass("leopard-ui-disabled");
        $("#GridView_TopBar_Import_" + gridViewId).addClass("leopard-ui-disabled");

        LeopardAjaxHelper.GridViewCRUD_InsertDataWithoutUserData(urlPath, jsonData, function () {
            that.submitDataCompletedLogic(docDataList, gridData, docDataIndex, gridIndex, gridViewId, updateIndex, finalCallback, that, "DONE", uiUpdateFrequency);
        }, function () {
            that.submitDataCompletedLogic(docDataList, gridData, docDataIndex, gridIndex, gridViewId, updateIndex, finalCallback, that, "FAIL", uiUpdateFrequency);
        });
    }

    submitDataCompletedLogic = (docDataList, gridData, docDataIndex, gridIndex, gridViewId, updateIndex, finalCallback, thisComp, resultText, uiUpdateFrequency) => {
        if (docDataIndex >= docDataList.length - 1) {
            finalCallback();
            return;
        }
        if (!LDH.IsObjectNull(docDataList[docDataIndex]["__DataInGrid"]) &&
            docDataList[docDataIndex]["__DataInGrid"] === true) {
            gridData[gridIndex]["Processed"] = resultText;
            gridIndex++;
            updateIndex++;
            this.gridIndexForImport++;
        }

        if (updateIndex >= uiUpdateFrequency) {
            updateIndex = 0;
            if (LDH.IsObjectNull(thisComp.props.useStateStore) || !thisComp.props.useStateStore) {
                thisComp.props.UpdateCustomStore(gridData, gridViewId);

                setTimeout(function () {
                    docDataIndex++;
                    thisComp.submitDataLogic(docDataList, gridData, docDataIndex, gridIndex, gridViewId, updateIndex, finalCallback, uiUpdateFrequency);
                }, 100);
            } else {
                thisComp.setState({customStore: gridData}, function () {
                    docDataIndex++;
                    thisComp.submitDataLogic(docDataList, gridData, docDataIndex, gridIndex, gridViewId, updateIndex, finalCallback, uiUpdateFrequency);
                });
            }
        } else {
            docDataIndex++;
            thisComp.submitDataLogic(docDataList, gridData, docDataIndex, gridIndex, gridViewId, updateIndex, finalCallback, uiUpdateFrequency);
        }
    }

    refreshButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Refresh_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        let that = this;
        let instance = this.uiObjectInstance.gridViewInstance;
        let gridViewState = this.props.state.gridViewState;
        let currentState = this.getCurrentState(gridViewState, false);

        if (this.props.isGridViewJSONEngine === false) {
            let store = currentState.customStore.store();
            if (LDH.IsObjectNull(this.originalDataSourceUrl) || LDH.IsValueEmpty(this.originalDataSourceUrl)) {
                this.originalDataSourceUrl = store._requestDispatcher.url;
            }
            let url = this.originalDataSourceUrl;
            let gridDefinition = data.definition;
            url = LDH.GetFilteredUrlByDataViewParameters(gridDefinition.parameters, url, data.gridViewId);

            store._requestDispatcher._url = url;
            instance.option("dataSource", currentState.customStore);

            if (!LDH.IsObjectNull(data.refreshChildViews) && data.refreshChildViews) {
                let rowData = this.selectedGridViewRowData;
                this.onSelectionChanged(rowData);
            }
        } else {
            let gridDefinition = null;
            if (this.props.useStateStore === true) {
                gridDefinition = this.gridDefinition;
            } else {
                gridDefinition = currentState.gridDefinition;
            }

            let postTemplate = LDH.DeepClone(LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate());
            postTemplate.source = LDH.GenerateGuid();
            postTemplate.type = gridDefinition.dataSourceRequestType;

            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let postData = [{"owner": userId}];

            if (postTemplate.type === "leopardsystems.document.list") {
                postData = "not-required";
            }

            LRH.EnableOrDisableGridViewToolbarButtons(data.gridViewId, false, instance);

            LeopardAjaxHelper.SendRequestByEventSync(function (response) {
                let responseData = response.body.data;

                let isDataView = false;
                if (LDH.IsObjectNull(that.props.useStateStore) ||
                    that.props.useStateStore === false) {
                    isDataView = true;
                }
                let isJsonEngine = that.props.isGridViewJSONEngine;
                that.initializeCustomStore(isDataView, isJsonEngine, responseData, true);

                if (!LDH.IsObjectNull(data.refreshChildViews) && data.refreshChildViews) {
                    let rowData = this.selectedGridViewRowData;
                    this.onSelectionChanged(rowData);
                }
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve document content.", "error", 5000);
                }
            }, postTemplate, postData);
        }
    };

    applyFilterButtonOnClick = (data) => {
        if ($("#GridView_TopBar_ApplyFilter_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        $("#" + data.gridViewId + " .dx-datagrid-toolbar-button.dx-apply-button").trigger("click");
    };

    clearFilterButtonOnClick = (data) => {
        if ($("#GridView_TopBar_ClearFilter_" + data.e.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        this.resetPagingAndRefresh(false, data.e.gridViewId);
        this.uiObjectInstance.gridViewInstance.clearFilter();

        if (!LDH.IsObjectNull(data.gridDefinition.removeColumnSortOnFilterCleared) &&
            data.gridDefinition.removeColumnSortOnFilterCleared === true) {
            this.uiObjectInstance.gridViewInstance.clearSorting();
        }
    };

    buttonPrevOnClick = (data) => {
        let $root = $("#GridViewPager_" + data.gridViewId);
        if ($(".leopard-page-button-container.previous", $root)
                .hasClass("disabled") ||
            $root.hasClass("leopard-ui-disabled")) {
            return;
        }
        let instance = this.uiObjectInstance.gridViewInstance;
        let pageIndex = instance.option("customPagingIndex");
        if (!LDH.IsValueEmpty(pageIndex)) {
            pageIndex = parseInt(pageIndex) - 1;
            if (pageIndex < 0) pageIndex = 0;
        } else {
            pageIndex = 0;
        }
        instance.option("customPagingIndex", pageIndex);
        instance.option("customPagingRefresh", true);
        instance.option("customPagingOperation", "prev");

        let gridViewState = this.props.state.gridViewState;
        let currentState = this.getCurrentState(gridViewState, false);
        instance.option("dataSource", currentState.customStore);
    };

    buttonNextOnClick = (data) => {
        let $root = $("#GridViewPager_" + data.gridViewId);
        if ($(".leopard-page-button-container.next", $root)
                .hasClass("disabled") ||
            $root.hasClass("leopard-ui-disabled")) {
            return;
        }
        let instance = this.uiObjectInstance.gridViewInstance;
        let pageIndex = instance.option("customPagingIndex");
        if (!LDH.IsValueEmpty(pageIndex)) {
            pageIndex = parseInt(pageIndex) + 1;
            if (pageIndex < 0) pageIndex = 0;
        } else {
            pageIndex = 0;
        }
        instance.option("customPagingIndex", pageIndex);
        instance.option("customPagingOperation", "next");

        let gridViewState = this.props.state.gridViewState;
        let currentState = this.getCurrentState(gridViewState, false);
        instance.option("dataSource", currentState.customStore);
    };

    disableOrEnablePagerBasedOnRowCount = (rowCount, gridViewId) => {
        let $root = $("#GridViewPager_" + gridViewId);
        if ($root.length === 0) return;
        let currentPageInt = 1;
        let instance = this.uiObjectInstance.gridViewInstance;
        if (!LDH.IsObjectNull(instance) &&
            !LDH.IsValueEmpty(instance.option("customPagingIndex"))) {
            currentPageInt = parseInt(instance.option("customPagingIndex")) + 1;
        }

        let firstPageDisabled = false;
        if (currentPageInt <= 1) firstPageDisabled = true;

        if (rowCount === 0 && currentPageInt <= 1) {
            $(".leopard-page-button-container.previous", $root).addClass("disabled");
            $(".leopard-page-button-container.next", $root).addClass("disabled");
        } else if (rowCount === 0 && currentPageInt > 1) {
            $(".leopard-page-button-container.previous", $root).removeClass("disabled");
            $(".leopard-page-button-container.next", $root).addClass("disabled");
        } else {
            let pageSize = parseInt($(".leopard-page-number.selected", $root).attr("pagesize"));
            if (pageSize <= rowCount) {
                $(".leopard-page-button-container.next", $root).removeClass("disabled");
            } else {
                $(".leopard-page-button-container.next", $root).addClass("disabled");
            }
            if (firstPageDisabled) {
                $(".leopard-page-button-container.previous", $root).addClass("disabled");
            } else {
                $(".leopard-page-button-container.previous", $root).removeClass("disabled");
            }
        }
        $(".leopard-pagenumber-current", $root).text(currentPageInt);
    };

    onEditorPreparing = (data) => {
        let e = data.e;

        if (e.parentType === "filterRow" && e.dataType === "datetime") {
            let columnDefinitionList = data.gridDefinition.columnDefinition;
            let continueProceed = false;
            for (let i = 0; i < columnDefinitionList.length; i++) {
                if (columnDefinitionList[i].columnName === e.dataField &&
                    (LDH.IsObjectNull(columnDefinitionList[i].columnType) ||
                        columnDefinitionList[i].columnType === "datetime")) {
                    continueProceed = true;
                }
            }
            if (continueProceed === false) return;

            let standardHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (n) {
                if (LDH.IsValueEmpty(n.value) === true ||
                    (!LDH.IsObjectNull(n.value.convertedToUtc) &&
                        n.value.convertedToUtc === true)) {
                    standardHandler(n);
                    return;
                }
                window.Global_TempDateConvertion.push({
                    dataViewId: data.gridViewId,
                    convertFrom: LDH.GetLocalISODateString(n.value).replace(".000Z", "Z"),
                    convertTo: n.value.toISOString().replace(".000Z", "Z")
                });
                standardHandler(n);
            }
        }

        if (e.parentType === "filterRow" && (e.dataType === "string" || e.dataType === "guid")) {
            let columnDefinitionList = data.gridDefinition.columnDefinition;
            let continueProceed = false;
            let columnDef = null;

            e.editorOptions.valueChangeEvent = "keyup";
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (event, args) {
                let isPreventDefault = false;
                for (let v = 0; v < columnDefinitionList.length; v++) {
                    if (!LDH.IsObjectNull(columnDefinitionList[v].minSearchCharLength) &&
                        columnDefinitionList[v].minSearchCharLength > 1 &&
                        columnDefinitionList[v].columnName === e.dataField &&
                        event.value !== null && event.value !== "" &&
                        event.value.length < columnDefinitionList[v].minSearchCharLength) {
                        isPreventDefault = true;
                        break;
                    }
                }
                if (isPreventDefault) {
                    event.event.preventDefault();
                } else {
                    defaultValueChangeHandler(event);
                }
            }

            for (let v = 0; v < columnDefinitionList.length; v++) {
                if (!LDH.IsObjectNull(columnDefinitionList[v].allowAutoComplete) &&
                    columnDefinitionList[v].allowAutoComplete === true &&
                    columnDefinitionList[v].columnName === e.dataField &&
                    (LDH.IsObjectNull(columnDefinitionList[v].columnType) ||
                        columnDefinitionList[v].columnType === "string" ||
                        columnDefinitionList[v].columnType === "guid")) {
                    continueProceed = true;
                    columnDef = columnDefinitionList[v];
                }
            }
            if (continueProceed === false) return;

            let autoCompleteOperation = "startswith";
            if (!LDH.IsObjectNull(columnDef.autoCompleteOperation) &&
                columnDef.autoCompleteOperation.length > 0) {
                autoCompleteOperation = columnDef.autoCompleteOperation;
            }
            let url = this.props.GetDataFromUrl.replace("?tableName=", "");
            let domainUrl = LDH.APIEndpointAdapter();

            let hasAutoCompleteDataSource = false;
            if (!LDH.IsObjectNull(columnDef.autoCompleteDataSource) &&
                !LDH.IsValueEmpty(columnDef.autoCompleteDataSource)) {
                url = columnDef.autoCompleteDataSource;
                hasAutoCompleteDataSource = true;
            }

            let autoCompleteDataSourceKeyName = "Unknown";
            if (!LDH.IsObjectNull(columnDef.autoCompleteDataSourceKeyName) &&
                !LDH.IsValueEmpty(columnDef.autoCompleteDataSourceKeyName)) {
                autoCompleteDataSourceKeyName = columnDef.autoCompleteDataSourceKeyName;
            }

            let displayExpr = e.dataField;
            let valueExpr = e.dataField;
            let dataSource = LRH.InitCustomStoreForAutoComplete(
                domainUrl, url, autoCompleteOperation, columnDef.columnName,
                data.gridDefinition, null, null, hasAutoCompleteDataSource,
                autoCompleteDataSourceKeyName, null, "");

            if (!LDH.IsObjectNull(columnDef.autoCompleteDataSource) &&
                !LDH.IsValueEmpty(columnDef.autoCompleteDataSource)) {
                if (columnDef.autoCompleteDataSource.indexOf("{") > -1 &&
                    columnDef.autoCompleteDataSource.indexOf("}") > -1) {
                    let dataSourceName = columnDef.autoCompleteDataSource.toString().replace("{", "");
                    dataSourceName = dataSourceName.replace("}", "");
                    dataSource = window[dataSourceName];
                    displayExpr = "text";
                    valueExpr = "value";
                }
            }

            e.editorName = "dxSelectBox";
            e.editorOptions.displayExpr = displayExpr;
            e.editorOptions.valueExpr = valueExpr;
            e.editorOptions.searchEnabled = true;
            e.editorOptions.searchMode = "contains";
            e.editorOptions.searchTimeout = 1000;
            e.editorOptions.minSearchLength = 0;
            e.editorOptions.showDropDownButton = false;
            e.editorOptions.showDataBeforeSearch = false;
            e.editorOptions.noDataText = "Enter value to search...";
            e.editorOptions.dataSource = dataSource;
        }
    };

    autoRefreshCountdownOnEnd = () => {
        let dataViewId = this.props.GridViewId;
        $("#GridView_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");

        this.refreshButtonOnClick({
            gridViewId: this.props.GridViewId,
            definition: this.props.gridDefinition,
            refreshChildViews: false
        });
    };

    onFocusedCellChanging = (e) => {
        e.isHighlighted = false;
    };

    genericPopoverOnShown = (data) => {
        let popover = this.uiObjectInstance[data.gridViewId + "_popover_actionGroup_" + data.id];
        if (!LDH.IsObjectNull(data.actionGroup.associatedActionItems) &&
            data.actionGroup.associatedActionItems.length > 0) {
            for (let i = 0; i < data.actionGroup.associatedActionItems.length; i++) {
                for (let j = 0; j < data.commandLinks.length; j++) {
                    if (data.actionGroup.associatedActionItems[i] === data.commandLinks[j].id) {
                        let actionItem = data.commandLinks[j];
                        if (!LDH.IsObjectNull(actionItem.customAccessibilityLogic) &&
                            !LDH.IsValueEmpty(actionItem.customAccessibilityLogic)) {
                            try {
                                let evalResult = this.evaluateCustomColumnAccessibilityLogic(
                                    actionItem, popover.option("rowData").e.row.data);
                                if (LDH.IsObjectNull(evalResult) || LDH.IsValueEmpty(evalResult)) {
                                    continue;
                                }
                                let $actionItem = $("#" + data.gridViewId + "_popover_actionGroup_" +
                                    data.actionGroup.id + "_actionItem_" + actionItem.id);

                                if (evalResult === "hidden" && $actionItem.length > 0) {
                                    $actionItem.hide();
                                } else if (evalResult === "disabled" && $actionItem.length > 0) {
                                    $actionItem.addClass("disabled");
                                } else if ($actionItem.length > 0) {
                                    $actionItem.show();
                                    $actionItem.removeClass("disabled");
                                }
                            } catch (ex) {

                            }
                        }
                    }
                }
            }
        }
    };

    commandColumnLinkOnClick = (data) => {
        let that = this;
        if ($("#" + data.id).length > 0 && $("#" + data.id).hasClass("disabled")) {
            return;
        }
        if (data.data.columnType === "photo-gallery" || data.data.columnType === "pdf-viewer") {
            window.Global_PopupCustomColumnData = data;
            let instance = that.uiObjectInstance.popupPhotoGalleryInstance;
            instance.option("visible", true);
        } else if (data.data.columnType === "modify-row" || data.data.columnType === "reset-password") {
            data.getDataFromUrl = that.props.GetDataFromUrl;
            data.getDataFromUrl = data.getDataFromUrl.replace("?tableName=", "");
            window.Global_PopupCustomColumnData = data;

            if (!LDH.IsObjectNull(data.e.row.data) && !LDH.IsObjectNull(data.data.eventSyncRequest) &&
                !LDH.IsValueEmpty(data.data.eventSyncRequest)) {
                let rowDataKeys = Object.keys(data.e.row.data);
                data.data.eventSyncRequestClone = data.data.eventSyncRequest;

                for (let v = 0; v < rowDataKeys.length; v++) {
                    let dataToSearch = "{" + rowDataKeys[v] + "}"
                    if (data.data.eventSyncRequestClone.indexOf(dataToSearch) > -1) {
                        let cellValue = data.e.row.data[rowDataKeys[v]];
                        let newPath = LDH.ReplaceAll(data.data.eventSyncRequestClone, dataToSearch, cellValue);
                        data.data.eventSyncRequestClone = newPath;
                    }
                }
                let dataName = "data";
                data.data.eventSyncRequestClone = LDH.FilterMacro(data.data.eventSyncRequestClone);
                data.data.eventSyncRequestClone = LDH.EvaluateJavaScriptForDataShaping(data.data.eventSyncRequestClone, dataName,
                    data.e.row.data, null);
            }

            let instance = that.uiObjectInstance.popupEditFormInstance;
            if (instance === null) return;

            window.Global_PopupTempObjectInstance = instance;
            instance.option("visible", true);
        } else if (data.data.columnType === "delete-row") {
            let keyObj = data.e.row.data[data.data.dataSourceId];
            let keyId = LDH.IsObjectNull(keyObj) || LDH.IsObjectNull(keyObj._value) ? "" : keyObj._value;

            LRH.ShowDialog("Are you sure you want to delete this row?", "Confirm Delete", function () {
                $(".leopard-application-loading-cover").show();
                LeopardAjaxHelper.GridViewCRUD_DeleteData(data.data.apiGatewayPath, keyId, function () {
                    $(".leopard-application-loading-cover").hide();
                    that.refreshGridViewOnPopupClosed();
                    LRH.ShowToast("This record has been successfully deleted.", "success", 5000);
                }, function (ex) {
                    $(".leopard-application-loading-cover").hide();
                    LRH.ShowToast("Failed to delete the record from the table. " +
                        JSON.stringify(ex.message), "error", 5000);
                });
            });
        } else if (data.data.columnType === "disable-row") {
            LRH.ShowDialog("Are you sure you want to disable this row?",
                "Confirm Disable", function () {

                });
        } else if (data.data.columnType === "action-group") {
            that.uiObjectInstance[data.gridViewId + "_popover_actionGroup_" + data.data.id].option("linkId", data.id);
            that.uiObjectInstance[data.gridViewId + "_popover_actionGroup_" + data.data.id].option("rowData", data);
            that.uiObjectInstance[data.gridViewId + "_popover_actionGroup_" + data.data.id].option("target", data.e.event.target);
            that.uiObjectInstance[data.gridViewId + "_popover_actionGroup_" + data.data.id].option("visible", true);
        }
    };

    addPhotoGalleryPopupInstance = (e) => {
        if (LDH.IsObjectNull(e)) return;
        this.uiObjectInstance.popupPhotoGalleryInstance = e.instance;
    };

    popupButtonSaveOnClick = (e) => {
        this.uiObjectInstance.popupPhotoGalleryInstance.hide();
    };

    addEditFormPopupInstance = (e) => {
        if (LDH.IsObjectNull(e)) return;
        this.uiObjectInstance.popupEditFormInstance = e.instance;
    };

    addDataImportPopupInstance = (e) => {
        if (LDH.IsObjectNull(e)) return;
        this.uiObjectInstance.popupDataImportInstance = e.instance;
    };

    addReportSchedulerPopupInstance = (e) => {
        if (LDH.IsObjectNull(e)) return;
        this.uiObjectInstance.popupReportSchedulerInstance = e.instance;
    };

    addDisposablePopupInstances = (e) => {
        for (let i = 0; i < e.length; i++) {
            this.disposablePopupInstances.push(e[i]);
        }
    };

    refreshGridViewOnPopupClosed = () => {
        let that = this;
        let instance = this.uiObjectInstance.gridViewInstance;
        if (!LDH.IsObjectNull(instance)) {
            setTimeout(function () {
                let gridViewState = that.props.state.gridViewState;
                let currentState = that.getCurrentState(gridViewState, false);
                instance.option("dataSource", currentState.customStore);
            }, 3000);
        }
    };

    onCellPrepared = (e) => {
        if (!LDH.IsObjectNull(this.props.gridDefinition.gridViewEngine) &&
            this.props.gridDefinition.gridViewEngine === "csv-adhoc-engine") {
            if (e.column.dataField === "Processed") {
                e.column.width = "110px";
                e.column.encodeHtml = false;

                if (e.rowType === "data" && e.value.toLowerCase() === "done") {
                    e.cellElement.innerHTML =
                        "<div class='leopard-text-color green'>" +
                        "     <b>" + e.value + "</b>" +
                        "</div>";
                } else if (e.rowType === "data" && e.value.toLowerCase() === "fail") {
                    e.cellElement.innerHTML =
                        "<div class='leopard-text-color red leopard-error-cell'>" +
                        "     <b>" + e.value + "</b>" +
                        "</div>";
                } else if (e.rowType === "data" && e.value.toLowerCase() === "warning") {
                    e.cellElement.innerHTML =
                        "<div class='leopard-text-color orange leopard-error-cell'>" +
                        "     <b>" + e.value + "</b>" +
                        "</div>";
                }
            }
        }

        if (e.rowType === "data" && e.column.command === "edit") {
            let $links = $(".dx-link", $(e.cellElement));
            let rowIndex = e.row.rowIndex.toString();

            for (let i = 0; i < e.column.buttons.length; i++) {
                let button = e.column.buttons[i];
                let buttonDisabled = false;
                let buttonHidden = false;

                if ((!LDH.IsObjectNull(button.columnType) && button.columnType === "action-group") ||
                    (!LDH.IsObjectNull(button.columnType) && button.columnType === "action-item" &&
                        !LDH.IsObjectNull(button.customColumn.isDisplaySideBySide) &&
                        button.customColumn.isDisplaySideBySide)) {
                    if ($links.length > 0 && !LDH.IsObjectNull(button.columnType) &&
                        button.columnType === "action-group") {
                        for (let j = 0; j < $links.length; j++) {
                            if ($links[j].text.trim() === button.text) {
                                $links[j].id = button.id + "_" + rowIndex;
                                $links[j].innerHTML = $links[j].text +
                                    "<i class='fas fa-caret-down' style='margin-left: 2px'></i>";
                            }
                        }
                    }

                    if (!LDH.IsObjectNull(button.customColumn.customAccessibilityLogic) &&
                        !LDH.IsValueEmpty(button.customColumn.customAccessibilityLogic)) {
                        try {
                            let evalResult = this.evaluateCustomColumnAccessibilityLogic(
                                button.customColumn, e.row.data);
                            if (LDH.IsObjectNull(evalResult) || LDH.IsValueEmpty(evalResult)) {
                                break;
                            }
                            if (evalResult === "hidden") buttonHidden = true;
                            if (evalResult === "disabled") buttonDisabled = true;
                        } catch (ex) {
                            buttonDisabled = false;
                            buttonHidden = false;
                        }
                    }
                }

                if (!LPH.SetCommandLinkAccessibility(button, $links, rowIndex, buttonDisabled, buttonHidden)) {
                    let isPhotoGallery = !LDH.IsObjectNull(button.columnType) &&
                        !LDH.IsObjectNull(button.countColumn) &&
                        !LDH.IsValueEmpty(button.countColumn) &&
                        button.columnType === "photo-gallery";

                    if (isPhotoGallery) {
                        let value = e.row.data[button.countColumn];
                        let hide = true;
                        if (LDH.IsValueEmpty(value) === false &&
                            (value.toString().toLowerCase() === "true" ||
                                value.toString().toLowerCase() !== "0")) {
                            hide = false;
                        }
                        if ($links.length > 0) {
                            for (let j = 0; j < $links.length; j++) {
                                if ($links[j].text.trim() === button.text) {
                                    $links[j].id = button.id + "_" + rowIndex;

                                    if (hide === true) {
                                        let className = $links[j].className;
                                        $links[j].style.opacity = "0.3";
                                        $links[j].className = className + " disabled";
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else if (e.rowType === "data" && !LDH.IsObjectNull(e.data) &&
            !LDH.IsObjectNull(e.data[e.column.dataField + "___Untruncated"]) &&
            !LDH.IsValueEmpty(e.data[e.column.dataField + "___Untruncated"]) &&
            !LDH.IsObjectNull(e.data[e.column.dataField]) &&
            e.data[e.column.dataField].toString().indexOf("…") > -1) {
            $(e.cellElement).attr("title", e.data[e.column.dataField + "___Untruncated"]);
        }

        if (!LDH.IsObjectNull(this.props.gridDefinition.enableTextHighlight) &&
            !LDH.IsObjectNull(this.props.gridDefinition.textHighlightColumns) &&
            !LDH.IsValueEmpty(this.props.gridDefinition.textHighlightColumns) &&
            !LDH.IsObjectNull(this.props.gridDefinition.textHighlightWords) &&
            !LDH.IsValueEmpty(this.props.gridDefinition.textHighlightWords) &&
            this.props.gridDefinition.enableTextHighlight) {
            if (e.rowType === "data" && !LDH.IsObjectNull(e.data)) {
                let highlightColumns = this.props.gridDefinition.textHighlightColumns.split(",");
                for (let v = 0; v < this.highlightWordsFromParent.length; v++) {
                    if (e.column.dataField === highlightColumns[v]) {
                        let id = LDH.GenerateGuid();
                        let elemId = $(e.cellElement).attr("id");
                        if (LDH.IsValueEmpty(elemId)) elemId = "";

                        if (elemId.indexOf("leopard-highlight") > -1) {
                            continue;
                        }

                        let idText = "leopard-highlight-" + id;
                        $(e.cellElement).attr("id", idText);

                        this.highlightPendingList.push({
                            id: idText,
                            words: this.highlightWordsFromParent
                        });
                    }
                }
            }
        }
    };

    customizeColumns = (data) => {
        if (data.gridDefinition.gridViewEngine === "csv-adhoc-engine") {
            if (this.hasCustomizeColumnsInitialized) {
                return;
            }
            let tableInitialized = true;
            if (data.e.length === 1 && data.e[0].type === "buttons") {
                tableInitialized = false;
            }

            if (tableInitialized) {
                for (let i = 0; i < data.e.length; i++) {
                    let dataField = data.e[i].dataField;
                    if (LDH.IsObjectNull(dataField)) {
                        continue;
                    }
                    if (dataField.indexOf("_SystemField") > -1) {
                        data.e[i].visible = false;
                    }
                }
                this.hasCustomizeColumnsInitialized = true;
            }
            return;
        }

        if (LDH.IsObjectNull(data.gridDefinition) ||
            LDH.IsObjectNull(data.gridDefinition.columnDefinition) ||
            this.hasCustomizeColumnsInitialized) {
            return;
        }

        for (let i = 0; i < data.e.length; i++) {
            let columnDef = null;
            let dataField = data.e[i].dataField;
            columnDef = data.gridDefinition.columnDefinition.filter(c => {
                return c.columnName === dataField;
            });
            if (!LDH.IsObjectNull(columnDef) && columnDef.length > 0) {
                columnDef = columnDef[0];
            }

            let columnType = "string";
            if (!LDH.IsObjectNull(columnDef) && columnDef.columnType !== undefined &&
                !LDH.IsObjectNull(columnDef.columnType) && columnDef.columnType !== "") {
                columnType = columnDef.columnType;
            }

            // Visible settings.
            if (LDH.IsObjectNull(this.gridViewCustomLayout)) {
                let isVisible = true;
                if (!LDH.IsObjectNull(columnDef) && columnDef.isVisible !== undefined &&
                    !LDH.IsObjectNull(columnDef.isVisible) && columnDef.isVisible !== "") {
                    isVisible = columnDef.isVisible;
                }
                data.e[i].visible = isVisible;
            }

            // Visible Index settings.
            if (LDH.IsObjectNull(this.gridViewCustomLayout)) {
                let visibleIndex = data.e[i].index;
                if (!LDH.IsObjectNull(columnDef) && columnDef.visibleIndex !== undefined &&
                    !LDH.IsObjectNull(columnDef.visibleIndex) && columnDef.visibleIndex !== "") {
                    visibleIndex = columnDef.visibleIndex;
                }
                data.e[i].visibleIndex = visibleIndex;
            }

            // Default Operation settings.
            if ((LDH.IsObjectNull(this.gridViewCustomLayout) &&
                    LDH.IsValueEmpty(data.e[i].selectedFilterOperation)) ||
                (LDH.IsObjectNull(this.gridViewCustomLayout) === false &&
                    LDH.IsValueEmpty(data.e[i].selectedFilterOperation) === false)) {
                let defaultOperation = "";
                if (LDH.IsValueEmpty(columnType) || columnType === "string") {
                    defaultOperation = "=";
                } else if (columnType === "guid") {
                    defaultOperation = "=";
                } else if (columnType === "number") {
                    defaultOperation = "=";
                } else if (columnType === "date") {
                    defaultOperation = "between";
                } else if (columnType === "datetime") {
                    defaultOperation = "between";
                }
                if (!LDH.IsObjectNull(columnDef) && !LDH.IsObjectNull(columnDef.defaultOperation) &&
                    !LDH.IsObjectNull(columnDef.defaultOperation) && columnDef.defaultOperation !== "") {
                    defaultOperation = columnDef.defaultOperation;
                }
                data.e[i].selectedFilterOperation = defaultOperation;
            }

            if (LDH.IsObjectNull(this.gridViewCustomLayout)) {
                for (let k = 0; k < data.e.length; k++) {
                    if (!LDH.IsObjectNull(data.e[k].custombanddata) &&
                        LDH.IsObjectNull(data.e[i].custombanddata) &&
                        !LDH.IsObjectNull(data.e[i].bandid) &&
                        !LDH.IsValueEmpty(data.e[i].bandid) &&
                        data.e[i].bandid === data.e[k].custombanddata.id) {
                        data.e[i].ownerBand = data.e[k].visibleIndex;
                        break;
                    }
                }
            }
        }
        this.hasCustomizeColumnsInitialized = true;
    };

    onSelectionChanged = (e) => {
        this.selectedGridViewRowData = e;

        if (LDH.IsObjectNull(e) || LDH.IsObjectNull(this.props.useStateStore) ||
            this.props.useStateStore === false ||
            LDH.IsObjectNull(this.relationshipsLinkedToDataView) ||
            this.relationshipsLinkedToDataView.length === 0) {
            return;
        }

        for (let i = 0; i < this.relationshipsLinkedToDataView.length; i++) {
            let pId = this.relationshipsLinkedToDataView[i].parentDataViewId;
            let cId = this.relationshipsLinkedToDataView[i].childDataViewId;

            let features = this.relationshipsLinkedToDataView[i].interactiveFeatures;
            let dataSourceId = this.relationshipsLinkedToDataView[i].dataSourceId;
            let rowData = LDH.DeepClone(e.selectedRowsData[0]);
            let listeners = LeopardStaticUIConfig.Global_DashboardDataViewListeners;
            let childDashboardItemId = cId.split(":")[0];
            if (childDashboardItemId === this.props.GridViewId) continue;

            if (!LDH.IsObjectNull(this.props.gridDefinition.parameters) &&
                !LDH.IsValueEmpty(this.props.gridDefinition.parameters)) {
                let parametersCloned = LDH.DeepClone(this.props.gridDefinition.parameters);

                for (let x = 0; x < parametersCloned.length; x++) {
                    let item = parametersCloned[x];
                    if (!LDH.IsObjectNull(window["dataViewParam_" + this.props.GridViewId + "_control_" + item.parameterName]) &&
                        !LDH.IsObjectNull(rowData)) {
                        let controlId = window["dataViewParam_" + this.props.GridViewId + "_control_" + item.parameterName];
                        rowData[item.parameterName] = controlId;
                    }
                }
            }

            for (let v = 0; v < listeners.length; v++) {
                if (listeners[v].dashboardItemId === childDashboardItemId) {
                    if (LDH.IsObjectNull(listeners[v]) || LDH.IsObjectNull(listeners[v].instance)) {
                        continue;
                    }
                    if (listeners[v].instance !== "blank" &&
                        (listeners[v].instance.NAME === "dxDataGrid" ||
                            listeners[v].instance.NAME === "dxChart")) {
                        listeners[v].instance.clearSelection();
                    }
                    window["Global_SelectedRowData_ParentPersistentId_" + pId.split(":")[1]] = rowData;

                    let evaluatedData = null;
                    if (!LDH.IsObjectNull(this.props.gridDefinition.eventHandlerSelectionOnChanged) &&
                        !LDH.IsValueEmpty(this.props.gridDefinition.eventHandlerSelectionOnChanged)) {
                        let javascript = this.props.gridDefinition.eventHandlerSelectionOnChanged;
                        let dataName = "data";
                        let dataValue = {
                            dataFromSource: LDH.DeepClone(e.selectedRowsData[0]),
                            dataFromParent: this.selectedParentViewData,
                            dataViewType: "datagrid",
                            dataViewDefinition: this.props.gridDefinition,
                            dataViewPersistentId: pId.split(":")[1]
                        };
                        evaluatedData = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                            dataValue, this.props.GridViewId, null);
                    }

                    listeners[v].callback({
                        dataFromSource: rowData,
                        dataFromParent: this.selectedParentViewData,
                        parentDataViewId: pId,
                        childDataViewId: cId,
                        features: features,
                        dataSourceId: dataSourceId,
                        dataViewType: "datagrid",
                        evaluatedData: evaluatedData,
                        parentGridDef: this.props.gridDefinition
                    });
                }
            }
        }
    };

    onRowClick = () => {
        LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault = false;
        if (!LDH.IsObjectNull(LeopardStaticUIConfig.Global_DashboardAutoSelectInstance)) {
            LeopardStaticUIConfig.Global_DashboardAutoSelectInstance.option("value", false);
        }
    };

    exportOptionPopupOnHiding = () => {
        this.uiObjectInstance.exportOptionPopupInstance.option("visible", false);
    };

    gridViewStatusCellRender = (e) => {
        let valueColor = e.data[e.column.dataField + "_ValueColor"];
        let bgColor = e.data[e.column.dataField + "_BgColor"];

        return (
            <div className={"leopard-customcell-status"} style={{
                color: valueColor, backgroundColor: bgColor
            }}>{e.data[e.column.dataField]}</div>
        );
    };

    gridViewColorTextCellRender = (e, columnType) => {
        let valueColor = e.data[e.column.dataField + "_ValueColor"];
        let bgColor = e.data[e.column.dataField + "_BgColor"];

        let value = e.data[e.column.dataField];
        if (value === null) value = "";

        if (columnType === "date" && value !== null &&
            value.toString() !== "") {
            value = moment(value).format("DD/MM/yyyy");
        } else if (columnType === "datetime" && value !== null &&
            value.toString() !== "") {
            value = moment(value).format("DD/MM/yyyy HH:mm");
        } else {
            value = value.toString();
        }

        return (
            <div className={"leopard-customcell-colortext"} style={{
                color: valueColor, backgroundColor: bgColor
            }}>{value}</div>
        );
    };

    evaluateCustomColumnAccessibilityLogic = (customColumn, rowData) => {
        try {
            let functionName = LDH.GenerateGuid();
            let script = "var tempFunc_" + functionName + "=function(){";
            script += customColumn.customAccessibilityLogic;
            script += "}; window.result_" + functionName + "=tempFunc_" + functionName + "();";
            window["rowData"] = rowData;
            eval(script);
            let evalResult = window["result_" + functionName];
            delete window["result_" + functionName];
            if (!LDH.IsObjectNull(evalResult) && !LDH.IsValueEmpty(evalResult)) {
                return evalResult;
            }
            return null;
        } catch (ex) {
            return null;
        }
    }

    gridViewLayoutCustomLoad = (data) => {
        if (!LDH.IsObjectNull(this.gridViewCustomLayout)) {
            return this.gridViewCustomLayout;
        }
        return data;
    };

    requireReloadDataView = (data) => {
        let instance = this.uiObjectInstance.gridViewInstance;
        this.hasCustomizeColumnsInitialized = false;
        this.gridViewCustomLayout = data.layoutToLoad;
        instance.state(data.layoutToLoad);

        if (!LDH.IsObjectNull(this.props.requireReloadDataView)) {
            this.props.requireReloadDataView(data);
        }
    };

    deleteLayoutOnClick = (data) => {
        let that = this;

        LRH.ShowDialog("Are you sure you want to delete this layout? <br/><b>" + data.layoutName + "</b>", "Confirm Delete", function () {
            let layoutId = data.layoutId;
            let dataViewPersistentId = data.dataViewPersistentId;

            for (let i = 0; i < data.layoutConfig.layouts.length; i++) {
                let layout = data.layoutConfig.layouts[i];
                if (layout.layoutId === layoutId) {
                    data.layoutConfig.layouts.splice(i, 1);
                    break;
                }
            }

            if (layoutId === data.layoutConfig.selectedLayoutId) {
                data.layoutConfig.selectedLayoutId = "";
            }

            let userId = LDH.GetUserIdFromUserProfile(window.userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(window.userProfile);
            LeopardAjaxHelper.UpdateDataViewLayoutById(userId, organizationId, dataViewPersistentId,
                data.layoutConfig, function () {
                    that.requireReloadDataView({
                        layoutToLoad: null,
                        isDashboardLayout: false, dashboardId: that.props.dashboardId
                    });
                    LRH.ShowToast("Your layout has been successfully deleted.", "success", 5000);
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to save your layout.", "error", 5000);
                    }
                });
        });
    };

    saveLayoutOnClick = (data) => {
        let layoutId = data.layoutId;
        let layoutConfig = data.layoutConfig;
        let dataViewPersistentId = data.dataViewPersistentId;

        let instance = this.uiObjectInstance.gridViewInstance;
        let gridViewStatus = instance.state();
        gridViewStatus.pageIndex = 0;

        for (let i = 0; i < layoutConfig.layouts.length; i++) {
            let layout = layoutConfig.layouts[i];
            if (layout.layoutId === layoutId) {
                layout.layoutData = gridViewStatus;
            }
        }

        let userId = LDH.GetUserIdFromUserProfile(window.userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(window.userProfile);
        LeopardAjaxHelper.UpdateDataViewLayoutById(userId, organizationId, dataViewPersistentId,
            layoutConfig, function () {
                LRH.ShowToast("Your layout has been successfully saved.", "success", 5000);
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to save your layout.", "error", 5000);
                }
            });
    };

    createLayoutOnClick = (data) => {
        let layoutConfig = data.layoutConfig;
        if (LDH.IsObjectNull(layoutConfig) || LDH.IsObjectNull(layoutConfig.selectedLayoutId)) {
            layoutConfig = {selectedLayoutId: "", layouts: []};
        }

        let dataViewPersistentId = data.dataViewPersistentId;
        let dataViewId = data.dataViewId;
        let instance = this.uiObjectInstance.gridViewInstance;
        let gridViewStatus = instance.state();
        gridViewStatus.pageIndex = 0;

        layoutConfig.layouts.push({
            dataViewPersistentId: dataViewPersistentId,
            dataViewId: dataViewId,
            layoutName: data.layoutName,
            layoutId: data.layoutId,
            layoutVersion: 1,
            layoutData: gridViewStatus,
            isDashboard: false
        });
        layoutConfig.selectedLayoutId = data.layoutId;

        let userId = LDH.GetUserIdFromUserProfile(window.userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(window.userProfile);
        LeopardAjaxHelper.UpdateDataViewLayoutById(userId, organizationId, dataViewPersistentId,
            layoutConfig, function () {
                LRH.ShowToast("Your layout has been successfully created.", "success", 5000);
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to create your layout.", "error", 5000);
                }
            });
    };

    renameLayoutOnClick = (data) => {
        let layoutId = data.layoutId;
        let layoutConfig = data.layoutConfig;
        let dataViewPersistentId = data.dataViewPersistentId;

        for (let i = 0; i < layoutConfig.layouts.length; i++) {
            let layout = layoutConfig.layouts[i];
            if (layout.layoutId === layoutId) {
                layout.layoutName = data.layoutName;
            }
        }

        let userId = LDH.GetUserIdFromUserProfile(window.userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(window.userProfile);
        LeopardAjaxHelper.UpdateDataViewLayoutById(userId, organizationId, dataViewPersistentId,
            layoutConfig, function () {
                LRH.ShowToast("Your layout has been successfully renamed.", "success", 5000);
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to rename your layout.", "error", 5000);
                }
            });
    };

    onContextMenuPreparing = (data) => {
        let thisComp = data.thisComp;
        if (LDH.IsObjectNull(data.e) || LDH.IsObjectNull(data.e.row) || LDH.IsObjectNull(data.e.row.rowType) ||
            data.e.row.rowType !== "data") {
            return;
        }

        let menuItems = [];
        if (!LDH.IsObjectNull(thisComp.customColumnConfiguration) &&
            !LDH.IsObjectNull(thisComp.customColumnConfiguration.customColumns)) {

            for (let v = 0; v < thisComp.customColumnConfiguration.customColumns.length; v++) {
                let customColumn = thisComp.customColumnConfiguration.customColumns[v];

                let groupAdded = false;
                if (customColumn.columnType === "action-group" && !LDH.IsObjectNull(customColumn.associatedActionItems) &&
                    customColumn.associatedActionItems.length > 0) {
                    let actionGroupVisibility = true;
                    if (!LDH.IsObjectNull(customColumn.customAccessibilityLogic) &&
                        !LDH.IsValueEmpty(customColumn.customAccessibilityLogic)) {
                        actionGroupVisibility = thisComp.evaluateCustomColumnAccessibilityLogic(
                            customColumn, data.e.row.data);
                        if (actionGroupVisibility === "hidden") continue;
                    }

                    groupAdded = false;
                    for (let i = 0; i < customColumn.associatedActionItems.length; i++) {
                        let actionItemId = customColumn.associatedActionItems[i];
                        for (let j = 0; j < thisComp.customColumnConfiguration.customColumns.length; j++) {
                            let actionItem = thisComp.customColumnConfiguration.customColumns[j];
                            if (actionItem.columnType === "action-item" && actionItem.id === actionItemId) {
                                let id = LDH.GenerateGuid();

                                let buttonVisibility = null;
                                if (!LDH.IsObjectNull(actionItem.customAccessibilityLogic) &&
                                    !LDH.IsValueEmpty(actionItem.customAccessibilityLogic)) {
                                    let actionItemVisibility = thisComp.evaluateCustomColumnAccessibilityLogic(
                                        actionItem, data.e.row.data);
                                    if (actionItemVisibility === "hidden") continue;
                                    buttonVisibility = actionItemVisibility;
                                }

                                if (actionGroupVisibility === "disabled") {
                                    buttonVisibility = actionGroupVisibility;
                                }
                                menuItems.push({
                                    text: actionItem.linkText,
                                    beginGroup: !groupAdded,
                                    disabled: buttonVisibility === "disabled",
                                    onItemClick: () => thisComp.actionItemOnPopupClicked({
                                        clickedFrom: "context-menu",
                                        actionItemId: actionItemId,
                                        gridViewId: data.gridViewId,
                                        actionGroupId: customColumn.id,
                                        data: LDH.DeepClone(actionItem),
                                        limitedColumns: LDH.DeepClone(data.limitedColumns),
                                        definition: LDH.DeepClone(data.gridDefinition),
                                        e: data.e,
                                        id: data.gridViewId + "_" + id + "_" + data.e.row.rowIndex.toString()
                                    })
                                });
                                groupAdded = true;
                            }
                        }
                    }
                } else if ((customColumn.columnType === "action-item" && !LDH.IsObjectNull(customColumn.isDisplaySideBySide) &&
                    customColumn.isDisplaySideBySide)) {
                    let id = LDH.GenerateGuid();

                    let buttonVisibility2 = null;
                    if (!LDH.IsObjectNull(customColumn.customAccessibilityLogic) &&
                        !LDH.IsValueEmpty(customColumn.customAccessibilityLogic)) {
                        let actionItemVisibility2 = thisComp.evaluateCustomColumnAccessibilityLogic(
                            customColumn, data.e.row.data);
                        if (actionItemVisibility2 === "hidden") continue;
                        buttonVisibility2 = actionItemVisibility2;
                    }

                    menuItems.push({
                        text: customColumn.linkText,
                        beginGroup: !groupAdded,
                        disabled: buttonVisibility2 === "disabled",
                        onItemClick: () => thisComp.actionItemOnPopupClicked({
                            clickedFrom: "context-menu",
                            actionItemId: customColumn.id,
                            gridViewId: data.gridViewId,
                            actionGroupId: customColumn.id,
                            data: LDH.DeepClone(customColumn),
                            limitedColumns: LDH.DeepClone(data.limitedColumns),
                            definition: LDH.DeepClone(data.gridDefinition),
                            e: data.e,
                            id: data.gridViewId + "_" + id + "_" + data.e.row.rowIndex.toString()
                        })
                    });
                    groupAdded = true;
                } else if (customColumn.columnType !== "action-item" && customColumn.columnType !== "action-group" &&
                    customColumn.columnType !== "add-row") {
                    let id = LDH.GenerateGuid();
                    let isDisableButton = LPH.IsEnableAdminUserOnlyForCommandLink(
                        data.gridDefinition, customColumn.columnType);

                    if (!isDisableButton && customColumn.columnType === "photo-gallery") {
                        let hasCountColumn = !LDH.IsObjectNull(customColumn.countColumnName) &&
                            !LDH.IsValueEmpty(customColumn.countColumnName);

                        if (hasCountColumn) {
                            let value = data.e.row.data[customColumn.countColumnName];
                            if (LDH.IsValueEmpty(value) === false &&
                                (value.toString().toLowerCase() === "true" ||
                                    value.toString().toLowerCase() !== "0")) {
                                isDisableButton = false;
                            } else {
                                isDisableButton = true;
                            }
                        }
                    }

                    menuItems.push({
                        text: customColumn.linkText,
                        beginGroup: !groupAdded,
                        disabled: isDisableButton,
                        onItemClick: () => thisComp.commandColumnLinkOnClick({
                            e: data.e, data: customColumn,
                            limitedColumns: data.limitedColumns,
                            definition: data.gridDefinition,
                            gridViewId: data.gridViewId,
                            id: data.gridViewId + "_" + id + "_" + data.e.row.rowIndex.toString()
                        })
                    });
                    groupAdded = true;
                }
            }
        }
        data.e.items = menuItems;
    };

    render() {
        if (this.disposingAllInstances) return null;

        let gridViewState = this.props.state.gridViewState;
        let currentState = this.getCurrentState(gridViewState, false);
        if (LDH.IsObjectNull(currentState)) {
            return (
                <div className={"leopard-dataview-retrievingdata-text"}>
                    Retrieving data, please wait...
                </div>
            );
        }
        let gridDefinition = null;

        if (this.props.useStateStore === true) {
            gridDefinition = this.gridDefinition;
        } else {
            gridDefinition = currentState.gridDefinition;
        }

        this.enableAutoRefresh = this.props.enableAutoRefresh;
        if (LDH.IsObjectNull(this.enableAutoRefresh)) {
            this.enableAutoRefresh = gridDefinition.enableAutoRefresh;
        }
        if (LDH.IsValueEmpty(this.enableAutoRefresh)) {
            this.enableAutoRefresh = false;
        }

        this.autoRefreshInterval = this.props.autoRefreshInterval;
        if (LDH.IsObjectNull(this.autoRefreshInterval)) {
            this.autoRefreshInterval = gridDefinition.autoRefreshInterval;
        }
        if (LDH.IsValueEmpty(this.autoRefreshInterval)) {
            this.autoRefreshInterval = 30;
        }

        this.customColumnOverallWidth = this.props.customColumnOverallWidth;
        if (LDH.IsObjectNull(this.customColumnOverallWidth)) {
            this.customColumnOverallWidth = gridDefinition.customColumnOverallWidth;
        }
        if (LDH.IsValueEmpty(this.customColumnOverallWidth)) {
            this.customColumnOverallWidth = 200;
        }

        this.showAutoRefreshSwitch = this.props.showAutoRefreshSwitch;
        if (LDH.IsObjectNull(this.showAutoRefreshSwitch)) {
            this.showAutoRefreshSwitch = gridDefinition.showAutoRefreshSwitch;
        }
        if (LDH.IsValueEmpty(this.showAutoRefreshSwitch)) {
            this.showAutoRefreshSwitch = true;
        }

        let showAddButton = false;
        let customColumnForAddRow = null;
        if (!LDH.IsObjectNull(gridDefinition.customColumnConfiguration) &&
            !LDH.IsObjectNull(gridDefinition.customColumnConfiguration.customColumns) &&
            gridDefinition.customColumnConfiguration.customColumns.length > 0) {
            for (let i = 0; i < gridDefinition.customColumnConfiguration.customColumns.length; i++) {
                let customColumn = gridDefinition.customColumnConfiguration.customColumns[i];
                if (customColumn.columnType === "add-row") {
                    showAddButton = true;
                    customColumnForAddRow = customColumn;
                }
            }
        }

        if (!LDH.IsObjectNull(gridDefinition.globalVariablesInit) && !LDH.IsValueEmpty(gridDefinition.globalVariablesInit) &&
            !this.globalVariableInitialized) {
            let javascript = gridDefinition.globalVariablesInit;
            let dataName = "data";
            let dataValue = "";
            LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, this.props.GridViewId, null);
            this.globalVariableInitialized = true;
        }

        return (
            <React.Fragment>
                <LeopardGridViewToolbar groupingEnabled={false}
                                        uploadButtonOnClick={(e) => this.uploadButtonOnClick(e)}
                                        downloadButtonOnClick={(e) => this.downloadButtonOnClick(e)}
                                        groupingButtonOnClick={(e) => this.groupingButtonOnClick(e)}
                                        gridViewId={this.props.GridViewId} minHeight={this.props.minHeightToolbar}
                                        addRowButtonOnClick={(e) => this.addRowButtonOnClick({
                                            e, data: customColumnForAddRow,
                                            limitedColumns: this.props.columnFieldList.limitedColumns,
                                            definition: gridDefinition
                                        })}
                                        explicitFilterOption={this.props.explicitFilterOption}
                                        showAutoRefreshSwitch={this.showAutoRefreshSwitch}
                                        autoRefreshCountdownOnEnd={(e) => this.autoRefreshCountdownOnEnd(e)}
                                        gridDefinition={gridDefinition} autoRefreshInterval={this.autoRefreshInterval}
                                        applyFilterButtonOnClick={(e) => this.applyFilterButtonOnClick(e)}
                                        clearFilterButtonOnClick={(e) => this.clearFilterButtonOnClick({
                                            e: e,
                                            gridDefinition: gridDefinition
                                        })}
                                        refreshButtonOnClick={(e) => this.refreshButtonOnClick({
                                            gridViewId: this.props.GridViewId,
                                            definition: gridDefinition,
                                            refreshChildViews: !LDH.IsObjectNull(e) &&
                                            !LDH.IsObjectNull(e.refreshChildViews) ? e.refreshChildViews : false
                                        })}
                                        viewOptionsButtonOnClick={() => this.viewOptionsButtonOnClick({
                                            gridViewId: this.props.GridViewId
                                        })}
                                        exportButtonOnClick={(e) => this.exportButtonOnClick(e)}
                                        importButtonOnClick={() => this.importButtonOnClick({gridViewId: this.props.GridViewId})}
                                        layoutButtonOnClick={() => this.layoutButtonOnClick({gridViewId: this.props.GridViewId})}
                                        importDataCallback={(data) => this.importDataCallback({
                                            gridViewId: this.props.GridViewId,
                                            data: data.data,
                                            definition: gridDefinition
                                        })}
                                        submitDataCallback={(data) => this.submitDataCallback({
                                            gridViewId: this.props.GridViewId,
                                            data: data.data,
                                            definition: gridDefinition
                                        })}
                                        removeAllButtonOnClick={(data) => this.removeAllButtonOnClick({
                                            gridViewId: this.props.GridViewId,
                                            data: data.data,
                                            definition: gridDefinition
                                        })}
                                        showAddButton={showAddButton} enableAutoRefresh={this.enableAutoRefresh}/>

                <BuildLeopardGridView setGridViewInstance={this.setGridViewInstance} thisComp={this}
                                      setExportOptionPopupInstance={this.setExportOptionPopupInstance}
                                      currentState={currentState} gridDefinition={gridDefinition}
                                      onRowExpanding={(e) => this.onRowExpanding(e)}
                                      onInitialized={(e) => this.onInitialized(e)}
                                      onDataErrorOccurred={(e) => this.onDataErrorOccurred(e)}
                                      onToolbarPreparing={(e) => this.onToolbarPreparing(e)}
                                      gridViewId={this.props.GridViewId}
                                      viewOptionsText={this.props.viewOptionsText}
                                      groupingEnabled={false} buttonNextOnClick={(e) => this.buttonNextOnClick(e)}
                                      buttonPrevOnClick={(e) => this.buttonPrevOnClick(e)}
                                      onCellPrepared={(e) => this.onCellPrepared(e)}
                                      onEditorPreparing={(e) => this.onEditorPreparing(e)}
                                      explicitFilterOption={this.props.explicitFilterOption}
                                      columnResizingMode={this.props.columnResizingMode}
                                      filterBuilderValue={this.props.filterBuilderValue}
                                      onRowClick={(e) => this.onRowClick(e)}
                                      onSelectionChanged={(e) => this.onSelectionChanged(e)}
                                      customizeColumns={(e) => this.customizeColumns(e)}
                                      limitedColumns={this.props.columnFieldList.limitedColumns}
                                      onContentReady={(e) => this.onContentReady(e)}
                                      onContextMenuPreparing={(e) => this.onContextMenuPreparing(e)}
                                      exportOptionPopupOnHiding={(e) => this.exportOptionPopupOnHiding(e)}
                                      exportDataOnPopupClicked={(e) => this.exportDataOnPopupClicked(e)}
                                      setComponentInstance={(e) => this.setComponentInstance(e)}
                                      actionItemOnPopupClicked={(e) => this.actionItemOnPopupClicked(e)}
                                      genericPopoverOnShown={(e) => this.genericPopoverOnShown(e)}
                                      onFocusedCellChanging={(e) => this.onFocusedCellChanging(e)}
                                      gridViewLayoutCustomLoad={(e) => this.gridViewLayoutCustomLoad(e)}
                                      requireReloadDataView={(e) => this.requireReloadDataView(e)}
                                      saveLayoutOnClick={(e) => this.saveLayoutOnClick(e)}
                                      createLayoutOnClick={(e) => this.createLayoutOnClick(e)}
                                      deleteLayoutOnClick={(e) => this.deleteLayoutOnClick(e)}
                                      renameLayoutOnClick={(e) => this.renameLayoutOnClick(e)}
                                      gridViewDataSourceOnChanged={this.gridViewDataSourceOnChanged}/>
            </React.Fragment>
        );
    }
}

const BuildLeopardGridView = ({
                                  setGridViewInstance,
                                  currentState,
                                  onContentReady,
                                  explicitFilterOption,
                                  onToolbarPreparing,
                                  onRowExpanding,
                                  gridViewId,
                                  buttonNextOnClick,
                                  buttonPrevOnClick,
                                  viewOptionsText,
                                  limitedColumns,
                                  onInitialized,
                                  columnResizingMode,
                                  filterBuilderValue,
                                  onDataErrorOccurred,
                                  thisComp,
                                  onEditorPreparing,
                                  gridDefinition,
                                  customizeColumns,
                                  onCellPrepared,
                                  onSelectionChanged,
                                  onRowClick,
                                  exportOptionPopupOnHiding,
                                  setExportOptionPopupInstance,
                                  exportDataOnPopupClicked,
                                  setComponentInstance,
                                  actionItemOnPopupClicked,
                                  genericPopoverOnShown,
                                  onContextMenuPreparing,
                                  onFocusedCellChanging,
                                  gridViewLayoutCustomLoad,
                                  requireReloadDataView,
                                  saveLayoutOnClick,
                                  createLayoutOnClick,
                                  deleteLayoutOnClick,
                                  renameLayoutOnClick,
                                  gridViewDataSourceOnChanged
                              }) => {
    if (LDH.IsValueEmpty(explicitFilterOption) === true) {
        explicitFilterOption = LeopardStaticUIConfig.GridView_ExplicitFilterOption;
    }

    thisComp.optimizePagerForLargeDataset = gridDefinition.optimizePagerForLargeDataset;
    if (LDH.IsValueEmpty(thisComp.optimizePagerForLargeDataset)) {
        thisComp.optimizePagerForLargeDataset = true;
    }

    if (!LDH.IsValueEmpty(gridDefinition.defaultPageSize) &&
        gridDefinition.defaultPageSize < 50) {
        gridDefinition.defaultPageSize = 50;
    }

    thisComp.defaultPageSize = gridDefinition.defaultPageSize;
    if (LDH.IsValueEmpty(thisComp.defaultPageSize)) {
        thisComp.defaultPageSize = 50;
    }

    thisComp.customColumnConfiguration = gridDefinition.customColumnConfiguration;
    LDH.ParseDevExtremeFilterString(filterBuilderValue);

    let isGridViewJSONEngine = thisComp.props.isGridViewJSONEngine;
    let staticJSONData = thisComp.props.staticJSONData;

    if (gridDefinition.gridViewEngine === "csv-adhoc-engine" &&
        thisComp.dynamicGridViewHeaders.length > 0 &&
        limitedColumns.length === 0) {
        limitedColumns = thisComp.dynamicGridViewHeaders;
    }

    let columnComponent = LeopardGridViewColumnBuilder(gridDefinition, limitedColumns,
        thisComp, isGridViewJSONEngine, staticJSONData);
    if (!LDH.IsObjectNull(thisComp.customColumnConfiguration) &&
        !LDH.IsObjectNull(thisComp.customColumnConfiguration.customColumns) &&
        thisComp.customColumnConfiguration.customColumns.length > 0) {
        let buttons = [];

        for (let i = 0; i < thisComp.customColumnConfiguration.customColumns.length; i++) {
            let customColumn = thisComp.customColumnConfiguration.customColumns[i];
            let id = LDH.GenerateGuid();

            if (customColumn.columnType === "add-row") {
                continue;
            }

            if (!LDH.IsObjectNull(customColumn.columnType) && customColumn.columnType === "action-item" &&
                !LDH.IsObjectNull(customColumn.isDisplaySideBySide) &&
                customColumn.isDisplaySideBySide === false) {
                continue;
            }

            if ((!LDH.IsObjectNull(customColumn.userRoles) && customColumn.userRoles.length === 0) ||
                (LDH.IsObjectNull(customColumn.userRoles)) &&
                (customColumn.columnType === "action-group" || customColumn.columnType === "action-item")) {
                continue;
            }

            if (!LDH.IsObjectNull(customColumn.userRoles) && customColumn.userRoles.length > 0) {
                let userRoles = LeopardSecurity.GetCurrentUserRoles().split(";");
                let foundRole = false;
                for (let v = 0; v < customColumn.userRoles.length; v++) {
                    for (let c = 0; c < userRoles.length; c++) {
                        if (userRoles[c] === customColumn.userRoles[v]) {
                            foundRole = true;
                        }
                    }
                }
                if (foundRole === false) continue;
            }

            if ((customColumn.columnType === "action-item" && !LDH.IsObjectNull(customColumn.isDisplaySideBySide) &&
                customColumn.isDisplaySideBySide)) {
                let id = LDH.GenerateGuid();
                buttons.push({
                    text: customColumn.linkText,
                    beginGroup: true,
                    onClick: (e) => thisComp.actionItemOnPopupClicked({
                        clickedFrom: "context-menu",
                        actionItemId: customColumn.id,
                        gridViewId: gridViewId,
                        actionGroupId: customColumn.id,
                        data: LDH.DeepClone(customColumn),
                        limitedColumns: LDH.DeepClone(limitedColumns),
                        definition: LDH.DeepClone(gridDefinition),
                        e: e,
                        id: gridViewId + "_" + id + "_" + e.row.rowIndex.toString()
                    })
                });
            } else {
                buttons.push({
                    text: customColumn.linkText, countColumn: customColumn.countColumnName,
                    enableAdminUserOnly: customColumn.enableAdminUserOnly,
                    customColumn: customColumn,
                    onClick(e) {
                        thisComp.commandColumnLinkOnClick({
                            e, data: customColumn, limitedColumns,
                            definition: gridDefinition,
                            gridViewId: gridViewId,
                            id: gridViewId + "_" + id + "_" + e.row.rowIndex.toString()
                        });
                    }, columnType: customColumn.columnType, id: gridViewId + "_" + id
                });
            }
        }

        let width = thisComp.customColumnConfiguration.customColumnOverallWidth;
        if (LDH.IsObjectNull(columnComponent) === false && buttons.length > 0) {
            columnComponent.push(<Column key={"CustomColumn"} width={width} type={"buttons"} buttons={buttons}/>);
        }
    }

    let sizeAttributes = {};
    if (!LDH.IsObjectNull(gridDefinition.popupFormEditorSize) && !LDH.IsValueEmpty(gridDefinition.popupFormEditorSize) &&
        gridDefinition.popupFormEditorSize === "manual" &&
        !LDH.IsObjectNull(gridDefinition.popupFormEditorWidth) && !LDH.IsValueEmpty(gridDefinition.popupFormEditorWidth) &&
        !LDH.IsObjectNull(gridDefinition.popupFormEditorHeight) && !LDH.IsValueEmpty(gridDefinition.popupFormEditorHeight)) {
        sizeAttributes = {
            defaultWidth: gridDefinition.popupFormEditorWidth,
            defaultHeight: gridDefinition.popupFormEditorHeight
        };
    }

    let rowKey = "";
    if (!LDH.IsObjectNull(gridDefinition.gridViewRowKey) && !LDH.IsValueEmpty(gridDefinition.gridViewRowKey)) {
        rowKey = gridDefinition.gridViewRowKey;
    }

    let gridViewClass = "leopard-gridview-control";
    if (thisComp.props.isLoadForTabbedView) {
        let largeDS = false;
        if (!LDH.IsObjectNull(gridDefinition.optimizePagerForLargeDataset) &&
            !LDH.IsValueEmpty(gridDefinition.optimizePagerForLargeDataset)) {
            largeDS = gridDefinition.optimizePagerForLargeDataset;
        }
        gridViewClass += " " + "leopard-gridview-control-within-tabview" + " " + (largeDS ? "optimized-pager" : "");
    }

    let documentRetrieveType = "leopardsystems.document.retrieve";
    if (!LDH.IsValueEmpty(gridDefinition.documentRetrieveType)) {
        documentRetrieveType = gridDefinition.documentRetrieveType;
    }

    let popupFormEditorRowCount = 2;
    if (!LDH.IsValueEmpty(gridDefinition.popupFormEditorRowCount)) {
        popupFormEditorRowCount = gridDefinition.popupFormEditorRowCount;
    }

    let documentModifyType = "leopardsystems.document.modify";
    if (!LDH.IsValueEmpty(gridDefinition.documentModifyType)) {
        documentModifyType = gridDefinition.documentModifyType;
    }

    let documentCreateType = "leopardsystems.document.create";
    if (!LDH.IsValueEmpty(gridDefinition.documentCreateType)) {
        documentCreateType = gridDefinition.documentCreateType;
    }

    let documentListType = "leopardsystems.document.list";
    if (!LDH.IsValueEmpty(gridDefinition.documentListType)) {
        documentListType = gridDefinition.documentListType;
    }

    let uploaderList = LeopardDropdownHelper.DropdownSelectionDataGridDocumentDirectory;
    if (!LDH.IsValueEmpty(gridDefinition.popupFileUploaderList) && gridDefinition.popupFileUploaderList === "formService") {
        uploaderList = LeopardDropdownHelper.DropdownSelectionDataGridFormDirectory;
    }

    let createNewFormViaPopup = false;
    if (!LDH.IsValueEmpty(gridDefinition.createNewFormViaPopup)) {
        createNewFormViaPopup = gridDefinition.createNewFormViaPopup;
    }

    return (
        <React.Fragment>
            <DataGrid cacheEnabled={LeopardStaticUIConfig.GridView_CacheEnabled}
                      dataSource={currentState.customStore} showBorders={LeopardStaticUIConfig.showBorders}
                      onContentReady={(e) => onContentReady(e)} keyExpr={rowKey}
                      errorRowEnabled={LeopardStaticUIConfig.GridView_ErrorRowEnabled}
                      allowColumnResizing={LeopardStaticUIConfig.GridView_AllowColumnResizing}
                      columnMinWidth={LeopardStaticUIConfig.GridView_ColumnMinWidth}
                      columnAutoWidth={LeopardStaticUIConfig.GridView_ColumnAutoWidth}
                      onFocusedCellChanging={(e) => onFocusedCellChanging(e)}
                      onCellPrepared={(e) => onCellPrepared(e)}
                      onSelectionChanged={(e) => onSelectionChanged(e)}
                      onRowClick={(e) => onRowClick(e)}
                      onContextMenuPreparing={(e) => onContextMenuPreparing({
                          e: e, thisComp: thisComp, gridViewId: gridViewId,
                          limitedColumns: limitedColumns, gridDefinition: gridDefinition
                      })}
                      highlightChanges={LeopardStaticUIConfig.GridView_HighlightChanges}
                      onEditorPreparing={(e) => onEditorPreparing({
                          e, gridViewId, gridDefinition
                      })}
                      customizeColumns={(e) => customizeColumns({e: e, gridDefinition: gridDefinition})}
                      repaintChangesOnly={LeopardStaticUIConfig.GridView_RepaintChangesOnly}
                      columnResizingMode={columnResizingMode} filterValue={filterBuilderValue}
                      allowColumnReordering={LeopardStaticUIConfig.GridView_AllowColumnReordering}
                      hoverStateEnabled={LeopardStaticUIConfig.GridView_HoverStateEnabled} id={gridViewId}
                      onRowExpanding={(e) => onRowExpanding(e)} ref={setGridViewInstance}
                      className={gridViewClass} onDataErrorOccurred={(e) => onDataErrorOccurred(e)}
                      onInitialized={(e) => onInitialized(e)} onToolbarPreparing={(e) => onToolbarPreparing(e)}
                      onRowUpdated={(e) => gridViewDataSourceOnChanged(e, "updated", thisComp)}
                      onRowRemoved={(e) => gridViewDataSourceOnChanged(e, "removed", thisComp)}
                      onRowInserted={(e) => gridViewDataSourceOnChanged(e, "inserted", thisComp)}
                      rowAlternationEnabled={LeopardStaticUIConfig.GridView_RowAlternationEnabled}>
                {
                    LDH.IsObjectNull(gridDefinition) ? "" : columnComponent
                }
                <ColumnChooser enabled={LeopardStaticUIConfig.ColumnChooser_Enabled} title={viewOptionsText}/>
                <Editing allowUpdating={false} allowDeleting={false} allowAdding={false}
                         texts={{confirmDeleteMessage: ""}}/>
                <Export enabled={true} fileName={viewOptionsText} allowExportSelectedData={true}/>
                <Selection mode={"single"}></Selection>
                <StateStoring enabled={true} type="custom" storageKey="gridViewLayout"
                              customLoad={gridViewLayoutCustomLoad}/>
                <LoadPanel enabled={LeopardStaticUIConfig.LoadPanel_ShowIndicator}
                           showPane={LeopardStaticUIConfig.LoadPanel_ShowPane}/>
                <FilterRow visible={LeopardStaticUIConfig.GridView_FilterRow} applyFilter={explicitFilterOption}/>
                <Sorting mode={LeopardStaticUIConfig.Sorting_Mode}/>
                <HeaderFilter visible={LeopardStaticUIConfig.HeaderFilter_Visible}/>
                <ColumnFixing enabled={LeopardStaticUIConfig.ColumnFixing_Enabled}/>
                {
                    !LDH.IsObjectNull(gridDefinition.enablePagination) && gridDefinition.enablePagination === false ?
                        <Paging defaultPageSize={thisComp.defaultPageSize} enabled={false}></Paging> :
                        <Paging defaultPageSize={thisComp.defaultPageSize}
                                enabled={thisComp.optimizePagerForLargeDataset === false}>
                        </Paging>
                }
                {
                    !LDH.IsObjectNull(gridDefinition.enablePagination) && gridDefinition.enablePagination === false ?
                        <Pager visible={false}></Pager> :
                        <Pager visible={thisComp.optimizePagerForLargeDataset === false}
                               infoText={"{0} of {1} ({2})"}
                               showPageSizeSelector={thisComp.optimizePagerForLargeDataset === false}
                               allowedPageSizes={LeopardStaticUIConfig.Pager_allowedPageSizes}
                               showInfo={thisComp.optimizePagerForLargeDataset === false}
                               showNavigationButtons={thisComp.optimizePagerForLargeDataset === false}>
                        </Pager>
                }
                {
                    !LDH.IsObjectNull(gridDefinition.enablePagination) && gridDefinition.enablePagination === false ?
                        <RemoteOperations paging={true} filtering={true} grouping={true} groupPaging={true}
                                          sorting={true}/> :
                        thisComp.optimizePagerForLargeDataset ?
                            <RemoteOperations paging={false} filtering={true} grouping={true} groupPaging={true}
                                              sorting={true}/> :
                            <RemoteOperations paging={true} filtering={true} grouping={true} groupPaging={true}
                                              sorting={true}/>
                }
            </DataGrid>
            {
                (!LDH.IsObjectNull(gridDefinition.enablePagination) && gridDefinition.enablePagination === false) ||
                thisComp.optimizePagerForLargeDataset === false ? "" :
                    <div className={"leopard-page-container"} id={"GridViewPager_" + gridViewId}>
                        <div className={"leopard-page-number " + (thisComp.defaultPageSize === 50 ? "selected" : "")}
                             pagesize="50">50
                        </div>
                        <div className={"leopard-page-number " + (thisComp.defaultPageSize === 100 ? "selected" : "")}
                             pagesize="100">100
                        </div>
                        <div className={"leopard-page-number " + (thisComp.defaultPageSize === 200 ? "selected" : "")}
                             pagesize="200">200
                        </div>
                        <div className={"leopard-page-button-container next"}>
                            <i className={"fas fa-chevron-right"} style={{fontSize: "25px", float: "right"}}
                               onClick={(e) => buttonNextOnClick({e, gridViewId})}></i>
                            <a className="leopard-page-button" style={{marginRight: "5px"}} href={"#_"}
                               onClick={(e) => buttonNextOnClick({e, gridViewId})}>Next</a>
                        </div>
                        <div className={"leopard-page-button-container previous"}>
                            <i className={"fas fa-chevron-left"} style={{fontSize: "25px"}}
                               onClick={(e) => buttonPrevOnClick({e, gridViewId})}></i>
                            <a className="leopard-page-button" style={{marginRight: "10px"}} href={"#_"}
                               onClick={(e) => buttonPrevOnClick({e, gridViewId})}>Previous</a>
                        </div>
                        <div className={"leopard-page-infotext"}>
                            Page <span className={"leopard-pagenumber-current"}></span>
                        </div>
                    </div>
            }
            <LeopardAttachmentPopup
                popupPhotoGalleryInstance={thisComp.addPhotoGalleryPopupInstance} popupWidth={"1024px"}
                addDisposablePopupInstances={(e) => thisComp.addDisposablePopupInstances(e)}
                popupButtonSaveOnClick={(e) => thisComp.popupButtonSaveOnClick(e)}
                popupHeight={"695px"} popupTitle={"Attachments"}
                dataViewId={gridViewId}
            ></LeopardAttachmentPopup>

            <LeopardFormEditor
                popupEditFormInstance={thisComp.addEditFormPopupInstance} popupWidth={"800px"}
                popupTitle={"Edit Row"} sizeAttributes={sizeAttributes}
                popupFormEditorRowCount={popupFormEditorRowCount}
                addDisposablePopupInstances={(e) => thisComp.addDisposablePopupInstances(e)}
                popupOnClosed={(e) => thisComp.refreshGridViewOnPopupClosed(e)}
            ></LeopardFormEditor>

            <LeopardDataImportPopup
                popupTitle={"Import"} contentHeight={"100%"} dataViewId={gridViewId}
                addDisposablePopupInstances={(e) => thisComp.addDisposablePopupInstances(e)}
                popupDataImportInstance={thisComp.addDataImportPopupInstance}
                popupOnClosed={(e) => thisComp.refreshGridViewOnPopupClosed(e)}>
            </LeopardDataImportPopup>

            <LeopardReportSchedulerPopup
                popupTitle={"View Schedules"} contentHeight={"100%"} dataViewId={gridViewId}
                dataViewId={gridViewId} gridDefinition={gridDefinition}
                addDisposablePopupInstances={(e) => thisComp.addDisposablePopupInstances(e)}
                popupReportSchedulerInstance={thisComp.addReportSchedulerPopupInstance}>
            </LeopardReportSchedulerPopup>

            <Popover target={"#GridView_TopBar_Export_" + gridViewId} position="bottom" width={"auto"}
                     onHiding={(e) => exportOptionPopupOnHiding(e)} ref={setExportOptionPopupInstance}
                     shading={false} animation={null}>
                <div>
                    <div className={"export-option-popup-link"} onClick={() => exportDataOnPopupClicked("XLSX")}>
                        Microsoft Excel (XLSX)
                    </div>
                    <div className={"export-option-popup-link"} onClick={() => exportDataOnPopupClicked("CSV")}>
                        Comma-Separated Values (CSV)
                    </div>
                </div>
            </Popover>

            <LeopardLayoutSelectionPopup
                dataViewPersistentId={gridDefinition.dataViewPersistentId} dataViewId={gridViewId}
                setComponentInstance={(e) => setComponentInstance(e)}
                saveLayoutOnClick={(e) => saveLayoutOnClick(e)}
                deleteLayoutOnClick={(e) => deleteLayoutOnClick(e)}
                createLayoutOnClick={(e) => createLayoutOnClick(e)}
                renameLayoutOnClick={(e) => renameLayoutOnClick(e)}
                requireReloadDataView={(e) => requireReloadDataView(e)}
                dashboardId={thisComp.props.dashboardId} isDashboardLayout={false}
                dashboardLayoutDataViewMapping={thisComp.props.dashboardLayoutDataViewMapping}
                addDisposablePopupInstances={(e) => thisComp.addDisposablePopupInstances(e)}>
            </LeopardLayoutSelectionPopup>

            <LeopardDocumentUploader
                dataViewId={gridViewId} documentRetrieveType={documentRetrieveType}
                documentModifyType={documentModifyType} documentCreateType={documentCreateType}
                documentListType={documentListType} fileUploaderList={uploaderList}
                createNewFormViaPopup={createNewFormViaPopup}
                addDisposablePopupInstances={(e) => thisComp.addDisposablePopupInstances(e)}
                setComponentInstance={(e) => setComponentInstance(e)}>
            </LeopardDocumentUploader>

            {
                !LDH.IsObjectNull(thisComp.customColumnConfiguration) &&
                !LDH.IsObjectNull(thisComp.customColumnConfiguration.customColumns) ?
                    thisComp.customColumnConfiguration.customColumns.map(function (actionGroup, i) {
                        if (actionGroup.columnType === "action-group") {
                            return (
                                <Popover position="bottom" width={"auto"} ref={(e) => setComponentInstance({
                                    e: e, componentName: gridViewId + "_popover_actionGroup_" + actionGroup.id
                                })} key={gridViewId + "_popover_actionGroup_" + actionGroup.id} shading={false}
                                         animation={null} onShown={(e) => genericPopoverOnShown({
                                    e: e, gridViewId: gridViewId, id: actionGroup.id, actionGroup: actionGroup,
                                    commandLinks: thisComp.customColumnConfiguration.customColumns
                                })} className={"popover_gridview_commandbuttons"}>
                                    {
                                        LDH.IsObjectNull(actionGroup.associatedActionItems) || actionGroup.associatedActionItems.length === 0 ? null :
                                            actionGroup.associatedActionItems.map(function (actionItemId, j) {
                                                return (<div className={"export-option-popup-link"}
                                                             key={gridViewId + "_popover_actionGroup_" + actionGroup.id + "_actionItem_" + actionItemId}
                                                             id={gridViewId + "_popover_actionGroup_" + actionGroup.id + "_actionItem_" + actionItemId}>
                                                    {
                                                        thisComp.customColumnConfiguration.customColumns.map(function (actionItem, k) {
                                                            if (actionItem.columnType === "action-item" && actionItem.id === actionItemId) {
                                                                return (
                                                                    <React.Fragment
                                                                        key={gridViewId + "_popover_actionGroup_" + actionGroup.id + "_actionItem_text_" + actionItemId}>
                                                                        <div
                                                                            id={gridViewId + "_popover_actionGroup_" + actionGroup.id + "_actionItem_text_" + actionItemId}
                                                                            onClick={(e) => actionItemOnPopupClicked({
                                                                                clickedFrom: "command-link",
                                                                                actionItemId: actionItemId,
                                                                                gridViewId: gridViewId,
                                                                                actionGroupId: actionGroup.id,
                                                                                data: LDH.DeepClone(actionItem),
                                                                                limitedColumns: LDH.DeepClone(limitedColumns),
                                                                                definition: LDH.DeepClone(gridDefinition),
                                                                                e: e,
                                                                                actionGroupInstanceId: gridViewId + "_popover_actionGroup_" + actionGroup.id
                                                                            })}>{actionItem.linkText}
                                                                        </div>
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                            return null;
                                                        })
                                                    }
                                                </div>);
                                            })
                                    }
                                </Popover>);
                        }
                        return null;
                    }) : null
            }
        </React.Fragment>
    );
};

const RetrieveDataFromReducer = (state) => {
    return {state};
};

const SendDataToReducer = (dispatch) => {
    return {
        InitCustomStore: (store, id) => {
            dispatch(InitCustomStore(store, id));
        },
        UpdateCustomStore: (store, id) => {
            dispatch(UpdateCustomStore(store, id));
        },
        UpdateGridViewDefinition: (data, id) => {
            dispatch(UpdateGridViewDefinition(data, id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardGridView);
