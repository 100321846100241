import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import RGL, {WidthProvider} from "react-grid-layout";
import {Switch} from 'devextreme-react';

import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardChildGridView from '../foundation/LeopardChildGridView';
import LeopardChildChart from '../foundation/LeopardChildChart';
import LeopardChildTimeline from '../foundation/LeopardChildTimeline';
import LeopardChildTabbedView from '../foundation/LeopardChildTabbedView';
import LeopardChildMap from '../foundation/LeopardChildMap';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import LeopardChildAttachableWidget from "../foundation/LeopardChildAttachableWidget";
import LeopardDocumentEditorConfiguration from "../components/LeopardDocumentEditorConfiguration";
import LeopardFormEditorConfiguration from "../components/LeopardFormEditorConfiguration";
import LeopardChildAnalyticsDashboard from "../foundation/LeopardChildAnalyticsDashboard";
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LeopardLayoutSelectionPopup from "../components/LeopardLayoutSelectionPopup";
import LeopardChildRegexTester from "../foundation/LeopardChildRegexTester";
import LeopardAPIGatewayConfig from "../foundation/LeopardAPIGatewayConfig";
import LeopardChildHtmlFormBuilder from "../foundation/LeopardChildHtmlFormBuilder";
import LeopardDataHelper from "../helpers/LeopardDataHelper";

class LeopardTemplateView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            templateLayout: null,
            dataViewList: [],
            relationships: [],
            dashboardInitialized: false
        };

        this.menuItems = [];
        this.ajaxLoadDataCount = 0;
        this.dashboardLayout = [];
        this.dashboardLayoutDataViewMapping = [];
        this.dataViewListToAdd = [];
        this.addDataViewListProcess = null;
        this.uiObjectInstances = [];
        this.disposablePopupInstances = [];
    }

    componentDidMount = () => {
        LeopardStaticUIConfig.Global_DashboardDataViewSiteAccessRegister = [];
        LeopardStaticUIConfig.Global_DashboardDataViewListeners = [];
        LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault = true;
        window.Global_TempDateConvertion = [];
        this.loadLayoutDefinitionByAjaxRequest();
    };

    componentWillUnmount = () => {
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
        this.uiObjectInstances = [];
        this.setState({templateLayout: [], relationships: []});

        let keys = Object.keys(window);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i].indexOf("datasource_subscriber_dataview_persistent_") > -1 ||
                keys[i].indexOf("gridview_datasource_persistent_") > -1 ||
                keys[i].indexOf("Global_SelectedRowData_ParentPersistentId_") > -1) {
                window[keys[i]] = null;
            }
        }
    };

    loadLayoutDefinitionByAjaxRequest = () => {
        let that = this;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let dashboardId = this.props.menuItem.dashboardId;
        let dashboardLayoutFromLayouts = null;
        LeopardAjaxHelper.GetDataViewLayoutById(userId, dashboardId, function (response) {
            let layouts = response;
            if (!LDH.IsObjectNull(layouts)) {
                if (!LDH.IsObjectNull(layouts.selectedLayoutId) &&
                    !LDH.IsObjectNull(layouts.layouts) && layouts.layouts.length > 0) {
                    for (let i = 0; i < layouts.layouts.length; i++) {
                        if (layouts.layouts[i].layoutId === layouts.selectedLayoutId) {
                            dashboardLayoutFromLayouts = layouts.layouts[i].layoutData;
                            that.dashboardLayoutDataViewMapping = layouts.layouts[i].dataViewMapping;
                        }
                    }
                }
            }

            LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (data) {
                let layout = data.dashboardLayout;
                if (LDH.IsValueEmpty(dashboardLayoutFromLayouts) === false) {
                    layout = dashboardLayoutFromLayouts;
                }
                that.dashboardLayout = layout;
                let relationships = data.relationships;
                that.setState({templateLayout: layout, relationships});
                that.dataViewListToAdd = [];

                let settingsVersion = data.settingsVersion;
                if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
                $(".dashboard-settings-version").attr("settingsversion", settingsVersion);

                let organisationId = LeopardDataHelper.GetOrganizationIdFromUserProfile(window.userProfile);
                LeopardAjaxHelper.GetLeftMenuStructureFromS3(organisationId, function (items) {
                    that.menuItems = items;
                    if (LDH.IsObjectNull(layout)) {
                        LRH.ShowToast("Your Workspace might have been deleted or moved. " +
                            "Please refresh this page to update the Control Centre.", "error", 5000);
                        LRH.ShowOrHideMenuLoadingProgress(false);
                        LRH.ShowUnhandledExceptionForMasterContent();
                        return;
                    }

                    let dataViewIds = [];
                    for (let i = 0; i < layout.length; i++) {
                        let dataViewPersistentId = layout[i].definition.dataViewPersistentId;
                        let item = null;
                        for (let j = 0; j < items.length; j++) {
                            if (!LDH.IsObjectNull(items[j].dataViewPersistentId) &&
                                !LDH.IsValueEmpty(items[j].dataViewPersistentId) &&
                                items[j].dataViewPersistentId === dataViewPersistentId) {
                                item = items[j];
                            }
                        }
                        let dataViewId = (item == null) ? "empty" : item.dataViewId;
                        if (dataViewId === "empty") that.ajaxLoadDataCount += 1;
                        dataViewIds.push(dataViewId);
                    }

                    // Get each data view by id.
                    LeopardAjaxHelper.GetDataViewByIds(userId, dataViewIds, function (dataViewList) {
                        if (LDH.IsObjectNull(dataViewList) || dataViewList.length === 0) {
                            that.ajaxLoadDataCount += 1;
                            if (that.ajaxLoadDataCount >= layout.length) {
                                that.setState({dashboardInitialized: true});
                            }
                            return;
                        }

                        for (let i = 0; i < dataViewList.length; i++) {
                            let dataView = dataViewList[i];

                            if (dataView.dataViewType === "datagrid") {
                                let tableName = dataView.dataSourceUrl;
                                let limitedColumns = [];

                                if (!LDH.IsObjectNull(dataView.dataViewNote) &&
                                    !LDH.IsObjectNull(dataView.dataViewNote.gridViewDefinition)) {
                                    let definition = dataView.dataViewNote.gridViewDefinition;
                                    for (let u = 0; u < definition.columnDefinition.length; u++) {
                                        if (!LDH.IsValueEmpty(definition.columnDefinition[u].isEnabled) &&
                                            definition.columnDefinition[u].isEnabled === false) {
                                            limitedColumns.push(definition.columnDefinition[u].columnName);
                                        }
                                    }
                                }

                                if (LDH.IsObjectNull(dataView.dataViewNote.gridViewDefinition.gridViewEngine) ||
                                    dataView.dataViewNote.gridViewDefinition.gridViewEngine === "odata-engine") {
                                    LeopardAjaxHelper.GetDataTableColumnsByDataSourceUrl(tableName, limitedColumns, function (data) {
                                            dataView.columnFieldList = data;
                                            dataView.isGridViewJSONEngine = false;
                                            that.dataViewListToAdd.push(dataView);
                                            that.ajaxLoadDataCount += 1;
                                            if (that.ajaxLoadDataCount >= layout.length) {
                                                that.setState({dashboardInitialized: true});
                                            }
                                        }, function (error, sessionTimeout) {
                                            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) &&
                                                !LDH.IsValueEmpty(error.message) &&
                                                error.message.indexOf("Cannot read property '") !== -1 &&
                                                error.message.indexOf("' of undefined") !== -1) {
                                                LRH.ShowToast("Your Workspace might have been deleted or moved. " +
                                                    "Please refresh this page to update the Control Centre.", "error", 5000);
                                            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                            } else {
                                                LRH.ShowToast("Failed to retrieve data for your Workspace. " +
                                                    "Please check your network connection status and try again.", "error", 5000);
                                                localStorage.setItem("LoadFirstMenuItem", "false");
                                            }
                                            LRH.ShowOrHideMenuLoadingProgress(false);
                                            LRH.ShowUnhandledExceptionForMasterContent();
                                        }, dataView.dataViewNote.gridViewDefinition.dataSourceCustomQueryTableSchema,
                                        dataView.dataViewNote.gridViewDefinition.customComputedColumns,
                                        dataView.dataViewNote.gridViewDefinition.dataSourceOverrideMeta);
                                } else if (dataView.dataViewNote.gridViewDefinition.gridViewEngine === "json-engine") {
                                    let postTemplate = LDH.DeepClone(LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate());
                                    postTemplate.source = LDH.GenerateGuid();
                                    postTemplate.type = dataView.dataViewNote.gridViewDefinition.dataSourceRequestType;

                                    let userProfile = window.userProfile;
                                    let userId = LDH.GetUserIdFromUserProfile(userProfile);
                                    let postData = [{"owner": userId}];

                                    if (postTemplate.type === "leopardsystems.document.list") {
                                        postData = "not-required";
                                    }

                                    LeopardAjaxHelper.SendRequestByEventSync(function (response) {
                                        let responseData = response.body.data;

                                        if (!LDH.IsObjectNull(responseData) && responseData.length > 0) {
                                            let columnFields = Object.keys(responseData[0]);
                                            let customComputedColumns = dataView.dataViewNote.gridViewDefinition.customComputedColumns;
                                            if (!LDH.IsObjectNull(customComputedColumns) && customComputedColumns.length > 0) {
                                                for (let k = 0; k < customComputedColumns.length; k++) {
                                                    columnFields.push(customComputedColumns[k].name);
                                                }
                                            }
                                            let includedColumns = LDH.GetIncludedColumns(limitedColumns, columnFields, true);
                                            dataView.columnFieldList = {
                                                fullColumns: columnFields,
                                                limitedColumns: includedColumns
                                            };
                                            dataView.staticJSONData = responseData;
                                            dataView.isGridViewJSONEngine = true;
                                        } else {
                                            dataView.columnFieldList = {fullColumns: [], limitedColumns: []};
                                            dataView.staticJSONData = [];
                                            dataView.isGridViewJSONEngine = true;
                                        }

                                        that.dataViewListToAdd.push(dataView);
                                        that.ajaxLoadDataCount += 1;
                                        if (that.ajaxLoadDataCount >= layout.length) {
                                            that.setState({dashboardInitialized: true});
                                        }
                                    }, function (error, sessionTimeout) {
                                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                        } else {
                                            LRH.ShowToast("Failed to retrieve document content.", "error", 5000);
                                        }
                                    }, postTemplate, postData);
                                } else if (dataView.dataViewNote.gridViewDefinition.gridViewEngine === "csv-adhoc-engine") {
                                    dataView.columnFieldList = {fullColumns: ["Empty"], limitedColumns: []};
                                    dataView.staticJSONData = [];
                                    dataView.isGridViewJSONEngine = true;

                                    that.dataViewListToAdd.push(dataView);
                                    that.ajaxLoadDataCount += 1;
                                    if (that.ajaxLoadDataCount >= layout.length) {
                                        that.setState({dashboardInitialized: true});
                                    }
                                }
                            } else {
                                that.dataViewListToAdd.push(dataView);
                                that.ajaxLoadDataCount += 1;
                                if (that.ajaxLoadDataCount >= layout.length) {
                                    that.setState({dashboardInitialized: true});
                                }
                            }
                        }
                    }, function (error, sessionTimeout) {
                        if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                            error.message.indexOf("Cannot read property '") !== -1 &&
                            error.message.indexOf("' of undefined") !== -1) {
                            LRH.ShowToast("Your Workspace might have been deleted or moved. " +
                                "Please refresh this page to update the Control Centre.", "error", 5000);
                        } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to retrieve data for your Workspace. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                        LRH.ShowOrHideMenuLoadingProgress(false);
                        LRH.ShowUnhandledExceptionForMasterContent();
                    });
                }, null);
                that.addDataViewList();
            }, function (error, sessionTimeout) {
                if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                    error.message.indexOf("Cannot read property '") !== -1 &&
                    error.message.indexOf("' of undefined") !== -1) {
                    LRH.ShowToast("Your Workspace might have been deleted or moved. " +
                        "Please refresh this page to update the Control Centre.", "error", 5000);
                } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve data for your Workspace. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                LRH.ShowOrHideMenuLoadingProgress(false);
                LRH.ShowUnhandledExceptionForMasterContent();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Workspace might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve the layouts of your Workspace. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    addDataViewList = () => {
        let that = this;
        let count = 0;

        that.addDataViewListProcess = setInterval(() => {
            if (this.state.dashboardInitialized === true) {
                this.setState({...this.state, dataViewList: this.dataViewListToAdd}, function () {
                    LRH.ShowOrHideMenuLoadingProgress(false);
                });
                clearInterval(that.addDataViewListProcess);
            }
            count++;
            if (count >= 600) {
                LRH.ShowOrHideMenuLoadingProgress(false);
                LRH.ShowUnhandledExceptionForMasterContent();
                clearInterval(that.addDataViewListProcess);
                LRH.ShowToast("Failed to load data. Please refresh your browser and try again.", "error", 5000);
            }
        }, 100);
    };

    updateWindowDimensionsForChildView = () => {
        LRH.TriggerWindowResizeEvent();
    };

    onResizeCallback = (layout, oldItem, newItem, placeholder, e, element) => {
        let $parent = $(element).closest(".react-grid-item");
        if ($parent === null || $parent.length === 0) return;

        let $layout = $(".leopard-templateview-cell-layout", $parent);
        if ($layout === null || $layout.length === 0) return;

        let layoutId = $layout.attr("custom_attr_id").trim();
        let height = $parent.height();

        if (!LDH.IsObjectNull(layout)) {
            this.dashboardLayout = layout;
        }

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            let instanceObj = this.uiObjectInstances[i];
            if (instanceObj.instance === null) continue;
            if (instanceObj.id === layoutId && instanceObj.type === "chart") {
                instanceObj.instance.option("size", {width: "100%"});
            }

            if (instanceObj.id === layoutId && instanceObj.type === "timeline") {
                instanceObj.instance.repaint();
            }

            if (instanceObj.id === layoutId && instanceObj.type === "photo") {
                instanceObj.instance.option("height", height - 55);
            }

            if (instanceObj.id === layoutId && instanceObj.type === "notepad") {
                instanceObj.instance.option("height", height - 40);
            }

            if (instanceObj.id === layoutId && instanceObj.type === "regex-tester") {
                instanceObj.instance.option("height", height - 40);
            }

            if (instanceObj.id === layoutId && instanceObj.type === "map") {
                $("#leopard-map-control-" + instanceObj.id).resize();
            }

            if (instanceObj.id === layoutId && instanceObj.type === "datagrid") {
                if (instanceObj.optimizePagerForLargeDataset) {
                    height = height - 98;
                } else {
                    height = height - 62;
                }
                instanceObj.instance.option("height", height);
            }
        }
    };

    resizeOnAllPanels = () => {
        let that = this;
        let $root = $(".leopard-right-panel-container");
        $(".react-grid-item", $root).each(function () {
            let $handle = $(".react-resizable-handle", $(this));
            that.onResizeCallback(null, null, null, null, null, $handle);
        });
    };

    setGridViewInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            if (this.uiObjectInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiObjectInstances.push(instanceData);
        }
        this.props.setGridViewInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setChartInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            if (this.uiObjectInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiObjectInstances.push(instanceData);
        }
        this.props.setChartInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setTimelineInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            if (this.uiObjectInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiObjectInstances.push(instanceData);
        }
        this.props.setTimelineInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setPhotoInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            if (this.uiObjectInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiObjectInstances.push(instanceData);
        }
        this.props.setPhotoInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setReportInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            if (this.uiObjectInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiObjectInstances.push(instanceData);
        }
        this.props.setReportInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setNotepadInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            if (this.uiObjectInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiObjectInstances.push(instanceData);
        }
        this.props.setNotepadInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setRegexTesterInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            if (this.uiObjectInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiObjectInstances.push(instanceData);
        }
        this.props.setRegexTesterInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setMapInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            if (this.uiObjectInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiObjectInstances.push(instanceData);
        }

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setAnalyticsInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            if (this.uiObjectInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiObjectInstances.push(instanceData);
        }

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setDocumentEditorInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            if (this.uiObjectInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiObjectInstances.push(instanceData);
        }
        this.props.setDocumentEditorInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setFormEditorInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            if (this.uiObjectInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiObjectInstances.push(instanceData);
        }
        this.props.setFormEditorInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setHtmlFormBuilderInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiObjectInstances.length; i++) {
            if (this.uiObjectInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiObjectInstances.push(instanceData);
        }
        this.props.setHtmlFormBuilderInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    autoSelectOnValueChanged = (e) => {
        LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault = e.value;
    };

    initAutoSelectInstance = (e) => {
        if (!LDH.IsObjectNull(e) && !LDH.IsObjectNull(e.instance)) {
            LeopardStaticUIConfig.Global_DashboardAutoSelectInstance = e.instance;
        } else {
            LeopardStaticUIConfig.Global_DashboardAutoSelectInstance = null;
        }
    };

    renderLoadingProgressIndicator = () => {
        if (this.state.dashboardInitialized && this.dataViewListToAdd.length === 0) {
            LRH.ShowOrHideMenuLoadingProgress(false);
            return (
                <React.Fragment>
                    <div style={{textAlign: "center", marginTop: "120px", fontFamily: "Arial", fontSize: "20px"}}>
                        <i className="far fa-meh" style={{color: "#FF8200"}}></i>
                        <div className={"leopard-empty-dataview-warning"}>
                            There is no content available to display. Please assign a Data View to this Workspace
                            first.
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        LRH.ShowOrHideMenuLoadingProgress(true);
        return (
            <React.Fragment>
        <span className={"leopard-loading-icon"} style={{width: "100%", height: "100%", marginLeft: "0px"}}>
          <i className="leopard-largeloading-progress-icon fas fa-spinner fa-pulse"></i>
          <span className="leopard-largeloading-progress-text">Loading data...</span>
        </span>
            </React.Fragment>
        );
    };

    renderBlankDataViewContent = (item) => {
        return (
            <div key={item.i}>
                <div className="leopard-templateview-cell-layout" custom_attr_id={item.definition.dashboardItemId}>
                    <div className={"leopard-templateview-cell-topbar leopard-bgcolor-orange"}>
                        <div className="topmenu-arrow-indicator"></div>
                        <span className="leopard-moving-handler-text">
              Empty Data View
            </span>
                    </div>
                    <div className="leopard-templateview-cell-content">
                        <div style={{textAlign: "center", marginTop: "120px", fontFamily: "Arial", fontSize: "20px"}}>
                            <i className="far fa-meh" style={{color: "#FF8200"}}></i>
                            <div style={{
                                width: "250px", marginLeft: "auto", fontSize: "12px", color: "#7F7F7F",
                                marginRight: "auto"
                            }}>
                                Oops! This content is currently blank due to the original Data View being deleted.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    setBorderColorForGridLayout = () => {
        setTimeout(function () {
            $(".leopard-templateview-cell-topbar").each(function () {
                let color = $(this).css("background-color");
                if (LDH.IsObjectNull(color) || LDH.IsValueEmpty(color)) {
                    return;
                }
                let $parent = $(this).closest(".react-grid-item");
                $parent.css("border-color", color);
            });
        }, 100);
    };

    initializeGridViewLayout = () => {
        let that = this;

        let dataViewList = this.state.dataViewList;
        let dataView = null;
        let dataViewNote = null;
        let dataSourceUrl = null;
        let relationships = LDH.SetRelationshipColorBar(this.state.relationships);

        return this.state.templateLayout.map(function (item) {
            if (item.definition.dashboardType === "datagrid") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let j = 0; j < dataViewList.length; j++) {
                    let def = dataViewList[j].dataViewNote.gridViewDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[j];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }
                dataViewNote = dataView.dataViewNote;

                let gridDefinition = dataViewNote.gridViewDefinition;
                dataSourceUrl = "?tableName=" + dataView.dataSourceUrl;

                if (!LDH.IsObjectNull(gridDefinition.overrideDatabaseTableField) &&
                    !LDH.IsValueEmpty(gridDefinition.overrideDatabaseTableField)) {
                    dataSourceUrl = "?tableName=" + gridDefinition.overrideDatabaseTableField;
                }

                let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");
                LRH.ApplyCustomStyle(dataViewNote.styleDefinition, version);

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let v = 0; v < relationships.length; v++) {
                    if (relationships[v].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[v].color;
                        level = relationships[v].level;
                    }
                    if (relationships[v].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[v].color;
                        level = relationships[v].level + 1;
                    }
                }
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout gridview"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content dataview_" + item.definition.dashboardItemId}>
                                {
                                    <LeopardChildGridView
                                        gridViewId={item.definition.dashboardItemId}
                                        gridDefinition={gridDefinition}
                                        gridViewName={dataView.dataViewName}
                                        gridViewHeight={"auto"}
                                        dashboardLevel={level}
                                        viewOptionsText={dataView.dataViewName}
                                        setGridViewInstance={that.setGridViewInstance}
                                        dataSourceUrl={dataSourceUrl}
                                        relationships={that.state.relationships}
                                        columnFieldList={dataView.columnFieldList}
                                        isGridViewJSONEngine={dataView.isGridViewJSONEngine}
                                        staticJSONData={dataView.staticJSONData}
                                        dashboardId={that.props.menuItem.dashboardId}
                                        dashboardLayoutDataViewMapping={that.dashboardLayoutDataViewMapping}
                                        requireReloadDataView={(e) => that.requireReloadDataView(e)}
                                    />
                                }
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "chart") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let k = 0; k < dataViewList.length; k++) {
                    let def = dataViewList[k].dataViewNote.chartDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[k];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;
                for (let b = 0; b < relationships.length; b++) {
                    if (relationships[b].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[b].color;
                        level = relationships[b].level;
                    }
                    if (relationships[b].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[b].color;
                        level = relationships[b].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                dataSourceUrl = window.GetDataTableByName + "?tableName=" + dataView.dataSourceUrl;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout chart"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content hidescrollbar dataview_" + item.definition.dashboardItemId}>
                                <LeopardChildChart
                                    dataSourceUrl={dataSourceUrl} definition={dataViewNote}
                                    palette={['#c3a2cc', '#b7b5e0', '#e48cba']}
                                    customizeTextYAxis={"#value#"}
                                    dashboardLevel={level}
                                    seriesType={"bar"} useStateStore={true}
                                    dataViewId={item.definition.dashboardItemId}
                                    chartType={dataViewNote.chartType}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsForChildView}
                                    setChartInstance={that.setChartInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "timeline") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let k = 0; k < dataViewList.length; k++) {
                    let def = dataViewList[k].dataViewNote.timelineDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[k];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;
                for (let b = 0; b < relationships.length; b++) {
                    if (relationships[b].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[b].color;
                        level = relationships[b].level;
                    }
                    if (relationships[b].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[b].color;
                        level = relationships[b].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                dataSourceUrl = window.GetDataTableByName + "?tableName=" + dataView.dataSourceUrl;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout timeline"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content hidescrollbar dataview_" + item.definition.dashboardItemId}>
                                <LeopardChildTimeline
                                    definition={dataViewNote}
                                    dashboardLevel={level}
                                    useStateStore={true}
                                    dataViewId={item.definition.dashboardItemId}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsForChildView}
                                    setTimelineInstance={that.setTimelineInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "tabbed-view") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let k = 0; k < dataViewList.length; k++) {
                    let def = dataViewList[k].dataViewNote.tabbedViewDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[k];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;
                for (let b = 0; b < relationships.length; b++) {
                    if (relationships[b].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[b].color;
                        level = relationships[b].level;
                    }
                    if (relationships[b].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[b].color;
                        level = relationships[b].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                dataSourceUrl = window.GetDataTableByName + "?tableName=" + dataView.dataSourceUrl;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout timeline"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content hidescrollbar dataview_" + item.definition.dashboardItemId}>
                                <LeopardChildTabbedView definition={dataViewNote} dashboardLevel={level}
                                                        useStateStore={true}
                                                        dataViewId={item.definition.dashboardItemId}
                                                        relationships={that.state.relationships}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "photo") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let q = 0; q < dataViewList.length; q++) {
                    let def = dataViewList[q].dataViewNote.photoDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[q];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let w = 0; w < relationships.length; w++) {
                    if (relationships[w].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level;
                    }
                    if (relationships[w].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout photo"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content dataview_" + item.definition.dashboardItemId}>
                                <LeopardChildAttachableWidget
                                    definition={dataViewNote} dashboardLevel={level}
                                    useStateStore={true}
                                    dashboardItemType={item.definition.dashboardType}
                                    dataViewId={item.definition.dashboardItemId}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsForChildView}
                                    setReportInstance={that.setReportInstance}
                                    setPhotoInstance={that.setPhotoInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "notepad") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let q = 0; q < dataViewList.length; q++) {
                    let def = dataViewList[q].dataViewNote.notepadDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[q];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let w = 0; w < relationships.length; w++) {
                    if (relationships[w].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level;
                    }
                    if (relationships[w].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout notepad"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content dataview_" + item.definition.dashboardItemId}>
                                <LeopardChildAttachableWidget
                                    definition={dataViewNote} dashboardLevel={level}
                                    useStateStore={true}
                                    dashboardItemType={item.definition.dashboardType}
                                    dataViewId={item.definition.dashboardItemId}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsForChildView}
                                    setNotepadInstance={that.setNotepadInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "regex-tester") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let q = 0; q < dataViewList.length; q++) {
                    let def = dataViewList[q].dataViewNote.regexDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[q];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let w = 0; w < relationships.length; w++) {
                    if (relationships[w].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level;
                    }
                    if (relationships[w].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout regex-tester"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content dataview_" + item.definition.dashboardItemId}>
                                <LeopardChildRegexTester
                                    definition={dataViewNote} dashboardLevel={level}
                                    useStateStore={true}
                                    dashboardItemType={item.definition.dashboardType}
                                    dataViewId={item.definition.dashboardItemId}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsForChildView}
                                    setRegexTesterInstance={that.setRegexTesterInstance}>
                                </LeopardChildRegexTester>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "map") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let q = 0; q < dataViewList.length; q++) {
                    let def = dataViewList[q].dataViewNote.mapDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[q];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let w = 0; w < relationships.length; w++) {
                    if (relationships[w].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level;
                    }
                    if (relationships[w].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout map"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content dataview_" + item.definition.dashboardItemId}>
                                <LeopardChildMap
                                    definition={dataViewNote} dashboardLevel={level} useStateStore={true}
                                    dataViewId={item.definition.dashboardItemId} isDataView={false}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsForChildView}
                                    setMapInstance={that.setMapInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "aws-quicksight-dashboard") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let q = 0; q < dataViewList.length; q++) {
                    if (LDH.IsObjectNull(dataViewList[q])) continue;
                    if (dataViewPersistentId === dataViewList[q].dataViewPersistentId) {
                        dataView = dataViewList[q];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let w = 0; w < relationships.length; w++) {
                    if (relationships[w].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level;
                    }
                    if (relationships[w].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout analytics-dashboard"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content dataview_" + item.definition.dashboardItemId}>
                                <LeopardChildAnalyticsDashboard
                                    definition={dataViewNote} dashboardLevel={level} useStateStore={true}
                                    dataViewId={item.definition.dashboardItemId} isDataView={false}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsForChildView}
                                    setAnalyticsInstance={that.setAnalyticsInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "document-editor") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let q = 0; q < dataViewList.length; q++) {
                    let def = dataViewList[q].dataViewNote.documentDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[q];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let w = 0; w < relationships.length; w++) {
                    if (relationships[w].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level;
                    }
                    if (relationships[w].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout document-editor"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content hide-scrollbar-horizontal-vertical dataview_" + item.definition.dashboardItemId}>
                                <LeopardDocumentEditorConfiguration
                                    definition={dataViewNote} dashboardLevel={level} useStateStore={true}
                                    viewType={"workspace"} isDataView={false}
                                    dashboardItemType={item.definition.dashboardType}
                                    dataViewId={item.definition.dashboardItemId}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsForChildView}
                                    setDocumentEditorInstance={that.setDocumentEditorInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "html-form-builder") {
                dataView = null;
                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let q = 0; q < dataViewList.length; q++) {
                    let def = dataViewList[q].dataViewNote.formDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[q];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let w = 0; w < relationships.length; w++) {
                    if (relationships[w].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level;
                    }
                    if (relationships[w].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout html-form-builder"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content hide-scrollbar-horizontal-vertical dataview_" + item.definition.dashboardItemId}>
                                <LeopardChildHtmlFormBuilder
                                    formDefinition={dataViewNote.formDefinition}
                                    dashboardLevel={level}
                                    useStateStore={true}
                                    dataViewId={item.definition.dashboardItemId}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsForChildView}
                                    setHtmlFormBuilderInstance={that.setHtmlFormBuilderInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "form-editor") {
                dataView = null;
                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let q = 0; q < dataViewList.length; q++) {
                    let def = dataViewList[q].dataViewNote.formDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[q];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let w = 0; w < relationships.length; w++) {
                    if (relationships[w].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level;
                    }
                    if (relationships[w].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout form-editor"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content html-form-builder hide-scrollbar-horizontal-vertical dataview_" + item.definition.dashboardItemId}>
                                <LeopardFormEditorConfiguration
                                    formDefinition={dataViewNote.formDefinition} dashboardLevel={level}
                                    useStateStore={true}
                                    viewType={"workspace"} isDataView={false}
                                    dashboardItemType={item.definition.dashboardType}
                                    dataViewId={item.definition.dashboardItemId}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsForChildView}
                                    setFormEditorInstance={that.setFormEditorInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "html-form-builder") {
                dataView = null;
                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let q = 0; q < dataViewList.length; q++) {
                    let def = dataViewList[q].dataViewNote.formDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[q];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let w = 0; w < relationships.length; w++) {
                    if (relationships[w].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level;
                    }
                    if (relationships[w].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout form-editor"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div
                                className={"leopard-templateview-cell-content hide-scrollbar-horizontal-vertical dataview_" + item.definition.dashboardItemId}>
                                <div>hello world!</div>
                            </div>
                        </div>
                    </div>);
            }

            return (<div key={item.i}></div>);
        });
    };

    layoutButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Layouts_" + data.dashboardId).hasClass("leopard-ui-disabled")) {
            return;
        }
        this.uiObjectInstances["layoutPopoverControl"].option("visible", true);
    };

    setComponentInstance = (data) => {
        if (LDH.IsObjectNull(data.e) || LDH.IsObjectNull(data.e.instance)) return;
        this.uiObjectInstances[data.componentName] = data.e.instance;
    }

    addDisposablePopupInstances = (e) => {
        for (let i = 0; i < e.length; i++) {
            this.disposablePopupInstances.push(e[i]);
        }
    };

    requireReloadDataView = (data) => {
        if ((!LDH.IsObjectNull(data) && (data.isDashboardLayout &&
                !LDH.IsValueEmpty(data.dashboardId))) ||
            (typeof data === "string" && data === "refresh-dashboard")) {
            let menuItemId = this.props.menuItem.menuItemId;
            let $menu = $("#menuitem_dashboard_" + menuItemId);
            $(".leopard-leftmenu-item-text", $menu).trigger("click");
            return;
        }
        let foundDataView = false;
        for (let i = 0; i < this.dashboardLayoutDataViewMapping.length; i++) {
            let mapping = this.dashboardLayoutDataViewMapping[i];
            if (mapping.dataViewId === data.dataViewId &&
                mapping.dataViewPersistentId === data.dataViewPersistentId) {
                mapping.layoutId = data.layoutId;
                foundDataView = true;
                break;
            }
        }
        if (foundDataView === false) {
            this.dashboardLayoutDataViewMapping.push({
                dataViewPersistentId: data.dataViewPersistentId,
                dataViewId: data.dataViewId,
                layoutId: data.layoutId
            });
        }
    };

    deleteLayoutOnClick = (data) => {
        let that = this;

        LRH.ShowDialog("Are you sure you want to delete this layout? <br/><b>" + data.layoutName + "</b>", "Confirm Delete", function () {
            let layoutId = data.layoutId;
            let dataViewPersistentId = data.dataViewPersistentId;

            for (let i = 0; i < data.layoutConfig.layouts.length; i++) {
                let layout = data.layoutConfig.layouts[i];
                if (layout.layoutId === layoutId) {
                    data.layoutConfig.layouts.splice(i, 1);
                    break;
                }
            }

            let deleteSelectedLayout = false;
            if (layoutId === data.layoutConfig.selectedLayoutId) {
                data.layoutConfig.selectedLayoutId = "";
                deleteSelectedLayout = true;
            }

            let userId = LDH.GetUserIdFromUserProfile(window.userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(window.userProfile);
            LeopardAjaxHelper.UpdateDataViewLayoutById(userId, organizationId, dataViewPersistentId,
                data.layoutConfig, function () {
                    if (deleteSelectedLayout) that.requireReloadDataView("refresh-dashboard");
                    LRH.ShowToast("Your layout has been successfully deleted.", "success", 5000);
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to save your layout.", "error", 5000);
                    }
                });
        });
    };

    saveLayoutOnClick = (data) => {
        let that = this;

        if (this.dashboardLayout === null || this.dashboardLayout.length === 0) {
            LRH.ShowToast("You have not made any changes to the Workspace layout.", "warning", 5000);
            return;
        }

        let hasCustomLayouts = false;
        if (LDH.IsObjectNull(that.dashboardLayoutDataViewMapping)) {
            that.dashboardLayoutDataViewMapping = [];
        }
        for (let i = 0; i < that.dashboardLayoutDataViewMapping.length; i++) {
            if (!LDH.IsValueEmpty(that.dashboardLayoutDataViewMapping[i].layoutId)) {
                hasCustomLayouts = true;
            }
        }
        if (LDH.IsValueEmpty(data.layoutId) && hasCustomLayouts) {
            LRH.ShowToast("Validation failed. Please create a new Workspace layout " +
                "or change all the Data View layouts to default.", "error", 5000);
            return;
        }

        let dashboardId = this.props.menuItem.dashboardId;
        let userId = LDH.GetUserIdFromUserProfile(window.userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(window.userProfile);

        LeopardAjaxHelper.GetDashboardDefinitionInsideLayout(userId, organizationId, dashboardId,
            this.dashboardLayout, function (response) {
                let layoutId = data.layoutId;
                if (!LDH.IsObjectNull(data.layoutId) && !LDH.IsValueEmpty(data.layoutId)) {
                    let layoutConfig = data.layoutConfig;
                    let dataViewPersistentId = data.dataViewPersistentId;

                    for (let i = 0; i < layoutConfig.layouts.length; i++) {
                        let layout = layoutConfig.layouts[i];
                        if (layout.layoutId === layoutId) {
                            layout.layoutData = response.dashboardLayout;
                            layout.dataViewMapping = that.dashboardLayoutDataViewMapping;
                        }
                    }
                    LeopardAjaxHelper.UpdateDataViewLayoutById(userId, organizationId, dataViewPersistentId,
                        layoutConfig, function () {
                            LRH.ShowToast("Your layout has been successfully saved.", "success", 5000);
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to save your layout.", "error", 5000);
                            }
                        });
                } else {
                    $(".leopard-savelayout-loading").css("visibility", "visible");
                    setTimeout(() => {
                        let userProfile = window.userProfile;
                        let userId = LDH.GetUserIdFromUserProfile(userProfile);
                        let dashboardId = that.props.menuItem.dashboardId;
                        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
                        LRH.ConsoleLog(that, "LeopardTemplateView_OnButtonSaveLayoutOnClick", that.dashboardLayout);

                        LeopardAjaxHelper.UpdateDashboardLayout(userId, organizationId,
                            dashboardId, that.dashboardLayout, function () {
                                let settingsVersionOnClient = parseInt($(".dashboard-settings-version").attr("settingsversion"));
                                $(".dashboard-settings-version").attr("settingsversion", settingsVersionOnClient + 1);

                                LRH.ShowToast("Your Workspace layout has been successfully saved.", "success", 5000);
                                LRH.ConsoleLog(that, "LeopardTemplateView_SaveLayout_Success", true);
                                $(".leopard-savelayout-loading").css("visibility", "hidden");
                            }, function (error, sessionTimeout) {
                                if (error === "version-out-of-date") {
                                    LRH.ShowStaticToast("Configuration outdated", "Your current configuration settings cannot be " +
                                        "saved due to a newer version found on the server, please refresh your " +
                                        "browser. ", "error", true);
                                } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                } else {
                                    LRH.ShowToast("Failed to save your Workspace layout.", "error", 5000);
                                }
                                LRH.ConsoleLog(that, "LeopardTemplateView_SaveLayout_Failed", error);
                                $(".leopard-savelayout-loading").css("visibility", "hidden");
                            });
                    }, 100);
                }
            }, null);
    };

    createLayoutOnClick = (data) => {
        let that = this;
        if (this.dashboardLayout === null || this.dashboardLayout.length === 0) {
            LRH.ShowToast("You have not made any changes to the Workspace layout.", "warning", 5000);
            return;
        }
        let dashboardId = this.props.menuItem.dashboardId;
        let userId = LDH.GetUserIdFromUserProfile(window.userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(window.userProfile);
        LeopardAjaxHelper.GetDashboardDefinitionInsideLayout(userId, organizationId, dashboardId,
            this.dashboardLayout, function (response) {
                let layoutConfig = data.layoutConfig;
                if (LDH.IsObjectNull(layoutConfig) || LDH.IsObjectNull(layoutConfig.selectedLayoutId)) {
                    layoutConfig = {selectedLayoutId: "", layouts: []};
                }

                let dataViewPersistentId = data.dataViewPersistentId;
                let dataViewId = data.dataViewId;

                layoutConfig.layouts.push({
                    dataViewPersistentId: dataViewPersistentId,
                    dataViewId: dataViewId,
                    layoutName: data.layoutName,
                    layoutId: data.layoutId,
                    layoutVersion: 1,
                    layoutData: response.dashboardLayout,
                    isDashboard: true,
                    dataViewMapping: that.dashboardLayoutDataViewMapping
                });
                layoutConfig.selectedLayoutId = data.layoutId;

                LeopardAjaxHelper.UpdateDataViewLayoutById(userId, organizationId, dataViewPersistentId,
                    layoutConfig, function () {
                        LRH.ShowToast("Your layout has been successfully created.", "success", 5000);
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to create your layout.", "error", 5000);
                        }
                    });
            }, null);
    };

    renameLayoutOnClick = (data) => {
        let layoutId = data.layoutId;
        let layoutConfig = data.layoutConfig;
        let dataViewPersistentId = data.dataViewPersistentId;

        for (let i = 0; i < layoutConfig.layouts.length; i++) {
            let layout = layoutConfig.layouts[i];
            if (layout.layoutId === layoutId) {
                layout.layoutName = data.layoutName;
            }
        }

        let userId = LDH.GetUserIdFromUserProfile(window.userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(window.userProfile);
        LeopardAjaxHelper.UpdateDataViewLayoutById(userId, organizationId, dataViewPersistentId,
            layoutConfig, function () {
                LRH.ShowToast("Your layout has been successfully renamed.", "success", 5000);
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to rename your layout.", "error", 5000);
                }
            });
    };

    render() {
        if (this.state.dataViewList !== null && this.state.dataViewList.length > 0 &&
            this.state.templateLayout !== null && this.state.dashboardInitialized === true) {
            let dashboardId = this.props.menuItem.dashboardId;

            setTimeout(function () {
                let focused = false;
                $(".autofocus_field input.dx-texteditor-input").each(function () {
                    if (focused) return;
                    $(this).focus();
                    focused = true;
                });
            }, 1000);

            let autoManualMode = "auto-mode";
            if (!LDH.IsObjectNull(this.props.menuItem.autoManualMode) && !LDH.IsValueEmpty(this.props.menuItem.autoManualMode)) {
                autoManualMode = this.props.menuItem.autoManualMode;
            }
            LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault = autoManualMode === "auto-mode" ? true : false;

            return (
                <React.Fragment>
                    <div style={{height: "26px", position: "relative", top: "-5px"}}>
                        <span style={{
                            padding: "0 15px 0 10px",
                            display: (autoManualMode === "hidden" ? "none" : "inline")
                        }}>
                                 <Switch width={"100px"} switchedOffText={"MANUAL MODE"}
                                         defaultValue={autoManualMode === "auto-mode" ? true : false}
                                         switchedOnText={"AUTO MODE"} ref={(e) => this.initAutoSelectInstance(e)}
                                         onValueChanged={(e) => this.autoSelectOnValueChanged(e)}
                                 />
                        </span>
                        <span style={{padding: "0 10px 0 0"}}>
                                     <LeopardTooltipWithLink
                                         elementId={"GridView_TopBar_Layouts_" + dashboardId}
                                         labelText={"Layouts"} width={250} title={""} text={""}
                                         onClick={(e) => this.layoutButtonOnClick({e: e, dashboardId: dashboardId})}
                                     />
                                </span>
                        <span className={"leopard-savelayout-loading leopard-loading-icon"} style={{
                            marginLeft: "0px", position: "relative", top: "5px", float: "none",
                            visibility: "hidden"
                        }}>
                                  <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                                </span>
                    </div>
                    <ReactGridLayout className="layout" layout={this.state.templateLayout} cols={216} rowHeight={1}
                                     autoSize={true} isDraggable={true}
                                     draggableHandle={".leopard-templateview-cell-topbar"}
                                     onDragStop={this.onResizeCallback} onResize={this.onResizeCallback}
                                     onResizeStop={this.onResizeCallback} useCSSTransforms={false}>
                        {this.initializeGridViewLayout()}
                    </ReactGridLayout>

                    <LeopardLayoutSelectionPopup
                        dataViewPersistentId={dashboardId} dataViewId={dashboardId}
                        setComponentInstance={(e) => this.setComponentInstance(e)}
                        saveLayoutOnClick={(e) => this.saveLayoutOnClick(e)}
                        deleteLayoutOnClick={(e) => this.deleteLayoutOnClick(e)}
                        createLayoutOnClick={(e) => this.createLayoutOnClick(e)}
                        renameLayoutOnClick={(e) => this.renameLayoutOnClick(e)}
                        requireReloadDataView={(e) => this.requireReloadDataView(e)}
                        dashboardId={dashboardId} isDashboardLayout={true}
                        addDisposablePopupInstances={(e) => this.addDisposablePopupInstances(e)}>
                    </LeopardLayoutSelectionPopup>
                </React.Fragment>
            );
        }
        return this.renderLoadingProgressIndicator();
    }
}

const ReactGridLayout = WidthProvider(RGL);

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardTemplateView);
