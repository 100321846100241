import React from 'react';
import $ from 'jquery';
import convert from 'xml-js';
import moment from 'moment';
import momentDurationFormatSetup from "moment-duration-format";
import 'devextreme/data/odata/store';
import ODataStore from 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import {confirm} from 'devextreme/ui/dialog';
import {API} from "aws-amplify";
import LDH from './LeopardDataHelper';
import LRH from './LeopardReactHelper';
import notify from 'devextreme/ui/notify';
import LeopardSecurity from '../security/LeopardSecurity';
import LeopardAjaxHelper from "./LeopardAjaxHelper";
import DateBox from "devextreme-react/date-box";
import {Autocomplete} from 'devextreme-react/autocomplete';
import {NumberBox, SelectBox, TagBox, TextBox} from 'devextreme-react';
import {Toast} from "bootstrap";
import LeopardPermissionHelper from "./LeopardPermissionHelper";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import saveAs from 'file-saver';

class LeopardReactHelper extends React.Component {
    constructor(props) {
        super(props);

        momentDurationFormatSetup(moment);
        if (typeof moment.duration.fn.format !== "function") {
            console.log("moment-duratio-format not supported.");
        }
        if (typeof moment.duration.format !== "function") {
            console.log("moment-duratio-format not supported.");
        }
    }

    static ConsoleLog = (comp, name, args) => {
        if (localStorage.getItem("ConsoleLog") === "lite") {
            console.log(name, args);
        } else if (localStorage.getItem("ConsoleLog") === "detail") {
            console.log(name, args, "##### Component #####", comp);
        }
    };

    static ShowToast = (text, type, displayTime) => {
        notify(text, type, displayTime);
    };

    static ShowStaticToast = (text, description, type, isShow) => {
        $(".leopard-static-toast .toast-title").text(text);
        $(".leopard-static-toast .toast-description").text(description);
        $(".leopard-static-toast").removeClass("error");
        $(".leopard-static-toast").addClass(type);

        var toast = new Toast($('.leopard-static-toast').get(0),
            {animation: true, autohide: false});

        toast.hide();
        setTimeout(function () {
            if (isShow) {
                toast.show();
            } else {
                toast.hide();
            }
        }, 500);
    };

    static ShowDialog = (text, title, callbackOK, callbackCancel) => {
        confirm(text, title).then((dialogResult) => {
            if (dialogResult === true) {
                callbackOK();
            }
            if (!LDH.IsObjectNull(callbackCancel)) {
                callbackCancel();
            }
        });
    };

    static ShowOrHideMenuLoadingProgress = (show) => {
        if (show) {
            $(".leopard-screen-menucover").show();
        } else {
            $(".leopard-screen-menucover").hide();
        }
    };

    static ShowUnhandledExceptionForMasterContent = () => {
        $(".leopard-right-panel-container .leopard-loading-icon").hide();
    };

    static ShowOrHideApplicationBackgroundCover = (show) => {
        if (show) {
            setTimeout(function () {
                $(".leopard-screen-cover").show();
            }, 100);
        } else {
            setTimeout(function () {
                $(".leopard-screen-cover").hide();
            }, 100);
        }
    };

    static exportToExcelLogic = (gridViewInstance, fileName, gridDefinition, dataViewId) => {
        let fileType = "application/octet-stream";
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Workbook');
        exportDataGrid({
            component: gridViewInstance,
            worksheet: worksheet,
            topLeftCell: {row: 1, column: 1},
            customizeCell: (options) => {
                const {gridCell, excelCell} = options;
                if (gridCell.rowType === 'data' &&
                    !LDH.IsObjectNull(gridDefinition.dataExportJavaScript) &&
                    !LDH.IsValueEmpty(gridDefinition.dataExportJavaScript)) {
                    let javascript = gridDefinition.dataExportJavaScript;
                    let dataName = "parentRowData";
                    let dataValue = "";
                    let excelCellId = LDH.GenerateGuid();
                    window["excelCell_" + excelCellId] = excelCell;
                    LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                        dataValue, dataViewId, {
                            gridCell: gridCell, excelCellId: excelCellId
                        });
                    delete window["excelCell_" + excelCellId];
                }
            }
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], {type: fileType}), fileName);
            });
        });
    };

    static exportToCsvLogic = (gridViewInstance, fileName, gridDefinition, dataViewId) => {
        let fileType = "application/octet-stream";
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Workbook');
        exportDataGrid({
            component: gridViewInstance,
            worksheet: worksheet,
            topLeftCell: {row: 1, column: 1},
            customizeCell: (options) => {
                const {gridCell, excelCell} = options;
                if (gridCell.rowType === 'data' &&
                    !LDH.IsObjectNull(gridDefinition.dataExportJavaScript) &&
                    !LDH.IsValueEmpty(gridDefinition.dataExportJavaScript)) {
                    let javascript = gridDefinition.dataExportJavaScript;
                    let dataName = "parentRowData";
                    let dataValue = "";
                    let excelCellId = LDH.GenerateGuid();
                    window["excelCell_" + excelCellId] = excelCell;
                    LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                        dataValue, dataViewId, {
                            gridCell: gridCell, excelCellId: excelCellId
                        });
                    delete window["excelCell_" + excelCellId];
                }
            }
        }).then(function () {
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], {type: fileType}), fileName);
            });
        });
    };

    static BindValidationRulesToUIObject = (data, uiObjectInstances) => {
        let validationResult = true;
        let defaultBlankValue = data.defaultBlankValue;
        let instance = uiObjectInstances[data.input];

        if (LDH.IsObjectNull(instance)) {
            return validationResult;
        }
        if (instance.option("visible") === false) {
            return validationResult;
        }

        for (let i = 0; i < data.rules.length; i++) {
            let treatZeroAsNull = false;

            if (data.input === "validationInput") {
                validationResult = true;
                break;
            }

            if (data.input === "selectDataSourceInput") {
                treatZeroAsNull = true;
            }

            if (data.rules[i].rule === "required" || data.rules[i].rule === "[required]") {
                data.e.rule.message = "This field cannot be blank";
                if (LDH.IsValueEmpty(defaultBlankValue) === false) {
                    if (data.e.value === defaultBlankValue) {
                        validationResult = false;
                        break;
                    }
                } else {
                    if (LDH.IsValueEmpty(data.e.value) === true ||
                        (treatZeroAsNull && data.e.value === 0)) {
                        validationResult = false;
                        break;
                    }
                }
            }

            if (data.rules[i].rule === "safeinput" || data.rules[i].rule === "[safeinput]") {
                data.e.rule.message = "This value is not safe";
                if (LeopardSecurity.IsInputValueDangerous(data.e.value)) {
                    validationResult = false;
                    break;
                }
            }

            if (data.rules[i].rule === "regex" || data.rules[i].rule === "[regex]") {
                data.e.rule.message = "This regular expression is invalid";
                var isValid = true;
                try {
                    new RegExp(data.e.value);
                } catch (ex) {
                    isValid = false;
                }
                if (!isValid) {
                    validationResult = false;
                    break;
                }
            }

            if (data.rules[i].rule === "regex-in-test" || data.rules[i].rule === "[regex-in-test]") {
                data.e.rule.message = "This value does not match the regex";
                var isValid = true;

                try {
                    var regexInRegEx = new RegExp(data.values.regexIn);
                    let result = regexInRegEx.test(data.e.value);
                    if (!result) isValid = false;
                } catch (ex) {
                    isValid = false;
                }
                if (!isValid) {
                    validationResult = false;
                    break;
                }
            }

            if (data.rules[i].rule === "emailaddress-list" || data.rules[i].rule === "[emailaddress-list]") {
                data.e.rule.message = "Invalid email address detected";
                let emails = data.e.value;
                if (LDH.IsValueEmpty(emails)) {
                    emails = "";
                }
                let split = emails.split(";");
                for (let v = 0; v < split.length; v++) {
                    let email = split[v].trim();
                    if (LDH.IsValueEmpty(email)) continue;
                    if (!LDH.IsValidEmailAddress(email)) {
                        validationResult = false;
                        break;
                    }
                }
            }
        }
        return validationResult;
    };

    static BindValidationRulesToUIObjectLite = (data, uiObjectInstances, parentClass) => {
        let validationResult = true;
        let instance = uiObjectInstances[data.inputFieldName];

        if (LDH.IsObjectNull(instance)) {
            return validationResult;
        }
        if (instance.option("visible") === false) {
            return validationResult;
        }

        if (!LDH.IsObjectNull(parentClass) && !LDH.IsValueEmpty(parentClass)) {
            let $parent = instance._$element.closest(parentClass);
            if (!$parent.is(":visible")) {
                return validationResult;
            }
        }

        if (data.regex === "[required]" || data.regex === "required") {
            data.e.rule.message = data.error;
            if (LDH.IsObjectNull(data.e.value) ||
                LDH.IsValueEmpty(data.e.value)) {
                validationResult = false;
            }
        }

        if (typeof data.validationType !== "undefined" && data.validationType === "reference-data") {
            validationResult = LRH.ValidateValueByRegExList(data);
        }
        return validationResult;
    };

    static ValidateValueByRegExList(data) {
        let validationResult = true;
        data.e.rule.message = data.error;
        var isValid = false;
        if (data.validationCondition !== "any") isValid = true;

        try {
            let datasources = data.validationDataSource;
            let vProp = data.valueProperty;
            if (datasources === null) datasources = [];
            for (let i = 0; i < datasources.length; i++) {
                if (data.validationCondition !== "any" && !isValid) continue;

                var regexInRegEx = new RegExp(datasources[i][vProp]);
                let result = regexInRegEx.test(data.e.value);

                if (data.validationCondition === "any") {
                    if (result) isValid = true;
                } else {
                    if (!result) isValid = false;
                }
            }
            if (!isValid) validationResult = false;
        } catch (ex) {
            validationResult = false;
        }
        return validationResult;
    };

    static SendAjaxForRetriveXmlFromReportDB(url, httpType, successFunc, errorFunc) {
        url = LDH.APIEndpointAdapter() + url;
        return $.ajax({
            type: httpType, url,
            error(error) {
                if (!LDH.IsObjectNull(errorFunc)) {
                    return errorFunc(error);
                }
                return null;
            }
        }).then(function (data) {
            let outerXml = data.documentElement.outerHTML;
            if (LDH.IsObjectNull(outerXml) && LDH.IsValueEmpty(outerXml)) {
                // This is a workaround for IE9+ support.
                outerXml = new XMLSerializer().serializeToString(data.documentElement);
            }
            let result = convert.xml2json(outerXml, {compact: false, spaces: 4});
            let resultJSON = JSON.parse(result);
            if (!LDH.IsObjectNull(successFunc)) {
                return successFunc(resultJSON);
            }
            return resultJSON;
        });
    }

    static SendAjaxRequestForCharts(url, httpType, linqQuery, successFunc, errorFunc,
                                    dataViewId, chartDefinition, thisComp, httpDataPost) {
        return API[httpType.toLowerCase()]("aws-api-gateway", url, {
            headers: LeopardAjaxHelper.GenericRequestHeaders(),
            body: httpDataPost
        }).then(data => {
            if (LeopardAjaxHelper.HandleSessionTimeoutEvent(data)) {
                return [];
            }
            if (LDH.IsObjectNull(data) || LDH.IsObjectNull(data.value) ||
                Array.isArray(data.value) === false) {
                let rawDataToInsert = data;
                data = {};
                data["value"] = rawDataToInsert;
            }
            if (!LDH.IsObjectNull(data.value) && !LDH.IsObjectNull(data.value.sqlMessage) &&
                !LDH.IsValueEmpty(data.value.sqlMessage)) {
                errorFunc("", "");
                return [];
            }
            if (!LDH.IsObjectNull(thisComp) && thisComp.disposingAllInstances) {
                return [];
            }
            if (LDH.IsTimeoutReceivedFromAPIGateway(data) === true) {
                if (!LDH.IsObjectNull(errorFunc)) {
                    errorFunc(data, "retry");
                }
                return [];
            }
            if (!LDH.IsObjectNull(chartDefinition) && !LDH.IsValueEmpty(dataViewId) &&
                !LDH.IsObjectNull(chartDefinition.maximumDataThreshold) &&
                !LDH.IsValueEmpty(chartDefinition.maximumDataThreshold) &&
                chartDefinition.maximumDataThreshold > 0 && !LDH.IsObjectNull(data) &&
                data.value.length >= chartDefinition.maximumDataThreshold) {
                $("#Chart_TopBar_WarningIcon_" + dataViewId).show();
            } else {
                $("#Chart_TopBar_WarningIcon_" + dataViewId).hide();
            }
            if (LDH.IsValueEmpty(linqQuery) === false && !LDH.IsObjectNull(data) &&
                !LDH.IsObjectNull(data.value)) {
                LRH.ExecuteClientScript(data, linqQuery, "data", true);
            }
            if (LDH.IsObjectNull(data.value) === false) {
                if (!LDH.IsObjectNull(successFunc)) {
                    successFunc(data.value, data);
                }
                return data.value;
            } else {
                if (!LDH.IsObjectNull(errorFunc)) {
                    errorFunc(data, "");
                }
                return [];
            }
        }).catch(error => {
            if (!LDH.IsObjectNull(thisComp) && thisComp.disposingAllInstances) {
                return [];
            }
            if (LeopardAjaxHelper.HandleSessionTimeoutEvent(error)) {
                return [];
            }
            if (LDH.IsTimeoutReceivedFromAPIGateway(error) === true) {
                if (!LDH.IsObjectNull(errorFunc)) {
                    errorFunc(error, "retry");
                }
            } else {
                if (!LDH.IsObjectNull(errorFunc)) {
                    errorFunc(error, "");
                }
            }
            return [];
        });
    }

    static SendAjaxRequestForTimeline(url, httpType, linqQuery, successFunc, errorFunc,
                                      dataViewId, timelineDefinition, thisComp) {
        return API[httpType.toLowerCase()]("aws-api-gateway", url, {
            headers: LeopardAjaxHelper.GenericRequestHeaders()
        }).then(data => {
            if (LeopardAjaxHelper.HandleSessionTimeoutEvent(data)) {
                return [];
            }
            if (LDH.IsObjectNull(data) || LDH.IsObjectNull(data.value) ||
                Array.isArray(data.value) === false) {
                let rawDataToInsert = data;
                data = {};
                data["value"] = rawDataToInsert;
            }
            if (!LDH.IsObjectNull(data.value) && !LDH.IsObjectNull(data.value.sqlMessage) &&
                !LDH.IsValueEmpty(data.value.sqlMessage)) {
                errorFunc("", "", thisComp);
                return [];
            }
            if (!LDH.IsObjectNull(thisComp) && thisComp.disposingAllInstances) {
                return [];
            }
            if (LDH.IsTimeoutReceivedFromAPIGateway(data) === true) {
                if (!LDH.IsObjectNull(errorFunc)) {
                    errorFunc(data, "retry", thisComp);
                }
                return [];
            }
            if (!LDH.IsObjectNull(timelineDefinition) && !LDH.IsValueEmpty(dataViewId) &&
                !LDH.IsObjectNull(timelineDefinition.maximumDataThreshold) &&
                !LDH.IsValueEmpty(timelineDefinition.maximumDataThreshold) &&
                timelineDefinition.maximumDataThreshold > 0 && !LDH.IsObjectNull(data) &&
                data.value.length >= timelineDefinition.maximumDataThreshold) {
                $("#Timeline_TopBar_WarningIcon_" + dataViewId).show();
            } else {
                $("#Timeline_TopBar_WarningIcon_" + dataViewId).hide();
            }

            if (LDH.IsValueEmpty(linqQuery) === false && !LDH.IsObjectNull(data) &&
                !LDH.IsObjectNull(data.value)) {

                let javascript = linqQuery;
                let dataName = "data";
                let dataValue = data;
                data.value = LDH.EvaluateJavaScriptForDataShaping(javascript,
                    dataName, dataValue, dataViewId);
            }
            if (LDH.IsObjectNull(data.value) === false) {
                if (!LDH.IsObjectNull(successFunc)) {
                    successFunc(data.value, data, thisComp);
                }
                return data.value;
            } else {
                if (!LDH.IsObjectNull(errorFunc)) {
                    errorFunc(data, "", thisComp);
                }
                return [];
            }
        }).catch(error => {
            if (!LDH.IsObjectNull(thisComp) && thisComp.disposingAllInstances) {
                return [];
            }
            if (LeopardAjaxHelper.HandleSessionTimeoutEvent(error)) {
                return [];
            }
            if (LDH.IsTimeoutReceivedFromAPIGateway(error) === true) {
                if (!LDH.IsObjectNull(errorFunc)) {
                    errorFunc(error, "retry", thisComp);
                }
            } else {
                if (!LDH.IsObjectNull(errorFunc)) {
                    errorFunc(error, "", thisComp);
                }
            }
            return [];
        });
    }

    static InitCustomStoreForTimelineView(httpType, dataViewId, serverSideQuery, customQueryShaping,
                                          postDataScript, successCallback, errorCallback, thisComp,
                                          timelineDefinition) {
        let instance = thisComp.uiObjectInstance.timelineInstance;
        if (instance === null) {
            setTimeout(function () {
                successCallback([], [], thisComp);
            }, 100);
            return [];
        }

        $("#gridViewToobar_" + dataViewId).show();
        $("#Timeline_TopBar_Refresh_" + dataViewId).addClass("leopard-ui-disabled");

        if (LeopardPermissionHelper.IsDebugModeEnabled()) {
            $("#gridViewToobar_" + dataViewId).hide();
            $("#Timeline_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
            return [];
        }

        if (!LDH.IsObjectNull(serverSideQuery) && !LDH.IsValueEmpty(serverSideQuery)) {
            serverSideQuery = LDH.FilterMacro(serverSideQuery);
        }

        let customQueryParams = "";
        if (instance !== null && !LDH.IsObjectNull(instance.option("customQueryParams")) &&
            !LDH.IsValueEmpty(instance.option("customQueryParams"))) {
            customQueryParams = instance.option("customQueryParams");
        }

        if (!LDH.IsObjectNull(customQueryParams) && !LDH.IsValueEmpty(customQueryParams)) {
            serverSideQuery = LDH.ReplaceAll(serverSideQuery, "{linked-params}", customQueryParams);
        } else {
            serverSideQuery = LDH.ReplaceAll(serverSideQuery, "{linked-params}", "");
        }

        if (instance !== null && !LDH.IsObjectNull(instance.option("dataFromSource")) &&
            !LDH.IsValueEmpty(instance.option("dataFromSource"))) {
            let dataFromSource = instance.option("dataFromSource");
            let sourceKeys = Object.keys(dataFromSource);
            for (let i = 0; i < sourceKeys.length; i++) {
                let sourceValue = dataFromSource[sourceKeys[i]];
                if (LDH.IsObjectNull(sourceValue) || LDH.IsValueEmpty(sourceValue)) {
                    continue;
                }
                serverSideQuery = LDH.ReplaceAll(serverSideQuery, "{" + sourceKeys[i] + "}", sourceValue);
            }
        }

        serverSideQuery = LDH.GetFilteredUrlByDataViewParameters(timelineDefinition.parameters, serverSideQuery, dataViewId);
        if (!LDH.IsObjectNull(customQueryShaping) && !LDH.IsValueEmpty(customQueryShaping)) {
            let javascript = customQueryShaping;
            let dataName = "parameters";
            let dataValue = serverSideQuery;
            serverSideQuery = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, dataViewId);
        }

        return LRH.SendAjaxRequestForTimeline(serverSideQuery, httpType, postDataScript, function (data, response) {
            successCallback(data, response, thisComp);
            return data;
        }, function (error, errorText, thisComp) {
            if (thisComp.disposingAllInstances === true) {
                return [];
            }
            if (LDH.IsObjectNull(errorText) || errorText !== "retry") {
                LRH.ShowToast("Unable to retrieve the data for the timeline.", "error", 5000);
                $("#gridViewToobar_" + dataViewId).hide();
                $("#Timeline_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
            }
            if (!LDH.IsObjectNull(errorCallback)) {
                errorCallback(error, errorText, thisComp);
            }
            return [];
        }, dataViewId, timelineDefinition, thisComp);
    }

    static InitCustomStoreForChartView(url, httpType, dataViewId, serverSideQuery, linqQuery, successCallback,
                                       errorCallback, thisComp, isDataView, hasCustomQueryParams, chartDefinition,
                                       httpDataPost, useNewUrlQuery) {
        return new DataSource({
            paginate: false,
            reshapeOnPush: true,
            store: new CustomStore({
                loadMode: 'raw',
                load: () => {
                    let instance = thisComp.uiObjectInstance.chartInstance;
                    if (LDH.IsObjectNull(instance) === true) {
                        successCallback([]);
                        return [];
                    }

                    LDH.SetPostProcessResultForDataViewInstance(instance, isDataView,
                        hasCustomQueryParams, dataViewId);

                    let option = instance.option("setBlankPostProcess");
                    if (!LDH.IsObjectNull(option) && option === true) {
                        successCallback([]);
                        return [];
                    }

                    $("#gridViewToobar_" + dataViewId).show();
                    $("#Chart_TopBar_Refresh_" + dataViewId).addClass("leopard-ui-disabled");

                    if (LeopardPermissionHelper.IsDebugModeEnabled()) {
                        $("#gridViewToobar_" + dataViewId).hide();
                        $("#Chart_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
                        return [];
                    }

                    let serverSideQueryCloned = serverSideQuery;
                    if (!LDH.IsObjectNull(serverSideQueryCloned) && !LDH.IsValueEmpty(serverSideQueryCloned)) {
                        serverSideQueryCloned = LDH.FilterMacro(serverSideQueryCloned);
                    }

                    if (LDH.IsObjectNull(useNewUrlQuery) || useNewUrlQuery === false) {
                        url = LDH.GetODataAPIGatewayUrl(serverSideQueryCloned);
                    } else {
                        url = serverSideQueryCloned;
                    }

                    url = LDH.FilterMacro(url);
                    url = LDH.GetFilteredUrlByDataViewParameters(chartDefinition.parameters, url, dataViewId);
                    let httpDataPostCloned = LDH.DeepClone(httpDataPost);
                    httpDataPostCloned = LDH.FilterMacro(httpDataPostCloned);

                    let customQueryParams = "";
                    if (!LDH.IsObjectNull(instance.option("customQueryParams")) &&
                        !LDH.IsValueEmpty(instance.option("customQueryParams"))) {
                        customQueryParams = instance.option("customQueryParams");
                    }

                    if (!LDH.IsObjectNull(instance.option("dataFromSource")) &&
                        !LDH.IsValueEmpty(instance.option("dataFromSource"))) {
                        let dataFromSource = instance.option("dataFromSource");
                        url = LDH.ConvertArrayMacroToString(url, dataFromSource, null);
                    }

                    if (!LDH.IsObjectNull(customQueryParams) && !LDH.IsValueEmpty(customQueryParams)) {
                        url = LDH.ReplaceAll(url, "{linked-params}", customQueryParams);
                    } else {
                        url = LDH.ReplaceAll(url, "{linked-params}", "");
                    }

                    if (!LDH.IsObjectNull(chartDefinition.defaultValuesForQuery) &&
                        !LDH.IsValueEmpty(chartDefinition.defaultValuesForQuery)) {
                        let defaultValues = chartDefinition.defaultValuesForQuery.split(",");
                        for (let x = 0; x < defaultValues.length; x++) {
                            let valueSplit = defaultValues[x].split("|");
                            if (!LDH.IsObjectNull(valueSplit[1]) &&
                                valueSplit[1].toString().toLowerCase() === "'blank'") {
                                valueSplit[1] = "";
                            }
                            url = LDH.ReplaceAll(url, valueSplit[0], valueSplit[1]);
                        }
                    }

                    let customQueryShaping = chartDefinition.customQueryShaping;
                    if (!LDH.IsObjectNull(customQueryShaping) && !LDH.IsValueEmpty(customQueryShaping)) {
                        let javascript = customQueryShaping;
                        let dataName = "parameters";
                        let dataValue = url;
                        url = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, dataViewId);
                    }

                    if (!LDH.IsObjectNull(httpDataPostCloned) && !LDH.IsValueEmpty(httpDataPostCloned)) {
                        let javascript = httpDataPostCloned;
                        let dataName = "parameters";
                        let dataValue2 = customQueryShaping;
                        httpDataPostCloned = LDH.EvaluateJavaScriptForDataShaping(
                            javascript, dataName, dataValue2, dataViewId);
                    }

                    return LRH.SendAjaxRequestForCharts(url, httpType, linqQuery, function (data, response) {
                        successCallback(data, response);
                        return data;
                    }, function (error, errorText) {
                        if (thisComp.disposingAllInstances === true) {
                            return [];
                        }
                        if (LDH.IsObjectNull(errorText) || errorText !== "retry") {
                            LRH.ShowToast("Unable to retrieve the data for the charts.", "error", 5000);
                            $("#gridViewToobar_" + dataViewId).hide();
                            $("#Chart_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
                        }
                        if (!LDH.IsObjectNull(errorCallback)) {
                            errorCallback(error, errorText);
                        }
                        return [];
                    }, dataViewId, chartDefinition, thisComp, httpDataPostCloned);
                }
            })
        });
    }

    static InitCustomStoreForAutoComplete(domain, url, autoCompleteOperation, columnName, gridDefinition, beforeSendCallback, postCallback,
                                          hasAutoCompleteDataSource, autoCompleteDataSourceKeyName, evaluateJavaScript, dataViewId) {
        let schemasColumnName = columnName;
        if (!LDH.IsObjectNull(hasAutoCompleteDataSource) && hasAutoCompleteDataSource) {
            schemasColumnName = autoCompleteDataSourceKeyName;
        }

        let schemas = LDH.GetTableAndColumnNameByCustomQueryTableSchema(gridDefinition, url, schemasColumnName);
        let newUrl = schemas.tableName;
        let newColumnName = schemas.columnName;

        let urlToQuery = domain + LDH.GetODataAPIGatewayUrl("/" + newUrl) +
            "?%24apply=groupby((" + newColumnName + "),aggregate($count as total))&authentication=true";

        if (!LDH.IsObjectNull(gridDefinition.topLevelFilteringField) && !LDH.IsValueEmpty(gridDefinition.topLevelFilteringField)) {
            urlToQuery = domain + LDH.GetODataAPIGatewayUrl("/" + newUrl) +
                "?%24apply=groupby((" + newColumnName + ", " + gridDefinition.topLevelFilteringField.split("|")[0] + "),aggregate($count as total))&authentication=true";
        }

        return {
            reshapeOnPush: true,
            store: {
                type: "odata",
                version: 4,
                url: urlToQuery,
                filterToLower: false,
                beforeSend: (xhr) => {
                    let schemasColumnName = columnName;
                    if (!LDH.IsObjectNull(hasAutoCompleteDataSource) && hasAutoCompleteDataSource) {
                        schemasColumnName = autoCompleteDataSourceKeyName;
                    }

                    let schemas = LDH.GetTableAndColumnNameByCustomQueryTableSchema(gridDefinition, url, schemasColumnName);
                    let newColumnName = schemas.columnName;

                    if (!LDH.IsObjectNull(beforeSendCallback)) beforeSendCallback();
                    if (!LDH.IsObjectNull(gridDefinition.topLevelFilteringField) && !LDH.IsValueEmpty(gridDefinition.topLevelFilteringField)) {
                        if (LDH.IsObjectNull(xhr.params["$filter"]) || LDH.IsValueEmpty(xhr.params["$filter"])) {
                            if (gridDefinition.topLevelFilteringField.indexOf("'") > -1) {
                                xhr.params["$filter"] = "tolower(" + gridDefinition.topLevelFilteringField.split("|")[0] + ") eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            } else {
                                xhr.params["$filter"] = gridDefinition.topLevelFilteringField.split("|")[0] + " eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            }
                        } else {
                            if (gridDefinition.topLevelFilteringField.indexOf("'") > -1) {
                                xhr.params["$filter"] += " and tolower(" + gridDefinition.topLevelFilteringField.split("|")[0] + ") eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            } else {
                                xhr.params["$filter"] += " and " + gridDefinition.topLevelFilteringField.split("|")[0] + " eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            }
                        }
                    }

                    if (!LDH.IsObjectNull(xhr.params["$filter"]) && !LDH.IsValueEmpty(xhr.params["$filter"])) {
                        if (!LDH.IsObjectNull(hasAutoCompleteDataSource) && hasAutoCompleteDataSource) {
                            xhr.params["$filter"] = LDH.ReplaceAll(xhr.params["$filter"], columnName, autoCompleteDataSourceKeyName);
                        }

                        xhr.params["$filter"] = LDH.FilterMacro(xhr.params["$filter"]);
                        xhr.params["$filter"] = LDH.ReplaceAll(xhr.params["$filter"], "(text)", "(" + newColumnName + ")");

                        if (autoCompleteOperation === "startswith") {
                            xhr.params["$filter"] = LDH.ReplaceAll(xhr.params["$filter"], "contains(", "startswith(");
                        }
                        xhr.params["$filter"] = LDH.ParseGuidForODataQuery(xhr.params["$filter"], newColumnName);
                    }
                    if (LDH.IsObjectNull(xhr.params["$top"]) || LDH.IsValueEmpty(xhr.params["$top"])) {
                        xhr.params["$top"] = 20;
                    }

                    if (LDH.IsObjectNull(gridDefinition.dataSourceCustomQueryTableSchema) === false &&
                        LDH.IsValueEmpty(gridDefinition.dataSourceCustomQueryTableSchema) === false) {
                        let tableSchemas = gridDefinition.dataSourceCustomQueryTableSchema.split("|");
                        for (let m = 0; m < tableSchemas.length; m++) {
                            if (tableSchemas[m].indexOf("{") > -1 && tableSchemas[m].indexOf("}") > -1 &&
                                LDH.IsObjectNull(xhr.params["$filter"]) === false &&
                                LDH.IsValueEmpty(xhr.params["$filter"]) === false) {
                                let startIndex = tableSchemas[m].indexOf("{") + 1;
                                let endIndex = tableSchemas[m].indexOf("}");
                                let prefix = tableSchemas[m].substring(startIndex, endIndex);
                                let newValue = xhr.params["$filter"];
                                newValue = LDH.ReplaceAll(newValue, prefix + "#", "");
                                xhr.params["$filter"] = newValue;
                            }
                        }
                    }
                },
                onLoaded: (result) => {
                    if (!LDH.IsObjectNull(postCallback)) postCallback();
                },
                errorHandler: (data) => {
                    if (!LDH.IsObjectNull(data) && typeof data === "object" && !LDH.IsObjectNull(data.httpStatus) &&
                        data.httpStatus === 504) {
                        LRH.ShowToast("This request has timed out, please try again.", "error", 5000);
                    }
                }
            },
            map(dataItem) {
                let dataJSON = dataItem;
                let columnNameToParse = columnName;
                if (!LDH.IsObjectNull(hasAutoCompleteDataSource) && hasAutoCompleteDataSource) {
                    columnNameToParse = autoCompleteDataSourceKeyName;
                }

                if (!LDH.IsValueEmpty(columnNameToParse) && columnNameToParse.indexOf("#") > -1) {
                    dataJSON = {};
                    let keys = Object.keys(dataItem);
                    let prefix = columnNameToParse.split("#")[0];
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] === "@odata.id" || keys[i] === "total") {
                            dataJSON[keys[i]] = dataItem[keys[i]];
                        } else {
                            dataJSON[prefix + "#" + keys[i]] = dataItem[keys[i]];
                        }
                    }
                }
                if (!LDH.IsObjectNull(hasAutoCompleteDataSource) && hasAutoCompleteDataSource) {
                    dataJSON[columnName] = dataJSON[autoCompleteDataSourceKeyName];
                }
                return dataJSON;
            },
            postProcess: (data) => {
                let list = [];
                if (LeopardAjaxHelper.HandleSessionTimeoutEvent(data)) {
                    return [];
                }
                if (!LDH.IsObjectNull(hasAutoCompleteDataSource) && hasAutoCompleteDataSource) {
                    for (let i = 0; i < data.length; i++) {
                        let value = data[i][autoCompleteDataSourceKeyName];
                        list.push({[columnName]: value});
                    }
                } else {
                    for (let i = 0; i < data.length; i++) {
                        let value = data[i][columnName];
                        list.push({[columnName]: value});
                    }
                }
                if (!LDH.IsObjectNull(evaluateJavaScript) && !LDH.IsValueEmpty(evaluateJavaScript)) {
                    if (LDH.IsObjectNull(list) || LDH.IsValueEmpty(list)) {
                        list = [];
                    }
                    let javascript = evaluateJavaScript;
                    let dataName = "data";
                    let dataValue = list;
                    list = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, dataViewId);
                }
                if (!LDH.IsObjectNull(postCallback)) postCallback(data);
                return list;
            }
        }
    }

    static InitCustomStoreForGridView(gridDefinition, gridViewId, url, linqQuery, limitedDataColumns,
                                      callbackFunc, fullColumns, thisComp, isDataView,
                                      hasCustomQueryParams, isGridViewJSONEngine, staticJSONData, textHighlightClearFunc) {
        let domainUrl = LDH.APIEndpointAdapter();
        let optimizePagerForLargeDataset = gridDefinition.optimizePagerForLargeDataset;
        if (LDH.IsValueEmpty(optimizePagerForLargeDataset)) {
            optimizePagerForLargeDataset = true;
        }

        let gridViewRowKey = null;
        if (!LDH.IsObjectNull(gridDefinition.gridViewRowKey) && !LDH.IsValueEmpty(gridDefinition.gridViewRowKey)) {
            gridViewRowKey = gridDefinition.gridViewRowKey;
        }

        let allowSelect = LDH.IsObjectNull(gridDefinition.overrideDatabaseTableField) ||
            LDH.IsValueEmpty(gridDefinition.overrideDatabaseTableField) ||
            gridDefinition.overrideDatabaseTableField.indexOf("/topingroup") === -1;

        if (isGridViewJSONEngine) {
            let includedColumns = LDH.GetIncludedColumns(limitedDataColumns, fullColumns);
            if (!LDH.IsObjectNull(staticJSONData) && staticJSONData.length > 0) {
                for (let w = 0; w < staticJSONData.length; w++) {
                    let keys = Object.keys(staticJSONData[w]);

                    for (let k = 0; k < keys.length; k++) {
                        let foundKey = false;

                        if (!LDH.IsObjectNull(includedColumns) && includedColumns.length > 0) {
                            for (let b = 0; b < includedColumns.length; b++) {
                                if (includedColumns[b] === keys[k]) {
                                    foundKey = true;
                                    break;
                                }
                            }
                        }

                        if (foundKey === false) {
                            delete staticJSONData[w][keys[k]];
                        }
                    }
                }
            }

            let data = staticJSONData;
            if (LDH.IsValueEmpty(linqQuery) === false) {
                let javascript = linqQuery;
                let dataName = "data";
                let dataValue = {data: data};
                data = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, gridViewId);
            }
            data = LDH.TruncateDataForGridView(gridDefinition, data);
            setTimeout(function () {
                callbackFunc(data, "json-data");
            }, 100);
            return data;
        }

        let dataStoreUrl = domainUrl + LDH.GetODataAPIGatewayUrl("/" + url + "&authentication=true");
        if (!LDH.IsObjectNull(gridDefinition.dataSourceOverrideMeta) &&
            !LDH.IsValueEmpty(gridDefinition.dataSourceOverrideMeta)) {
            let gatewayUrl = gridDefinition.dataSourceOverrideMeta.replace("/$metadata", "");
            dataStoreUrl = domainUrl + gatewayUrl + "/" + url + "&authentication=true";
        }

        let ds = new DataSource({
            reshapeOnPush: false,
            store: new ODataStore({
                version: 4,
                key: gridViewRowKey,
                url: dataStoreUrl,
                filterToLower: false,
                beforeSend: (xhr) => {
                    let instance = thisComp.uiObjectInstance.gridViewInstance;
                    let dynamicDataset = instance.option("dynamicDatasetFromMessaging");
                    LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, false, instance);

                    if (!LDH.IsObjectNull(thisComp.props.gridDefinition.enableTextHighlight) &&
                        thisComp.props.gridDefinition.enableTextHighlight) {
                        textHighlightClearFunc();
                    }

                    if (!LDH.IsObjectNull(dynamicDataset)) {
                        console.log("DynamicDatasetToApply", dynamicDataset);
                        xhr.url = "/data.json";
                        return;
                    }

                    LDH.SetPostProcessResultForDataViewInstance(instance, isDataView,
                        hasCustomQueryParams, gridViewId);

                    if (thisComp.props.isLoadForTabbedView &&
                        (LDH.IsObjectNull(instance.option("customQueryParams")) ||
                            LDH.IsValueEmpty(instance.option("customQueryParams")))) {
                        instance.option("setBlankPostProcess", true);

                        if (!LDH.IsObjectNull(thisComp.props.gridDefinition.oDataQueryNonSelectionForLinkedView) &&
                            !LDH.IsValueEmpty(thisComp.props.gridDefinition.oDataQueryNonSelectionForLinkedView)) {
                            let nonSelectionQuery = thisComp.props.gridDefinition.oDataQueryNonSelectionForLinkedView;
                            instance.option("customQueryParams", nonSelectionQuery);
                        }
                    }

                    if (LeopardPermissionHelper.IsDebugModeEnabled()) {
                        $("#gridViewToobar_" + gridViewId).hide();
                        $("#GridView_TopBar_Refresh_" + gridViewId).removeClass("leopard-ui-disabled");
                        return [];
                    }

                    let customQueryParams = "";
                    if (!LDH.IsObjectNull(instance.option("customQueryParams")) &&
                        !LDH.IsValueEmpty(instance.option("customQueryParams"))) {
                        customQueryParams = instance.option("customQueryParams");
                    }

                    if (!LDH.IsObjectNull(instance.option("dataFromSource")) &&
                        !LDH.IsValueEmpty(instance.option("dataFromSource"))) {
                        let dataFromSource = instance.option("dataFromSource");
                        let sourceKeys = Object.keys(dataFromSource);
                        for (let i = 0; i < sourceKeys.length; i++) {
                            let sourceValue = dataFromSource[sourceKeys[i]];
                            if (LDH.IsObjectNull(sourceValue) || LDH.IsValueEmpty(sourceValue)) {
                                continue;
                            }
                            xhr.url = LDH.ReplaceAll(xhr.url, "{" + sourceKeys[i] + "}", sourceValue);
                        }
                    }

                    if (!LDH.IsObjectNull(gridDefinition.defaultValueLookupForOverrideDbField) &&
                        !LDH.IsValueEmpty(gridDefinition.defaultValueLookupForOverrideDbField)) {
                        let defaultValues = gridDefinition.defaultValueLookupForOverrideDbField.split(",");
                        for (let x = 0; x < defaultValues.length; x++) {
                            let valueSplit = defaultValues[x].split("|");
                            if (valueSplit[1].toLowerCase() === "'blank'") {
                                valueSplit[1] = "";
                            }
                            xhr.url = LDH.ReplaceAll(xhr.url, valueSplit[0], valueSplit[1]);
                        }
                    }

                    if (!LDH.IsObjectNull(customQueryParams) && !LDH.IsValueEmpty(customQueryParams)) {
                        let filter = xhr.params["$filter"];
                        if (LDH.IsObjectNull(filter) || LDH.IsValueEmpty(filter)) {
                            filter = "";
                        } else {
                            filter = " and " + filter;
                        }
                        xhr.params["$filter"] = customQueryParams + filter;
                    }

                    let preProcessingScript = gridDefinition.clientSidePreProcessingScript;
                    if (!LDH.IsObjectNull(preProcessingScript) && !LDH.IsValueEmpty(preProcessingScript)) {
                        let javascript = preProcessingScript;
                        let dataName = "parameters";
                        let dataValue = xhr.params["$filter"];
                        xhr.params["$filter"] = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, gridViewId);
                    }

                    if (!LDH.IsObjectNull(gridDefinition.topLevelFilteringField) && !LDH.IsValueEmpty(gridDefinition.topLevelFilteringField)) {
                        if (LDH.IsObjectNull(xhr.params["$filter"]) || LDH.IsValueEmpty(xhr.params["$filter"])) {
                            if (gridDefinition.topLevelFilteringField.indexOf("'") > -1) {
                                xhr.params["$filter"] = "tolower(" + gridDefinition.topLevelFilteringField.split("|")[0] + ") eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            } else {
                                xhr.params["$filter"] = gridDefinition.topLevelFilteringField.split("|")[0] + " eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            }
                        } else {
                            if (gridDefinition.topLevelFilteringField.indexOf("'") > -1) {
                                xhr.params["$filter"] += " and tolower(" + gridDefinition.topLevelFilteringField.split("|")[0] + ") eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            } else {
                                xhr.params["$filter"] += " and " + gridDefinition.topLevelFilteringField.split("|")[0] + " eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            }
                        }
                    }

                    if (!LDH.IsObjectNull(xhr.params["$filter"]) && !LDH.IsValueEmpty(xhr.params["$filter"])) {
                        xhr.params["$filter"] = LDH.FilterMacro(xhr.params["$filter"]);
                        xhr.params["$filter"] = LDH.ParseGuidForODataQuery(xhr.params["$filter"], "");
                        xhr.params["$filter"] = LDH.GetFilteredUrlByDataViewParameters(
                            gridDefinition.parameters, xhr.params["$filter"], gridViewId);
                    }

                    for (let v = 0; v < window.Global_TempDateConvertion.length; v++) {
                        let tempDate = window.Global_TempDateConvertion[v];
                        if (!LDH.IsObjectNull(tempDate) && !LDH.IsObjectNull(xhr.params["$filter"]) &&
                            !LDH.IsValueEmpty(xhr.params["$filter"]) && tempDate.dataViewId === gridViewId) {
                            xhr.params["$filter"] = LDH.ReplaceAll(xhr.params["$filter"],
                                tempDate.convertFrom, tempDate.convertTo);
                        }
                    }

                    if (optimizePagerForLargeDataset === true) {
                        xhr.params["$count"] = false;

                        let filterString = xhr.params["$filter"];
                        let filterHistory = thisComp.combinedFilterHistory;
                        let historyLength = filterHistory.length - 2;
                        if (filterHistory.length > 5000) {
                            if (filterHistory[historyLength] !== filterString) {
                                filterHistory = [];
                                filterHistory.push("-");
                            } else {
                                filterHistory = [];
                            }
                        }
                        filterHistory.push(filterString);

                        if (!LDH.IsObjectNull(filterHistory) && historyLength > 1 &&
                            filterHistory[historyLength] !== filterString) {
                            instance.option("customPagingOperation", "");
                            instance.option("customPagingIndex", 0);
                        }

                        if (LDH.IsObjectNull(instance) === false) {
                            let operation = instance.option("customPagingOperation");
                            let pageIndex = instance.option("customPagingIndex");
                            let pageSize = instance.option("customPagingSize");

                            if (LDH.IsValueEmpty(pageSize) === true) {
                                pageSize = thisComp.props.gridDefinition.defaultPageSize;
                            }
                            if (!LDH.IsValueEmpty(operation) && operation === "next") {
                                instance.option("customPagingRefresh", false);
                                if (pageIndex + 1 < 0) pageIndex = 0;
                                xhr.params["$skip"] = pageIndex * pageSize;
                                instance.option("customPagingIndex", pageIndex);
                            } else if (!LDH.IsValueEmpty(operation) && operation === "prev") {
                                if (pageIndex - 1 < 0) pageIndex = 0;
                                xhr.params["$skip"] = pageIndex * pageSize;
                                instance.option("customPagingIndex", pageIndex);
                            } else {
                                xhr.params["$skip"] = 0;
                                instance.option("customPagingIndex", 0);
                            }
                            xhr.params["$top"] = pageSize;
                        }
                    }
                    $("#" + gridViewId + " .dx-datagrid-toolbar-button.dx-apply-button").hide();

                    let option = instance.option("setBlankPostProcess");
                    if (!LDH.IsObjectNull(option) && option === true) {
                        xhr.params["$top"] = 0;
                        xhr.params["$skip"] = 0;
                    }

                    if (xhr.url.indexOf("$top=") > -1 && !LDH.IsObjectNull(xhr.params["$top"]) &&
                        !LDH.IsValueEmpty(xhr.params["$top"]) && !isDataView) {
                        delete xhr.params["$top"];
                    }

                    let paramKeys = Object.keys(xhr.params);
                    if (!LDH.IsObjectNull(paramKeys) && paramKeys.length > 0) {
                        for (let v = 0; v < paramKeys.length; v++) {
                            let originalValue = xhr.params[paramKeys[v]];
                            if (LDH.IsValueEmpty(originalValue) ||
                                typeof originalValue !== 'string') {
                                continue;
                            }
                            originalValue = LDH.ReplaceAll(originalValue, "#", "/");
                            xhr.params[paramKeys[v]] = originalValue;
                        }
                    }
                }
            }),
            requireTotalCount: true,
            select: allowSelect ? LDH.GetIncludedColumns(limitedDataColumns, fullColumns) : null,
            map: (dataItem) => {
                if (LeopardPermissionHelper.IsDebugModeEnabled()) {
                    $("#gridViewToobar_" + gridViewId).hide();
                    $("#GridView_TopBar_Refresh_" + gridViewId).removeClass("leopard-ui-disabled");
                    return [];
                }

                let instance = thisComp.uiObjectInstance.gridViewInstance;
                if (!LDH.IsObjectNull(instance.option("dynamicDatasetFromMessaging"))) {
                    return {};
                }
                let dataJSON = {};
                LDH.MapJsonObjectForGridViewData(dataItem, dataJSON, null);
                return dataJSON;
            },
            postProcess: (data) => {
                if (LeopardPermissionHelper.IsDebugModeEnabled()) {
                    $("#gridViewToobar_" + gridViewId).hide();
                    $("#GridView_TopBar_Refresh_" + gridViewId).removeClass("leopard-ui-disabled");
                    return [];
                }

                if (thisComp.disposingAllInstances) {
                    return [];
                }
                let instance = thisComp.uiObjectInstance.gridViewInstance;
                let dynamicDataset = instance.option("dynamicDatasetFromMessaging");

                if (!LDH.IsObjectNull(dynamicDataset)) {
                    setTimeout(function () {
                        callbackFunc(dynamicDataset, ds);
                    }, 100);
                    return dynamicDataset;
                }
                if (LeopardAjaxHelper.HandleSessionTimeoutEvent(data)) {
                    return [];
                }
                if (LDH.IsValueEmpty(linqQuery) === false) {
                    let dataName = "data";
                    let dataValue = data;
                    data = LDH.EvaluateJavaScriptForDataShaping(linqQuery, dataName, dataValue, gridViewId, null);
                }
                data = LDH.TruncateDataForGridView(gridDefinition, data);
                setTimeout(function () {
                    callbackFunc(data, ds);
                }, 100);
                return data;
            },
            onLoadingChanged: (isLoading) => {
                if (isLoading === false && !thisComp.disposingAllInstances) {
                    let instance = thisComp.uiObjectInstance.gridViewInstance;
                    LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true, instance);
                }
            }
        });
        return ds;
    }

    static InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition) {
        let schemas = LDH.GetTableAndColumnNameByCustomQueryTableSchema(gridDefinition, url, columnName);
        url = schemas.tableName;
        columnName = schemas.columnName;

        let urlToQuery = domain + LDH.GetODataAPIGatewayUrl("/" + url) +
            "?%24apply=groupby((" + columnName + "),aggregate($count as total))&authentication=true";

        if (!LDH.IsObjectNull(gridDefinition.topLevelFilteringField) && !LDH.IsValueEmpty(gridDefinition.topLevelFilteringField)) {
            urlToQuery = domain + LDH.GetODataAPIGatewayUrl("/" + url) +
                "?%24apply=groupby((" + columnName + ", " + gridDefinition.topLevelFilteringField.split("|")[0] + "),aggregate($count as total))&authentication=true";
        }

        return {
            store: {
                type: "odata",
                version: 4,
                url: urlToQuery,
                filterToLower: false,
                beforeSend: (xhr) => {
                    if (!LDH.IsObjectNull(gridDefinition.topLevelFilteringField) && !LDH.IsValueEmpty(gridDefinition.topLevelFilteringField)) {
                        if (LDH.IsObjectNull(xhr.params["$filter"]) || LDH.IsValueEmpty(xhr.params["$filter"])) {
                            if (gridDefinition.topLevelFilteringField.indexOf("'") > -1) {
                                xhr.params["$filter"] = "tolower(" + gridDefinition.topLevelFilteringField.split("|")[0] + ") eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            } else {
                                xhr.params["$filter"] = gridDefinition.topLevelFilteringField.split("|")[0] + " eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            }
                        } else {
                            if (gridDefinition.topLevelFilteringField.indexOf("'") > -1) {
                                xhr.params["$filter"] += " and tolower(" + gridDefinition.topLevelFilteringField.split("|")[0] + ") eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            } else {
                                xhr.params["$filter"] += " and " + gridDefinition.topLevelFilteringField.split("|")[0] + " eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            }
                        }
                    }

                    if (!LDH.IsObjectNull(xhr.params["$filter"]) && !LDH.IsValueEmpty(xhr.params["$filter"])) {
                        xhr.params["$filter"] = LDH.FilterMacro(xhr.params["$filter"]);
                        xhr.params["$filter"] = LDH.ReplaceAll(xhr.params["$filter"], "(text)", "(" + columnName + ")");
                        xhr.params["$filter"] = LDH.ParseGuidForODataQuery(xhr.params["$filter"], columnName);
                    }
                }
            },
            postProcess: (data) => {
                let list = [];
                if (LeopardAjaxHelper.HandleSessionTimeoutEvent(data)) {
                    return [];
                }
                for (let i = 0; i < data.length; i++) {
                    let value = data[i][columnName];
                    list.push({text: value, value});
                }
                return list;
            }
        }
    }

    static EnableOrDisableGridViewToolbarButtons = (gridViewId, enabled, gridViewInstance) => {
        let isDataUpdatedByEventMessaging = false;
        if (!LDH.IsObjectNull(gridViewInstance) &&
            !LDH.IsObjectNull(gridViewInstance.option("isDataUpdatedByEventMessaging")) &&
            !LDH.IsValueEmpty(gridViewInstance.option("isDataUpdatedByEventMessaging"))) {
            isDataUpdatedByEventMessaging = gridViewInstance.option("isDataUpdatedByEventMessaging");
            gridViewInstance.option("isDataUpdatedByEventMessaging", false);
        }

        if (enabled === true || isDataUpdatedByEventMessaging) {
            $("#gridViewToobar_" + gridViewId).hide();
            $("#GridView_TopBar_Refresh_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_Export_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_Import_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_ViewOptions_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_AddRecord_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_ApplyFilter_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_ClearFilter_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_Layouts_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_Download_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_Upload_" + gridViewId).removeClass("leopard-ui-disabled");
            $(".leopard-pagination-blocker", $("#" + gridViewId)).hide();
            $("#GridViewPager_" + gridViewId).removeClass("leopard-ui-disabled");
        } else {
            $("#gridViewToobar_" + gridViewId).show();
            $("#GridView_TopBar_Refresh_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_Export_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_Import_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_ViewOptions_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_AddRecord_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_ApplyFilter_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_ClearFilter_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_Layouts_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_Download_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_Upload_" + gridViewId).addClass("leopard-ui-disabled");
            $(".leopard-pagination-blocker", $("#" + gridViewId)).show();
            $("#GridViewPager_" + gridViewId).addClass("leopard-ui-disabled");
        }
    };

    static SearchForMenuItems = (inputValue) => {
        $(".leopard-leftmenu-item .leopard-leftmenu-item-text").each(function () {
            let value = $(this).attr("searchabletext");
            if (LDH.IsObjectNull(value) || LDH.IsValueEmpty(value)) {
                return;
            }
            if (LDH.IsValueEmpty(inputValue.toString().toLowerCase().trim())) {
                $(this).closest(".leopard-leftmenu-item").css("display", "flex");
                $(this).closest(".leopard-leftmenu-item").addClass("item-found");
                $(this).closest(".dx-treeview-node").show();
                return;
            }
            let text = value.toString().toLowerCase().trim();
            if (text.indexOf(inputValue.toString().toLowerCase().trim()) > -1) {
                $(this).closest(".leopard-leftmenu-item").css("display", "flex");
                $(this).closest(".leopard-leftmenu-item").addClass("item-found");
                $(this).closest(".dx-treeview-node").show();
            } else {
                $(this).closest(".leopard-leftmenu-item").hide();
                $(this).closest(".leopard-leftmenu-item").removeClass("item-found");
                $(this).closest(".dx-treeview-node").hide();
            }
        });

        let $root = $(".leopard-master-leftmenu-container");
        $(".leopard-leftmenu-category", $root).each(function () {
            let id = $(this).attr("id");
            let count = 0;

            $(".leopard-leftmenu-item", $root).each(function () {
                let parentId = $(this).attr("parentid");
                if (parentId === id && $(this).hasClass("item-found")) {
                    count++;
                }
            });

            let $parent = $(this).closest("li.dx-treeview-node");
            if ($(".dx-treeview-node-container", $parent).length === 0) {
                return;
            }
            $(".menu-item-totalcount", $(this)).text(count);
        });
    };

    static TriggerWindowResizeEvent() {
        if (typeof (Event) === 'function') {
            window.dispatchEvent(new Event('resize'));
        } else {
            let evt = window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }
    }

    static RenderDataViewParameters(parameters, dataViewId, definition, onValueChangedFunc, setInputFieldInstance, uiObjectInstances) {
        if (LDH.IsObjectNull(parameters)) parameters = [];

        let parametersCloned = LDH.DeepClone(parameters);
        if (!LDH.IsObjectNull(parametersCloned) && parametersCloned.length > 0) {
            for (let i = 0; i < parametersCloned.length; i++) {
                if (LDH.IsObjectNull(parametersCloned[i].displayOrder) ||
                    LDH.IsValueEmpty(parametersCloned[i].displayOrder)) {
                    parametersCloned[i].displayOrder = 0;
                }
            }
            parametersCloned.sort(function (a, b) {
                if (a.displayOrder < b.displayOrder) {
                    return -1;
                }
                if (a.displayOrder > b.displayOrder) {
                    return 1;
                }
                return 0;
            });
        }

        return (LDH.IsObjectNull(parametersCloned) || parametersCloned.length === 0) ? null :
            parametersCloned.map(function (item, i) {
                let controlIdentifier = "dataViewParam_" + dataViewId + "_control_" + item.parameterName;

                let parameterVisibility = true;
                if (!LDH.IsObjectNull(item.parameterVisibility) && !LDH.IsValueEmpty(item.parameterVisibility)) {
                    parameterVisibility = item.parameterVisibility;
                }
                let controlWidth = LDH.IsObjectNull(item.controlWidth) ||
                LDH.IsValueEmpty(item.controlWidth) ? 100 : item.controlWidth;

                let parameterLabel = (LDH.IsObjectNull(item.parameterLabel) ||
                    LDH.IsValueEmpty(item.parameterLabel)) ? null :
                    <span className={"dataview-parameters-label"}>{item.parameterLabel}</span>;

                let defaultValue = (LDH.IsObjectNull(item.defaultValue) ||
                    LDH.IsValueEmpty(item.defaultValue)) ? null : item.defaultValue;
                defaultValue = LDH.FilterMacro(defaultValue);

                let controlDefaultValueLogic = item.controlDefaultValueLogic;
                if (!LDH.IsObjectNull(controlDefaultValueLogic) && !LDH.IsValueEmpty(controlDefaultValueLogic) &&
                    LDH.IsObjectNull(uiObjectInstances[controlIdentifier])) {
                    let javascript = controlDefaultValueLogic;
                    let dataName = "data";
                    let dataValue = defaultValue;
                    defaultValue = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, dataViewId, null);
                }

                if (LDH.IsObjectNull(defaultValue) || LDH.IsValueEmpty(defaultValue)) {
                    defaultValue = null;
                }

                if (LDH.IsObjectNull(window["dataViewParam_" + dataViewId + "_control_" + item.parameterName])) {
                    window["dataViewParam_" + dataViewId + "_control_" + item.parameterName] = defaultValue;
                }
                let valueFormat = (LDH.IsObjectNull(item.valueFormat) ||
                    LDH.IsValueEmpty(item.valueFormat)) ? null : item.valueFormat;

                let placeholder = (LDH.IsObjectNull(item.placeholder) ||
                    LDH.IsValueEmpty(item.placeholder)) ? null : item.placeholder;

                let dataKey = "id";
                if (!LDH.IsObjectNull(item.dataSourceKey) && !LDH.IsValueEmpty(item.dataSourceKey)) {
                    dataKey = item.dataSourceKey;
                }

                let dataName = "name";
                if (!LDH.IsObjectNull(item.dataSourceName) && !LDH.IsValueEmpty(item.dataSourceName)) {
                    dataName = item.dataSourceName;
                }

                let autoApplyParameterFilter = true;
                if (!LDH.IsObjectNull(definition.autoApplyParameterFilter) &&
                    !LDH.IsValueEmpty(definition.autoApplyParameterFilter)) {
                    autoApplyParameterFilter = definition.autoApplyParameterFilter;
                }

                let acOperation = "contains";
                if (!LDH.IsObjectNull(item.autoCompleteOperation) && !LDH.IsValueEmpty(item.autoCompleteOperation)) {
                    acOperation = item.autoCompleteOperation;
                }

                let minSearchLength = 1;
                if (!LDH.IsObjectNull(item.minSearchLength) && !LDH.IsValueEmpty(item.minSearchLength)) {
                    minSearchLength = item.minSearchLength;
                }

                let allowTyping = true;
                if (!LDH.IsObjectNull(item.allowTyping) && !LDH.IsValueEmpty(item.allowTyping)) {
                    allowTyping = item.allowTyping;
                }

                let parameterTriggerOnChange = true;
                if (!LDH.IsObjectNull(item.parameterTriggerOnChange) && !LDH.IsValueEmpty(item.parameterTriggerOnChange)) {
                    parameterTriggerOnChange = item.parameterTriggerOnChange;
                }

                let minValue = -99999999999;
                if (!LDH.IsObjectNull(item.minValue) && !LDH.IsValueEmpty(item.minValue)) {
                    minValue = item.minValue;
                }

                let maxValue = 99999999999;
                if (!LDH.IsObjectNull(item.maxValue) && !LDH.IsValueEmpty(item.maxValue)) {
                    maxValue = item.maxValue;
                }

                let dataShapingForRemote = "";
                if (!LDH.IsObjectNull(item.dataShapingForRemote) && !LDH.IsValueEmpty(item.dataShapingForRemote)) {
                    dataShapingForRemote = item.dataShapingForRemote;
                }

                let dataShapingForQuery = "";
                if (!LDH.IsObjectNull(item.dataShapingForQuery) && !LDH.IsValueEmpty(item.dataShapingForQuery)) {
                    dataShapingForQuery = item.dataShapingForQuery;
                }

                let dataSource = null;
                if (item.parameterType === "textbox" || item.parameterType === "dropdown" || item.parameterType === "dropdown-multiselect") {
                    if (!LDH.IsObjectNull(item.dataSource) && !LDH.IsValueEmpty(item.dataSource)) {
                        let domainUrl = LDH.APIEndpointAdapter();
                        dataSource = LRH.InitCustomStoreForAutoComplete(domainUrl, item.dataSource, acOperation,
                            dataKey, {topLevelFilteringField: null, dataSourceCustomQueryTableSchema: null},
                            null, null, true, dataKey, dataShapingForRemote, dataViewId);
                    } else if (!LDH.IsObjectNull(dataShapingForRemote) && !LDH.IsValueEmpty(dataShapingForRemote)) {
                        let javascript = dataShapingForRemote;
                        let dataName = "data";
                        let dataValue = [];
                        dataSource = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, dataViewId);
                    }
                }

                let controlEnabled = true;
                let controlEnablementLogic = item.controlEnablementLogic;
                if (!LDH.IsObjectNull(controlEnablementLogic) && !LDH.IsValueEmpty(controlEnablementLogic)) {
                    let javascript = controlEnablementLogic;
                    let dataName = "data";
                    let dataValue = "";
                    controlEnabled = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, dataViewId, null);
                }

                let controlVisible = true;
                let controlVisibilityLogic = item.controlVisibilityLogic;
                if (!LDH.IsObjectNull(controlVisibilityLogic) && !LDH.IsValueEmpty(controlVisibilityLogic)) {
                    let javascript = controlVisibilityLogic;
                    let dataName = "data";
                    let dataValue = "";
                    controlVisible = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, dataViewId, null);
                }

                return (
                    <span
                        className={"dataview-parameters-controlcontainer-" + item.parameterName + " " + "dataview-parameters-container-" + dataViewId}
                        style={{display: (parameterVisibility ? "flex" : "none")}}
                        key={"parameters_" + dataViewId + "_controlContainer_" + i}>
                        {parameterLabel}
                        <span style={{float: "left", marginTop: "-1px", marginRight: "5px"}}>
                            {
                                (item.parameterType === "date" || item.parameterType === "datetime") ?
                                    <DateBox
                                        type={item.parameterType} width={controlWidth}
                                        onValueChanged={(e) => onValueChangedFunc({
                                            controlName: controlIdentifier,
                                            control: e,
                                            dataShapingForQuery: dataShapingForQuery,
                                            autoApplyParameterFilter: autoApplyParameterFilter,
                                            parameterTriggerOnChange: parameterTriggerOnChange,
                                            controlDefaultValueLogic: controlDefaultValueLogic
                                        })}
                                        className={controlIdentifier}
                                        ref={(e) => setInputFieldInstance({
                                            e,
                                            input: controlIdentifier,
                                            properties: item
                                        })}
                                        disabled={!controlEnabled} visible={controlVisible}
                                        defaultValue={defaultValue} placeholder={placeholder}
                                        displayFormat={valueFormat}>
                                    </DateBox> : null
                            }
                            {
                                item.parameterType === "numberbox" ?
                                    <NumberBox
                                        width={controlWidth} defaultValue={defaultValue}
                                        onValueChanged={(e) => onValueChangedFunc({
                                            controlName: controlIdentifier,
                                            control: e,
                                            dataShapingForQuery: dataShapingForQuery,
                                            autoApplyParameterFilter: autoApplyParameterFilter,
                                            parameterTriggerOnChange: parameterTriggerOnChange,
                                            controlDefaultValueLogic: controlDefaultValueLogic
                                        })}
                                        className={controlIdentifier}
                                        ref={(e) => setInputFieldInstance({
                                            e,
                                            input: controlIdentifier,
                                            properties: item
                                        })}
                                        disabled={!controlEnabled} visible={controlVisible}
                                        min={minValue} max={maxValue} showSpinButtons={true}
                                        format={valueFormat}>
                                    </NumberBox> : null
                            }
                            {
                                item.parameterType === "textbox" && ((!LDH.IsObjectNull(item.dataSource) && !LDH.IsValueEmpty(item.dataSource))
                                    || (!LDH.IsObjectNull(item.dataShapingForRemote) && !LDH.IsValueEmpty(item.dataShapingForRemote))) ?
                                    <Autocomplete
                                        onValueChanged={(e) => onValueChangedFunc({
                                            controlName: controlIdentifier,
                                            control: e,
                                            dataShapingForQuery: dataShapingForQuery,
                                            autoApplyParameterFilter: autoApplyParameterFilter,
                                            parameterTriggerOnChange: parameterTriggerOnChange,
                                            controlDefaultValueLogic: controlDefaultValueLogic
                                        })}
                                        className={controlIdentifier}
                                        ref={(e) => setInputFieldInstance({
                                            e,
                                            input: controlIdentifier,
                                            properties: item
                                        })}
                                        disabled={!controlEnabled} visible={controlVisible}
                                        dataSource={dataSource} width={controlWidth} defaultValue={defaultValue}
                                        minSearchLength={minSearchLength} placeholder={placeholder} valueExpr={dataKey}
                                        displayExpr={dataName}>
                                    </Autocomplete> : null
                            }
                            {
                                item.parameterType === "textbox" && (LDH.IsObjectNull(item.dataSource) || LDH.IsValueEmpty(item.dataSource))
                                && (LDH.IsObjectNull(item.dataShapingForRemote) || LDH.IsValueEmpty(item.dataShapingForRemote)) ?
                                    <TextBox
                                        onValueChanged={(e) => onValueChangedFunc({
                                            controlName: controlIdentifier,
                                            control: e,
                                            dataShapingForQuery: dataShapingForQuery,
                                            autoApplyParameterFilter: autoApplyParameterFilter,
                                            parameterTriggerOnChange: parameterTriggerOnChange,
                                            controlDefaultValueLogic: controlDefaultValueLogic
                                        })}
                                        className={controlIdentifier}
                                        ref={(e) => setInputFieldInstance({
                                            e,
                                            input: controlIdentifier,
                                            properties: item
                                        })}
                                        disabled={!controlEnabled} visible={controlVisible}
                                        width={controlWidth} defaultValue={defaultValue} placeholder={placeholder}>
                                    </TextBox> : null
                            }
                            {
                                item.parameterType === "dropdown" && ((!LDH.IsObjectNull(item.dataSource) && !LDH.IsValueEmpty(item.dataSource))
                                    || (!LDH.IsObjectNull(item.dataShapingForRemote) && !LDH.IsValueEmpty(item.dataShapingForRemote))) ?
                                    <SelectBox
                                        onValueChanged={(e) => onValueChangedFunc({
                                            controlName: controlIdentifier,
                                            control: e,
                                            dataShapingForQuery: dataShapingForQuery,
                                            autoApplyParameterFilter: autoApplyParameterFilter,
                                            parameterTriggerOnChange: parameterTriggerOnChange,
                                            controlDefaultValueLogic: controlDefaultValueLogic
                                        })}
                                        className={controlIdentifier}
                                        disabled={!controlEnabled} visible={controlVisible}
                                        dataSource={dataSource} width={controlWidth} defaultValue={defaultValue}
                                        ref={(e) => setInputFieldInstance({
                                            e,
                                            input: controlIdentifier,
                                            properties: item
                                        })}
                                        searchEnabled={allowTyping} showDataBeforeSearch={true}
                                        minSearchLength={minSearchLength} placeholder={placeholder} valueExpr={dataKey}
                                        searchMode={acOperation} displayExpr={dataName} searchTimeout={1000}
                                        noDataText={"Enter value to search..."} showDropDownButton={true}>
                                    </SelectBox> : null
                            }
                            {
                                item.parameterType === "dropdown-multiselect" && ((!LDH.IsObjectNull(item.dataSource) && !LDH.IsValueEmpty(item.dataSource))
                                    || (!LDH.IsObjectNull(item.dataShapingForRemote) && !LDH.IsValueEmpty(item.dataShapingForRemote))) ?
                                    <TagBox dataSource={dataSource} showSelectionControls={true}
                                            valueExpr={dataKey} displayExpr={dataName} showDropDownButton={true}
                                            className={controlIdentifier} multiline={false}
                                            defaultValue={!LDH.IsValueEmpty(defaultValue) ? defaultValue.split(",") : defaultValue}
                                            disabled={!controlEnabled} width={controlWidth}
                                            maxDisplayedTags={1} showMultiTagOnly={false}
                                            onValueChanged={(e) => onValueChangedFunc({
                                                controlName: controlIdentifier,
                                                control: e,
                                                dataShapingForQuery: dataShapingForQuery,
                                                autoApplyParameterFilter: autoApplyParameterFilter,
                                                parameterTriggerOnChange: parameterTriggerOnChange,
                                                controlDefaultValueLogic: controlDefaultValueLogic
                                            })}
                                            ref={(e) => setInputFieldInstance({
                                                e,
                                                input: controlIdentifier,
                                                properties: item
                                            })}>
                                    </TagBox> : null
                            }
                        </span>
                    </span>
                );
            })
    }

    static DisposeUIInstancesFromList(uiObjectInstances) {
        try {
            if (!LDH.IsObjectNull(uiObjectInstances)) {
                let keys = Object.keys(uiObjectInstances);
                for (let i = 0; i < keys.length; i++) {
                    try {
                        let key = keys[i];
                        if (!LDH.IsObjectNull(uiObjectInstances[key]) &&
                            !LDH.IsObjectNull(uiObjectInstances[key].instance)) {
                            if (typeof uiObjectInstances[key].instance.dispose !== "undefined") {
                                uiObjectInstances[key].instance.dispose();
                                uiObjectInstances[key] = null;
                            } else if (typeof uiObjectInstances[key].instance().dispose !== "undefined") {
                                uiObjectInstances[key].instance().dispose();
                                uiObjectInstances[key] = null;
                            }
                        } else if (!LDH.IsObjectNull(uiObjectInstances[key])) {
                            if (typeof uiObjectInstances[key].dispose !== "undefined") {
                                uiObjectInstances[key].dispose();
                                uiObjectInstances[key] = null;
                            } else {
                                uiObjectInstances[key] = null;
                            }
                        }
                    } catch (ex) {
                        console.log("Function not found: " + ex);
                    }
                }
            }
        } catch (ex) {
            console.log("Function not found: " + ex);
        }
    }

    static DownloadStringToFile(filename, text) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    static AddDisposablePopupInstances(thisComp) {
        let instances = [];
        let instanceKeys = Object.keys(thisComp.uiObjectInstances);
        for (let i = 0; i < instanceKeys.length; i++) {
            let key = instanceKeys[i];
            instances.push(thisComp.uiObjectInstances[key]);
        }
        thisComp.props.addDisposablePopupInstances(instances);
    }

    static ExecuteClientScript(data, linqQuery, dataType, clearReference) {
        return this.ExecuteClientScriptWithIdentifier(data, linqQuery, dataType, clearReference, "");
    }

    static ExecuteClientScriptWithIdentifier(data, linqQuery, dataType, clearReference, identifier) {
        try {
            if (LDH.IsObjectNull(dataType) || LDH.IsValueEmpty(dataType)) {
                dataType = "data";
            }

            if (LeopardPermissionHelper.IsDebugModeEnabled()) {
                return data;
            }

            window[dataType] = data;
            window.moment = moment;
            window.__callback = function () {
                //LRH.ShowToast("Your client-side query could not be processed. Raw data is being used.", "warning", 5000);
            };
            let query = LDH.GetClientSideQueryWithIdentifier(linqQuery, dataType, identifier);
            $.globalEval(query);

            let dataToReturn = window[dataType];
            if (clearReference && !LDH.IsObjectNull(window[dataType])) {
                window[dataType] = null;
            }
            window.moment = null;
            return dataToReturn;
        } catch (ex) {
            LRH.ShowToast("Your client-side query could not be processed. Raw data is being used.", "warning", 5000);
        }
    }

    static ApplyCustomStyle(styleDefinition, version) {
        if (styleDefinition !== undefined && styleDefinition !== null &&
            styleDefinition.length > 0) {
            let defaultStyle = '<link id="leopard-default-style" rel="stylesheet" type="text/css" href=' + version + '"/src/resources/css/leopard-theme-colors.css?v=" />';
            let customStyle = '<style id="leopard-custom-style" type="text/css">' + styleDefinition + '</style>';

            $("#leopard-default-style").remove();
            $("head").append(defaultStyle);

            $("#leopard-custom-style").remove();
            $("head").append(customStyle);
        } else {
            $("#leopard-custom-style").remove();
        }
    }

    static GetAthenaDataForReport = (datasources, reportDef, args, callback) => {
        let dataSignedUrls = [];
        let that = this;

        for (let i = 0; i < datasources.length; i++) {
            dataSignedUrls.push(datasources[i].signedUrl);
        }
        let signedDataArray = [];

        function downloadData(url, index) {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'text';

            xhr.onload = function () {
                if (xhr.status === 200) {
                    let data = xhr.responseText;
                    signedDataArray[index] = {url: url};
                    for (let i = 0; i < datasources.length; i++) {
                        if (datasources[i].signedUrl !== url) {
                            continue;
                        }
                        datasources[i].jsonData = JSON.parse(data);
                    }
                    if (signedDataArray.length === dataSignedUrls.length) {
                        LRH.GenerateReportWithAthenaData(datasources,
                            reportDef, that, args, callback);
                    }
                }
            };
            xhr.send();
        }

        dataSignedUrls.forEach((url, index) => downloadData(url, index));
    };

    static GenerateReportWithAthenaData = (reportResults, reportDef, that, args, callback) => {
        let reportDataList = {};
        for (let i = 0; i < reportResults.length; i++) {
            let jsonData = reportResults[i].jsonData;
            if (reportResults[i].name === "defaultDataSource") {
                reportDataList["_defaultDS"] = jsonData;
            } else {
                reportDataList[reportResults[i].name] = jsonData;
            }
        }
        callback(reportDataList);
    };
}

export default LeopardReactHelper;
