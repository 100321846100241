import React, {Component} from 'react';
import {connect} from 'react-redux';
import LPH from "../helpers/LeopardPermissionHelper";

class LeopardMenuDataViewList extends Component {
    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        let that = this;
        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined &&
            this.props.state.permissions !== null) {
            let permissions = this.props.state.permissions;
            showGridViewAdminToolbar = permissions.ShowGridViewAdminToolbar;
        }

        let result = this.props.menuItems.map(function (item, i) {
            let selectedClass = that.props.selectedId === item.menuItemId ? "selected" : "";
            let hideButton = !showGridViewAdminToolbar || (!LPH.IsLeopardAdminModeEnabled(that) &&
                that.props.dataViewCategory === "default-view");
            return (
                <React.Fragment key={"LeopardMenuDataViewList_" + i}>
                    <div id={"menuitem_dataview_" + item.dataViewType + "_" + item.menuItemId}
                         parentid={item.menuItemParentId}
                         data_view_persistent_id={item.dataViewPersistentId}
                         data_view_id={item.dashboardId}
                         className={"leopard-leftmenu-item " + (that.props.categoryVisible ? "" : "hidden") +
                             " " + selectedClass + " " + (item.menuItemName.indexOf("Selenium_") > -1 ? "selenium-dataview" : "")}>
                        <div className={"leopard-leftmenu-item-text " + (hideButton ? "extended" : "")}
                             id={item.menuItemName.indexOf("Selenium_") > -1 ? "dataview-item-createdby-selenium" : ""}
                             title={item.menuItemName} searchabletext={item.menuItemName}
                             onClick={() => that.props.menuItemOnClick({item, selected: selectedClass})}
                             custom_attr_url={item.dataSourceUrl}>{item.menuItemName}</div>
                        {
                            hideButton ? "" :
                                <div className={"leopard-leftmenu-item-options fas fa-ellipsis-h " +
                                    (item.menuItemName.indexOf("Selenium_") > -1 ? "selenium-dataview-edit" : "")}
                                     onClick={
                                         () => that.props.menuItemSettingsButtonOnClick({
                                             item, option: "edit_dataview",
                                             dataViewCategory: that.props.dataViewCategory
                                         })
                                     }
                                />
                        }
                    </div>
                </React.Fragment>
            );
        });
        return (<React.Fragment>{result}</React.Fragment>);
    }
}

// =======================================================================
// ========================== Redux Operations ===========================
// =======================================================================
const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer, null)(LeopardMenuDataViewList);
